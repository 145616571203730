<template>
    <app-layout title="Home">
        <div style="direction: ltr !important" class="h-screen flex items-center justify-center">
            <form
                :action="route('payment.checkout.submit')"
                class="paymentWidgets"
                :data-brands="type"
            ></form>
        </div>
    </app-layout>
</template>

<script>
import { defineComponent } from "vue";
// import AppLayout from "@/Layouts/AnnouncementLayout.vue";
// import Welcome from "@/Jetstream/Welcome.vue";

export default defineComponent({
    components: {
        // AppLayout,
        // Welcome
    },
    data() {
        return {
            checkoutId: null,
            response: this.$page.props.response,
            paymentType: this.$page.props.response.paymentType,
            type: null,
        };
    },
    mounted() {
        this.checkoutId = this.response.id;
        var url = null;
        if (this.paymentType == "mada") {
            this.type = "MADA";
            url =
                "https://test.oppwa.com/v1/paymentWidgets.js" +
                `?checkoutId=${this.checkoutId}`;
        } else if (this.paymentType == "visa/master") {
            this.type = "VISA MASTER AMEX";
            url =
                "https://test.oppwa.com/v1/paymentWidgets.js" +
                `?checkoutId=${this.checkoutId}`;
        } else if (this.paymentType == "apple") {
            this.type = "APPLEPAY";
            url = "https://eu-test.oppwa.com/v1/paymentWidgets.js"+
                `?checkoutId=${this.checkoutId}`;
        }
        var plugin = document.createElement("script");

        plugin.setAttribute("src", url);
        plugin.async = true;
        document.head.appendChild(plugin);
        var page_3d = document.createElement("script");
        page_3d.innerHTML = `var wpwlOptions = {
                                paymentTarget:'_top',
                            }`;
        page_3d.async = true;
        document.head.appendChild(page_3d);
    },
});
</script>
