<template>
    <section class="bg-secondary-900 min-h-screen">
        <Head>
            <title>Invoice | Qariin</title>
        </Head>
        <div class="container py-12">
            <div
                class="max-w-3xl shadow-md mx-auto p-xs bg-white overflow-hidden"
                style="min-width: 48rem"
            >
                <div
                    class="flex items-center justify-between border-b pb-3 mb-6"
                >
                    <div class="flex flex-col items-start justify-start">
                        <img
                            src="@qariin-fe/src/images/svg/logo-colored.svg"
                            alt="Qariin logo"
                            class="w-32 h-24 object-contain"
                        />
                        <h2 class="text-tertiary text-xs mt-5">
                            منصة قارن- Qariin Platform
                        </h2>
                        <p class="text-black">920010050</p>
                    </div>
                    <div v-if="!!data.company.vat_number" class="flex flex-col items-end justify-start">
                        <img
                           v-if="data.company.avatar"
                            :src="
                                `/storage/${data.company.avatar}`
                            "
                            alt="Insurance Company logo"
                            class="w-32 h-24 object-contain"
                        />
                        <h2 class="text-tertiary text-xs mt-5">
                            {{ data.company.name_ar }}
                        </h2>
                        <p class="text-black">
                            VAT: {{ data.company.vat_number }}
                        </p>
                    </div>
                </div>
                <div class="grid grid-cols-3 gap-5">
                    <div class="flex flex-col items-start justify-start">
                        <h3 class="text-secondary">رقم الوثيقة</h3>
                        <span class="text-secondary-200 text-xs mb-3"
                            >Policy Number</span
                        >
                        <h2 class="text-black">
                            {{ data.policy?.policy_number }}
                        </h2>
                        <span class="text-secondary-200 text-xs"
                            >{{ data.plicy?.policy_effective_date }} -
                            {{ data.policy?.policy_expiry_date }}</span
                        >
                    </div>
                    <div class="flex flex-col items-start justify-start">
                        <h3 class="text-secondary">العميل</h3>
                        <span class="text-secondary-200 text-xs mb-3"
                            >Client</span
                        >
                        <h2 class="text-black">
                            {{ data.owner.arabic_full_name }}
                        </h2>
                        <span class="text-secondary-200 text-xs">{{
                            data.owner.english_full_name
                        }}</span>
                    </div>
                    <div class="flex flex-col items-end justify-start">
                        <h3 class="text-secondary">رقم المركبة</h3>
                        <span class="text-secondary-200 text-xs mb-3"
                            >.Vehicle No</span
                        >
                        <div>
                            <h2 class="text-secondary-900">
                                <span v-if="data.vehicle && data.vehicle.plate_number" class="pe-2"
                                    >{{ data.vehicle.plate_text3 }}
                                    {{ data.vehicle.plate_text2 }}
                                    {{ data.vehicle.plate_text1 }}
                                </span>
                                <span v-if="data.vehicle && data.vehicle.plate_number">{{
                                    data.vehicle.plate_number
                                }}</span>
                            </h2>
                        </div>
                        <span class="text-secondary-200 text-xs mb-3">
                            <span class="pe-1">{{data.vehicle.maker.name}}</span>
                            <span class="pe-1">{{data.vehicle.vehicle_model?.name}}</span>
                            <span class="pe-1">{{data.vehicle.model_year}}</span>
                        </span>
                    </div>
                </div>
                <div
                    class="relative grid grid-cols-3 gap-x-3 gap-y-4 mt-10 pb-16 border-b mb-5"
                >
                    <div>
                        <h2 class="text-secondary">تفاصيل السعر</h2>
                        <span class="text-secondary-200">Description</span>
                    </div>
                    <div class="col-span-2 text-left">
                        <h2 class="text-secondary">القيمة</h2>
                        <span class="text-secondary-200">AMOUNT</span>
                    </div>
                    <div class="col-span-3 border-b"></div>
                    <div v-if="data.insurance_type === 'tpl'">
                        <h2 class="text-secondary-900">تأمين ضد الغير</h2>
                        <span class="text-secondary-200">Third party liability</span>
                    </div>
                    <div v-else>
                        <h2 class="text-secondary-900">تأمين شامل</h2>
                        <span class="text-secondary-200">Comprehensive</span>
                    </div>
                    <div class="col-span-2 flex items-center justify-end">
                        <span class="text-secondary-900 pe-2">SR</span>
                        <h2 class="text-secondary-900">
                            {{ basePrice }}
                        </h2>
                    </div>
                    <div class="col-span-3 border-b"></div>
                    <div>
                        <h2 class="text-secondary-900">المنافع الإضافية</h2>
                        <span class="text-secondary-200">Additional benefits</span>
                    </div>
                    <div class="col-span-2 flex items-center justify-end">
                        <span class="text-secondary-900 pe-2">SR</span>
                        <h2 class="text-secondary-900">
                            {{ !!data.covers.length ? coversTotal : 0 }}
                        </h2>
                    </div>
                    <div class="col-span-3 border-b"></div>
                    <div>
                        <h2 class="text-secondary-900">المجموع</h2>
                        <span class="text-secondary-200 uppercase">subtotal</span>
                    </div>
                    <div class="col-span-2 flex items-center justify-end">
                        <span class="text-secondary-900 pe-2">SR</span>
                        <h2 class="text-secondary-900">
                            {{ Number(coversTotal) + Number(basePrice) }}
                        </h2>
                    </div>
                    <div class="col-span-3 border-b"></div>
                    <div class="grid grid-cols-4 col-span-2">
                        <div class="grid grid-cols-2 gap-4 col-span-3">
                            <template v-if="discount > 0">
                                <div>
                                    <h2 class="text-secondary-900">
                                        الخصومات
                                    </h2>
                                    <span class="text-secondary-200">Discount</span>
                                </div>
                                <div class="flex items-center justify-end">
                                    <span class="text-secondary-900 pe-2">SR</span>
                                    <h2 class="text-secondary-900">
                                        {{ discount }}
                                    </h2>
                                </div>
                                <div class="col-span-2 border-b"></div>
                                <div>
                                    <h2 class="text-secondary-900">
                                        المجموع قبل الضريبة
                                    </h2>
                                    <span class="text-secondary-200">Subtotal before tax</span>
                                </div>
                                <div class="flex items-center justify-end">
                                    <span class="text-secondary-900 pe-2">SR</span>
                                    <h2 class="text-secondary-900">
                                        {{ subtotalBeforeTax }}
                                    </h2>
                                </div>
                                <div class="col-span-2 border-b"></div>
                            </template>
                            <div>
                                <h2 class="text-secondary-900">
                                    ضريبة
                                </h2>
                                <span class="text-secondary-200">VAT</span>
                            </div>
                            <div class="flex items-center justify-end">
                                <span class="text-secondary-900 pe-2">SR</span>
                                <h2 class="text-secondary-900">
                                    {{ vat }}
                                </h2>
                            </div>
                            <div class="col-span-2 border-b"></div>
                            <div>
                                <h2 class="text-secondary-900">
                                    المجموع المستحق
                                </h2>
                                <span class="text-secondary-200">TOTAL</span>
                            </div>
                            <div class="text-left">
                                <div class="flex items-center justify-end">
                                    <span class="text-secondary-900 pe-2">SR</span>
                                    <h2 class="text-secondary-900">
                                        {{
                                            Number(data.total_price).toFixed(2)
                                        }}
                                    </h2>
                                </div>
                                <span class="text-xs text-secondary-200"
                                    >شامل 2% قيمة عمولة الوسيط</span
                                >
                            </div>
                        </div>
                    </div>
                    <div
                        class="absolute -left-4"
                        style="width: 300px; height: 280px; bottom: -80px"
                    >
                        <img
                            src="@qariin-fe/src/images/invoice-bg.png"
                            class="max-w-full max-h-full object-contain"
                            alt="Invoice bg"
                        />
                    </div>
                </div>

                <div class="grid grid-cols-2 gap-x-10">
                    <ul class="ms-5 list-disc text-xs text-secondary">
                        <li class="pb-2">
                            لقد أقريت بالإطلاع على شروط وأحكام وثيقة التأمين
                            والموافقة عليها، دون أدنى مسئولية على قارن
                        </li>
                        <li class="pb-2">
                            لقد وافقت على منح شركة اثنان واربعون الوسيط لوساطة التأمين الإلكتروني الحق في
                            الاستعلام من شركة نجم لخدمات التأمين و / أو مركز
                            المعلومات الوطني و / أو أي جهات رسمية أخرى ذات
                            العلاقة عن بياناتي والبيانات الخاصة بالمركبة أو أي
                            بيانات أخرى ذات علاقة
                        </li>
                        <li class="pb-2">
                            لقد أقريت بأن الصور المرفوعة هي الصور الحقيقة
                            للمركبة المؤمن عليها، والتي تم أخذها مؤخرا ولم يتم
                            عليها التعديل بأي شكل من الأشكال. واقر بمعرفتي بأن
                            في حال اثبات عكس ذلك فإن هذا سيؤدي إلى الغاء وثيقة
                            التأمين وسيؤثر على المطالبات المرفوعة على المركبة
                        </li>
                    </ul>
                    <ul
                        class="ms-5 list-disc text-xs text-secondary"
                        style="direction: ltr"
                    >
                        <li class="pb-2">
                            I have read and agreed to the T&C of the Insurance
                            Policy without any liability on Qariin.
                        </li>
                        <li class="pb-2">
                            I declared to grant the Broker Care Company the
                            right to inquire from Najm Insurance Services and /
                            or National Information Center and / or other
                            relevant official systems on my data and data of
                            vehicles or any other relevant data
                        </li>
                        <li class="pb-2">
                            I declared that the vehicle images are of my
                            vehicle, were taken recently and have not been
                            amended in any way. I accept that the false
                            declaration could affect any claims or invalidate my
                            policy
                        </li>
                    </ul>
                </div>

                <div
                    class="grid grid-cols-2 gap-x-16 text-secondary text-xs pt-3 mt-6 pb-8 border-t"
                >
                    <div>
                        <p>
                            شركة اثنان واربعون لوساطة التأمين: الرياض - حي العليا طريق
                            الامير محمد بن عبدالعزيز, هاتف : 8001111051 بريد
                            الكتروني : support@qariin.com
                        </p>
                    </div>
                    <div>
                        <p>
                            QariinCompany: Riyadh - Al Olaya District, Prince
                            Muhammad bin Abdulaziz Road , Phone: 8001111051
                            Email: support@qariin.com
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { Head } from '@inertiajs/inertia-vue3'
import * as moment from "moment";

export default {
    name: "Invoice",
    components: { Head },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            malathOrGulfCompanyCode: ["1900", "13"],
            arabiaOrAmanaCompanyCode: ["1988"],
            acig: ["761"],
        };
    },
    computed: {
        basePrice() {
          let price = null;

          if (this.malathOrGulfCompanyCode.includes(this.data.company.code)) {
            price = this.data.product.prices.find(price => {
                return price.price_type.code === "SUB_PREM";
            });
          } else if (this.arabiaOrAmanaCompanyCode.includes(this.data.company.code)) {
            price = this.data.product.prices.find(price => {
                return price.price_type.english_description === "Basic Premium";
            });
             //this.data.product.gross;
          } else if (this.acig.includes(this.data.company.code)) {
            price = this.data.product.prices.find(price => {
                return price.price_type.english_description === "Basic Cover";
            });
          }

          return Number(price?.amount ?? 0).toFixed(2);
        },
        coversTotal() {
            if (!this.data.covers.length) return 0;
            const price = this.data.covers.reduce((sum, cover) => {
                return Number(cover.amount - cover.tax_amount) + Number(sum);
            }, 0);

            return price;
        },
        discount() {
            const discounts = this.data.product.prices.filter(price => price.is_discount);
            
            return Number(discounts.reduce((sum, discount) => {
                return Number(discount.amount) + Number(sum)
            }, 0)).toFixed(2);
        },
        subtotalBeforeTax() {
            return Number((Number(this.coversTotal) + Number(this.basePrice)) - Number(this.discount)).toFixed(2);
        },
        vat() {
            const vats = this.data.product.prices.filter(price => price.price_type.english_description.includes("VAT"));
            
            return Number(vats.reduce((sum, vat) => {
                return Number(vat.amount) + Number(sum)
            }, 0)).toFixed(2);
        }
    },
    methods: {
        formatDate(date) {
            return moment(date).format("MM/DD/YYYY");
        },
        getPrice(code, isAcig = false) {
            let price = null;
            if (!isAcig) {
                price =this.data.product.prices.find(price => {
                    return price.price_type.code === code;
                });
            } else {
                price =this.data.product.prices.find(price => {
                    return price.price_type.english_description === "Basic Cover";
                });
            }
        
            return Number(price.amount).toFixed(2);
        },
    },
};
</script>
