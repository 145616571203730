<template>
    <Modal ref="modal" title="البرامج الترويجية" small>
        <form v-if="!loading" ref="form" @submit.prevent class="max-w-md mx-auto">
            <ErrorsAlert
                v-if="errorMessages && errorMessages.length"
                :errors="errorMessages"
            />
            <p class="text-secondary-400 text-sm">
                للتحقق من انتسابكم لأحد الجهات المشاركة، يرجى إدخال بريد الجهة الخاص بك أو رقم الهوية أو الرمز الترويجي لاستكمال عملية التحقق.
            </p>
            <div class="my-4">
                <div class="flex items-center justify-start my-3">
                    <fieldset class="me-10">
                        <input type="radio" name="promotion-type" id="work-email" @change="promotion = false" />
                        <label for="work-email">بريد العمل</label>
                    </fieldset>
                    <fieldset>
                        <input type="radio" name="promotion-type" id="promotion-code" checked @change="promotion = true" />
                        <label for="promotion-code">الرمز الترويجي</label>
                    </fieldset>
                </div>
                <fieldset v-if="promotion" class="input-normal input-normal--xs">
                    <input
                        v-model="input.promotion_code"
                        type="text"
                        id="promotion-code"
                        name="promotion-code"
                        placeholder="الرمز الترويجي"
                    />
                </fieldset>
                <div v-else>
                    <fieldset
                        class="selectbox"
                        :style="`--icon: url('${
                            require('@qariin-fe/src/images/svg/arrow-down-secondary.svg')
                                .default
                        }');`"
                    >
                        <select
                            v-model="input.company"
                            required
                        >
                            <option value="null" disabled>اختار الجهة</option>
                            <option
                                v-for="company in companies"
                                :key="company.id"
                                :value="company.id"
                            >
                                {{ company.name_ar }}
                            </option>
                        </select>
                    </fieldset>
                    <fieldset
                        v-if="currentOffers.length"
                        class="selectbox"
                        :style="`--icon: url('${
                            require('@qariin-fe/src/images/svg/arrow-down-secondary.svg')
                                .default
                        }');`"
                    >
                        <select
                            v-model="input.promotion_offer"
                            required
                        >
                            <option value="null" disabled>اختر العرض</option>
                            <option
                                v-for="offer in currentOffers"
                                :key="offer.id"
                                :value="offer.id"
                            >
                                {{ offer.name_ar }}
                            </option>
                        </select>
                    </fieldset>
                    <fieldset class="input-normal input-normal--xs">
                        <input
                            v-model="input.email"
                            type="email"
                            required
                            placeholder="البريد الالكتروني"
                        />
                    </fieldset>
                </div>
                <p v-if="promotion && promotionNotFound" class="my-1 text-danger text-xs">
                    تم ادخال رمز خاطئ
                </p>
                <p v-if="promotion && promotionExpired" class="my-1 text-danger text-xs">
                    هذا الرمز لم يعد صالحاً للاستخدام
                </p>
                <p v-if="promotion && promotionValid" class="my-1 text-success text-xs">
                    تم التحقق من الرمز, برجاء حفظ التغيرات
                </p>
                <p v-if="promotionOfferIsValid" class="my-1 text-success text-xs">
                    تم التحقق من العرض, برجاء حفظ التغيرات
                </p>
            </div>
            <div class="flex items-center justify-between gap-4">
                <button
                    type="button"
                    class="text-center bg-primary text-white rounded-md px-md"
                    :class="{disabled: !isValid}"
                    @click="sendRequest()"
                    :disabled="!isValid"
                >
                    تحقق
                </button>
                <button
                    v-if="promotionValid || promotionOfferIsValid"
                    type="button"
                    class="text-center bg-white text-primary rounded-md px-md"
                    @click="raiseData()"
                >
                    حفظ التغيرات
                </button>
            </div>
        </form>

        <div v-else class="flex items-center justify-center my-16">
            <VueLottiePlayer
                style="width: 200px; height: 100px"
                name="Qariin loader"
                loop
                path="https://assets9.lottiefiles.com/private_files/lf30_1xgata3z.json"
            />
        </div>
    </Modal>
</template>

<script>
import { defineComponent } from "vue";
import Modal from "@/components/Modal";
import ErrorsAlert from "@/components/ErrorsAlert.vue";
// import axios from "axios";
import VueLottiePlayer from "vue-lottie-player";

export default defineComponent({
    name: "AdditionalInfoModal",
    components: {
        Modal,
        ErrorsAlert,
        VueLottiePlayer,
    },
    data() {
        return {
            open: false,
            errorMessages: [],
            loading: false,
            dataCleared: false,
            promotion: true,
            promotionExpired: false,
            promotionNotFound: false,
            promotionValid: false,
            promotionId: false,
            promotionOfferId: null,
            promotionOfferIsValid: false,
            companies: null,
            currentOffers: [],
            input: {
                promotion_code: null,
                promotion_offer: null,
                company: null,
                email: null,
            },
        };
    },
    computed: {
        isValid() {
            if (this.promotion)
                return this.input.promotion_code;
            else
                return this.input.company && this.input.promotion_offer && this.input.email && this.$refs.form.checkValidity();
        },
    },
    watch: {
        'input.company'(value) {
            if (!value) return;

            const id = value;
            const offers = this.companies.find(company => {
                return company.id == id
            }).promotion_offers;

            this.currentOffers = offers;
        },
    },
    methods: {
        toggleModal() {
            this.$refs.modal.toggleModal();
        },
        raiseData() {
            let data = { ...this.input };

            Object.keys(data).forEach((key) => {
                if (!data[key]) delete data[key];
            });

            if (this.promotion) {
                // eslint-disable-next-line vue/require-explicit-emits
                this.$emit("saved", {code: true, id: this.promotionId});
                this.promotionNotFound = false;
                this.promotionExpired = false;
                this.promotionValid = false;
                this.input.promotion_code = null;
                this.toggleModal();
            }


            if (this.promotionOfferIsValid) {
                // eslint-disable-next-line vue/require-explicit-emits
                this.$emit("saved", {promotionOffer: true, id: this.promotionOfferId});
                this.input.company = null;
                this.input.promotion_offer = null;
                this.input.email = null;
                this.toggleModal();
            }
        },
        async getWorkLocations() {
            await axios
                .get(route('get.promotion.company.index'))
                .then(({ data }) => {
                    this.companies = data.data;
                });
        },
        async sendRequest() {
            if (this.promotion) {
                const code = this.input.promotion_code;

                axios.get(route('get.coupons.details', code))
                    .then(data => {
                        this.promotionId = data.data.data.id;
                        this.promotionValid = true;
                        this.promotionNotFound = false;
                        this.promotionExpired = false;
                    }, error => {
                        const status = error.request.status;
                        switch(Number(status)) {
                            case 404:
                                this.promotionNotFound = true;
                                this.promotionExpired = false;
                                this.promotionValid = false;
                                break;
                            case 410:
                                this.promotionExpired = true;
                                this.promotionNotFound = false;
                                this.promotionValid = false;
                                break;
                        }
                        console.error(error);
                    })
            } else {

                this.errorMessages = [];
                const promotion_company_id = this.input.company;
                const promotion_offer_id = this.input.promotion_offer;
                const user_email = this.input.email;

                axios.post(route('promotion.company.send.email'), {
                    promotion_company_id,
                    promotion_offer_id,
                    user_email,
                })
                    .then(data => {
                        this.promotionOfferIsValid = true;
                        this.promotionOfferId = data.data.data.id;
                    }, error => {
                        var { response } = error;
                        var { data } = response;

                        if (data.errors)
                            Object.keys(data.errors).forEach((key) => {
                                this.errorMessages.push(data.errors[key][0]);
                            });
                        else this.errorMessages.push(data.message);
                        console.error(error);
                    })
            }
        },
    },
    async mounted() {
       await this.getWorkLocations();
    }
});
</script>
