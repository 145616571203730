<template>
  <article v-if="!comprehensive" class="offers__offer">
    <div class="offers__offer__title">
      <h3>
        <span>{{ company?.name_ar }}</span>
      </h3>
    </div>
    <div class="grid grid-cols-2 lg:grid-cols-10">
      <figure class="border-e col-span-2">
        <img
          v-if="company.avatar"
          :src="getAttachment(company.avatar)"
          class="w-40 max-w-xs h-32 object-contain mx-auto pointer-events-none"
          alt="Insurance Company"
        />
        <div class="flex items-center justify-center flex-col mt-2">
          <img
            :src="
              require(`@qariin-fe/src/images/integration-speed/${company.integration_speed}.svg`)
                .default
            "
            class="w-16 h-16 pointer-events-none object-contain"
            alt="Link speed"
          />
          <p class="text-secondary">
              {{ $t('offers_speed') }}
          </p>
          <a
              v-if="company.terms_and_conditions"
              :href="getAttachment(company.terms_and_conditions)"
              target="_blank"
              class="min-height-full text-secondary"
            >
              {{ $t('offers_terms') }}
          </a>
        </div>
      </figure>
      <div class="p-xs border-e col-span-3">
        <h3 class="text-sm text-secondary mb-3">
            {{ $t('offers_includes') }}
        </h3>
        <ul class="offers__features">
          <li v-for="item in offerBasicCovers(offer)" :key="item.id">
            <span v-if="!item.additional">{{ item.cover_name_ar }}</span>
          </li>
        </ul>

        <ul v-if="!offerBasicCovers(offer).length" class="offers__noFeatures">
          <li class="empty">
              {{ $t('offers_no_benefits') }}
          </li>
        </ul>
      </div>
      <div class="p-xs border-e col-span-3">
        <h3 class="text-sm text-secondary mb-3">
            {{ $t('offers_add_benefits') }}
        </h3>
        <ul v-if="offerAdditionalCovers(offer).length" class="offers__noFeatures">
          <li v-for="(item, index) in offerAdditionalCovers(offer)" :key="item.id">
            <fieldset class="max-w-xs mb-3">
              <input
                type="checkbox"
                :id="`${item.id}#${index}`"
                @change="toggleAdditionalCover(offer,item)"
              />
              <label :for="`${item.id}#${index}`" :class="item.isDimmed ? 'text-xs ps-1 text-secondary' : 'text-xs ps-1'">
                {{ item.cover_name_ar }}
                <small class="ps-3 text-secondary">
                  {{ item.amount }} {{ $t('sar') }}
                </small>
              </label>
            </fieldset>
          </li>
        </ul>
        <ul v-else class="offers__noFeatures">
          <li class="empty">
              {{ $t('offers_no_add_benefits') }}
          </li>
        </ul>
      </div>
      <div class="col-span-2">
        <ul class="offers__pricing px-1">
          <li>
            <small>
                {{ $t('offers_price_base') }}
            </small>
            <b>
              {{ getMainAmount(offer).toFixed(2) }}
              {{ $t('sar') }}
            </b>
          </li>
          <li :key="item.id" v-for="item in getAddonsAndDiscounts(offer)">
            <small :title="item.price_type.arabic_description">{{ item.price_type.arabic_description }}</small>
            <b>
              {{ Number(item.amount).toFixed(2) }}
              {{ $t('sar') }}
            </b>
          </li>
          <li>
            <small>
                {{ $t('offers_price_total') }}
            </small>
            <b>
              {{ getTotalAmount(offer).toFixed(2) }}
              {{ $t('sar') }}
            </b>
          </li>
          <li>
            <small>
                {{ $t('offers_price_vat') }}
            </small>
            <b>
              {{ getVat(offer).toFixed(2) }}
              {{ $t('sar') }}
            </b>
          </li>
          <li class="border-t">
              <small>
                {{ $t('offers_price_total_after_vat') }}
              </small>
              <b>
                {{ Number(getTotalAmount(offer) + getVat(offer)).toFixed(2) }}
                {{ $t('sar') }}
              </b>
          </li>
        </ul>
        <jet-authentication-card simple>
          <form :action="route('orders.store')" method="post">
            <jet-input type="hidden" name="company_id" :value="company.id" />
            <jet-input type="hidden" name="product_id" :value="offer.id" />
            <jet-input type="hidden" name="search_id" :value="searchId" />
            <jet-input
              type="hidden"
              name="quotation_id"
              :value="offer.quotation_id"
            />
            <jet-input type="hidden" name="first_step" :value="1" />
            <jet-input type="hidden" name="product_type_code" :value="1" />
            <jet-input type="hidden" name="quotation_reference" :value="1" />
            <jet-input
              v-for="(cover, index) in offer.selectedAdditionalCovers"
              :key="cover"
              type="hidden"
              :name="`order_covers[${index}]`"
              :value="cover"
            />
            <jet-input type="hidden" name="_token" :value="csrf" />
            <jet-button type="submit" class="offers__buyNow">
              {{ $t('offers_buy') }}
            </jet-button>
          </form>
        </jet-authentication-card>
        <a
          v-if="comprehensiveOffers.length"
          href="#"
          class="offers__fullPackage"
          @click.prevent="$refs.comprehensiveOffer.toggleModal"
        >
          <span>
            {{ $t('offers_comprehensive_price') }}
          </span>
          <span>
            {{
              parseFloat(
                getTotalAmount(
                  sortedComprehensiveOffers[0]
                ) + getVat(sortedComprehensiveOffers[0])
              ).toFixed(2)
            }}
            {{ $t('sar') }}
          </span>
        </a>
      </div>
    </div>
    <Modal v-if="comprehensiveOffers" wide ref="comprehensiveOffer" :title="offer.company.name_ar">
        <template
          v-if="comprehensiveOffers.length"
        >
          <article
            class="offers__offer"
            v-for="comOffer in sortedComprehensiveOffers"
            :key="comOffer.id"
          >
            <div class="grid grid-cols-2 lg:grid-cols-10">
              <figure class="border-e col-span-2">
                <img
                  v-if="offer.company.avatar"
                  :src="getAttachment(offer.company.avatar)"
                  class="
                  w-40
                  max-w-xs
                  h-32
                  object-contain
                  mx-auto
                  pointer-events-none
                "
                  alt="Insurance Company"
                />
                <div class="flex items-center justify-center flex-col">
                  <img
                    :src="require(`@qariin-fe/src/images/integration-speed/${offer.company.integration_speed}.svg`)
                        .default
                      "
                    class="w-16 h-16 pointer-events-none object-contain"
                    alt="Link speed"
                  />
                  <p class="text-secondary">
                    {{ $t('offers_speed') }}
                  </p>
                  <a
                    v-if="offer.company.terms_and_conditions"
                    :href="getAttachment(offer.company.terms_and_conditions)"
                    target="_blank"
                    class="min-height-full text-secondary"
                  >
                  {{ $t('offers_terms') }}
                  </a>
                </div>
              </figure>
              <div class="p-xs border-e col-span-3">
                <h3 class="text-sm text-secondary mb-3">
                  {{ $t('offers_includes') }}
                </h3>
                <ul class="offers__features">
                  <li
                    v-for="item in offerBasicCovers(comOffer)"
                    :key="item.id"
                  >
                    <span v-if="!item.additional">{{ item.cover_name_ar }}</span>
                  </li>
                </ul>

                <ul
                  v-if="!offerBasicCovers(comOffer).length"
                  class="offers__noFeatures"
                >
                  <li class="empty">
                      {{ $t('offers_no_benefits') }}
                  </li>
                </ul>
              </div>
              <div class="p-xs border-e col-span-3">

                <h3 class="text-sm text-secondary mb-3">
                  {{ $t('offers_add_benefits') }}
                </h3>

                <ul v-if="offerAdditionalCovers(comOffer).length" class="offers__noFeatures">
                  <li
                    v-for="(item, index) in offerAdditionalCovers(
                      comOffer
                    )"
                    :key="item.id"
                  >
                    <fieldset class="max-w-xs mb-3">
                      <input
                        type="checkbox"
                        :id="`${item.id}#${index}`"
                        @change="
                          toggleAdditionalCover(comOffer, item)
                          "
                      />
                      <label :for="`${item.id}#${index}`" :class="item.isDimmed ? 'text-xs ps-1 text-secondary' : 'text-xs ps-1'">
                        {{ item.cover_name_ar }}
                        <small class="ps-3 text-secondary">
                          {{ item.amount }} {{ $t('sar') }}
                        </small>
                      </label>
                    </fieldset>
                  </li>
                </ul>
                <ul v-else class="offers__noFeatures">
                  <li class="empty">
                      {{ $t('offers_no_benefits') }}
                  </li>
                </ul>
              </div>
              <div class="col-span-2">
                <ul class="offers__pricing px-1">
                  <li>
                    <small>
                        {{ $t('offers_price_base') }}
                    </small>
                    <b>
                      {{ getMainAmount(comOffer).toFixed(2) }}
                      {{ $t('sar') }}
                    </b>
                  </li>
                  <li
                    :key="item.id"
                    v-for="item in getAddonsAndDiscounts(comOffer)"
                  >
                    <small :title="item.price_type.arabic_description">{{ item.price_type.arabic_description }}</small>
                    <b>
                      {{ Number(item.amount).toFixed(2) }}
                      {{ $t('sar') }}
                    </b>
                  </li>
                  <li>
                    <small>
                        {{ $t('offers_price_total') }}
                    </small>
                    <b>
                      {{ getTotalAmount(comOffer).toFixed(2) }}
                      {{ $t('sar') }}
                    </b>
                  </li>

                  <li>
                    <small>
                        {{ $t('offers_price_vat') }}
                    </small>
                    <b>
                      {{ getVat(comOffer).toFixed(2) }}
                      {{ $t('sar') }}
                    </b>
                  </li>
                  <li class="border-t">
                    <small>
                      {{  $t("offers_price_endurance") }}
                    </small>
                    <b v-if="comOffer.excess_value || comOffer.deductable">
                      {{ comOffer.excess_value ? comOffer.excess_value : comOffer.deductable }} {{ $t('sar') }}
                    </b>
                    <b v-else>
                      2000 {{ $t('sar') }}
                    </b>
                  </li>
                  <li>
                    <span class="offers__vehicleValue">
                      {{ $t('home_vehicle_value') }} {{ Number(vehicleValue).toFixed(2) }} {{ $t('sar') }}
                    </span>
                  </li>
                  <li class="border-t">
                    <small :title="$t('offers_price_total_after_vat')">
                      {{ $t('offers_price_total_after_vat') }}
                    </small>
                    <b>
                      {{
                        Number(
                          getTotalAmount(comOffer) + getVat(comOffer)
                        ).toFixed(2)
                      }}
                      {{ $t('sar') }}
                    </b>
                  </li>
                </ul>

                <jet-authentication-card simple>
                  <form :action="route('orders.store')" method="post">
                    <jet-input
                      type="hidden"
                      name="company_id"
                      :value="offer.company.id"
                    />
                    <jet-input
                      type="hidden"
                      name="product_id"
                      :value="comOffer.id"
                    />
                    <jet-input type="hidden" name="search_id" :value="searchId" />
                    <jet-input
                      type="hidden"
                      name="quotation_id"
                      :value="comOffer.quotation_id"
                    />
                    <jet-input type="hidden" name="first_step" :value="1" />
                    <jet-input
                      type="hidden"
                      name="product_type_code"
                      :value="2"
                    />
                    <jet-input
                      type="hidden"
                      name="quotation_reference"
                      :value="2"
                    />
                    <jet-input type="hidden" name="_token" :value="csrf" />
                    <jet-input
                      v-for="(cover, index) in comOffer.selectedAdditionalCovers"
                      :key="cover"
                      type="hidden"
                      :name="`order_covers[${index}]`"
                      :value="cover"
                    />
                    <jet-button type="submit" class="offers__buyNow">
                      {{ $t('offers_buy') }}
                    </jet-button>
                  </form>
                </jet-authentication-card>
              </div>
            </div>
          </article>
        </template>
      </Modal>
  </article>
  <article
    v-else-if="comprehensive && offer.deductible_offers && offer.deductible_offers.length > 1 && activeOffer"
    class="offers__offer"
  >
    <div class="offers__offer__title">
      <h3>
        <span>{{ company?.name_ar }}</span>
      </h3>
    </div>
    <div class="grid grid-cols-2 lg:grid-cols-10">
      <figure class="border-e col-span-2">
        <img
          v-if="company.avatar"
          :src="getAttachment(company.avatar)"
          class="
            w-40
            max-w-xs
            h-32
            object-contain
            mx-auto
            pointer-events-none
          "
          alt="Insurance Company"
        />
        <div class="flex items-center justify-center flex-col">
          <img
            :src="
              require(`@qariin-fe/src/images/integration-speed/${company.integration_speed}.svg`)
                .default
            "
            class="w-16 h-16 pointer-events-none object-contain"
            alt="Link speed"
          />
          <p class="text-secondary">
              {{ $t('offers_speed') }}
          </p>
          <a
            v-if="company.terms_and_conditions"
            :href="getAttachment(company.terms_and_conditions)"
            target="_blank"
            class="min-height-full text-secondary"
          >
            {{ $t('offers_terms') }}
          </a>
        </div>
      </figure>
      <div class="p-xs border-e col-span-3">
        <h3 class="text-sm text-secondary mb-3">
            {{ $t('offers_includes') }}
        </h3>
        <ul class="offers__features">
          <li
            v-for="item in offerBasicCovers(activeOffer)"
            :key="item.id"
          >
            <span v-if="!item.additional">{{ item.cover_name_ar }}</span>
          </li>
        </ul>

        <ul
          v-if="!offerBasicCovers(activeOffer).length"
          class="offers__noFeatures"
        >
          <li class="empty">
            {{ $t('offers_no_benefits') }}
          </li>
        </ul>
      </div>
      <div class="p-xs border-e col-span-3">
        <h3 class="text-sm text-secondary mb-3">
            {{ $t('offers_add_benefits') }}
        </h3>
        <ul v-if="offerAdditionalCovers(activeOffer).length" class="offers__noFeatures">
          <li
            v-for="(item, index) in offerAdditionalCovers(
              offer
            )"
            :key="item.id"
          >
            <fieldset class="max-w-xs mb-3">
              <input
                type="checkbox"
                :id="`${item.id}#${index}`"
                :checked="activeOffer.selectedAdditionalCovers.includes(item.id)"
                @change="
                  toggleAdditionalCover(activeOffer, item)
                "
              />
              <label :for="`${item.id}#${index}`" :class="item.isDimmed ? 'text-xs ps-1 text-secondary' : 'text-xs ps-1'">
                {{ item.cover_name_ar }}
                <small class="ps-3 text-secondary">
                  {{ item.amount }} {{ $t('sar') }}
                </small>
              </label>
            </fieldset>
          </li>
        </ul>
        <ul v-else class="offers__noFeatures">
          <li class="empty">
            {{ $t('offers_no_benefits') }}
          </li>
        </ul>
      </div>
      <div class="col-span-2">
        <ul class="offers__pricing px-1">
          <li>
            <small>
                {{ $t('offers_price_base') }}
            </small>
            <b>
              {{ getMainAmount(activeOffer).toFixed(2) }}
              {{ $t('sar') }}
            </b>
          </li>
          <li
            :key="item.id"
            v-for="item in getAddonsAndDiscounts(activeOffer)"
          >
            <small :title="item.price_type.arabic_description">{{ item.price_type.arabic_description }}</small>
            <b>
              {{ Number(item.amount).toFixed(2) }}
              {{ $t('sar') }}
            </b>
          </li>
          <li>
            <small>
                {{ $t('offers_price_total') }}
            </small>
            <b>
              {{ getTotalAmount(activeOffer).toFixed(2) }}
              {{ $t('sar') }}
            </b>
          </li>

          <li>
            <small>
                {{ $t('offers_price_vat') }}
            </small>
            <b>
              {{ getVat(activeOffer).toFixed(2) }}
              {{ $t('sar') }}
            </b>
          </li>
          <li class="offers__deductables">
            <small>
              {{  $t("offers_price_endurance") }}
            </small>
            <fieldset>
              <select
                name="product_deductable"
                id="product-deductables"
                v-model="selectedDeductible"
                @change="setComprehensiveOffer"
              >
                <option
                  :key="deductable.id"
                  v-for="deductable in deductibles"
                  :value="deductable"
                >
                  {{ deductable }}
                </option>
              </select>
              {{ $t('sar') }}
            </fieldset>
          </li>
          <li>
            <span class="offers__vehicleValue">
              قيمة المركبة {{ Number(vehicleValue).toFixed(2) }} {{ $t('sar') }}
            </span>
          </li>
          <li class="border-t">
            <small :title="$t('offers_price_total_after_vat')">
                {{ $t('offers_price_total_after_vat') }}
            </small>
            <b>
              {{
                Number(
                  getTotalAmount(activeOffer) + getVat(offer)
                ).toFixed(2)
              }}
              {{ $t('sar') }}
            </b>
          </li>
        </ul>

        <jet-authentication-card simple>
          <form :action="route('orders.store')" method="post">
            <jet-input
              type="hidden"
              name="company_id"
              :value="activeOffer.company.id"
            />
            <jet-input
              type="hidden"
              name="product_id"
              :value="activeOffer.id"
            />
            <jet-input type="hidden" name="search_id" :value="searchId" />
            <jet-input
              type="hidden"
              name="quotation_id"
              :value="activeOffer.quotation_id"
            />
            <jet-input type="hidden" name="first_step" :value="1" />
            <jet-input
              type="hidden"
              name="product_type_code"
              :value="2"
            />
            <jet-input
              type="hidden"
              name="quotation_reference"
              :value="2"
            />
            <jet-input type="hidden" name="_token" :value="csrf" />
            <jet-input
              v-for="(cover, index) in activeOffer.selectedAdditionalCovers"
              :key="cover"
              type="hidden"
              :name="`order_covers[${index}]`"
              :value="cover"
            />
            <jet-button type="submit" class="offers__buyNow">
              {{ $t('offers_buy') }}
            </jet-button>
          </form>
        </jet-authentication-card>
      </div>
    </div>
  </article>
   <article
    v-else-if="comprehensive && offer.id"
    class="offers__offer"
  >
    <div class="offers__offer__title">
      <h3>
        <span>{{ company?.name_ar }}</span>
      </h3>
    </div>
    <div class="grid grid-cols-2 lg:grid-cols-10">
      <figure class="border-e col-span-2">
        <img
          v-if="company.avatar"
          :src="getAttachment(company.avatar)"
          class="
            w-40
            max-w-xs
            h-32
            object-contain
            mx-auto
            pointer-events-none
          "
          alt="Insurance Company"
        />
        <div class="flex items-center justify-center flex-col">
          <img
            :src="
              require(`@qariin-fe/src/images/integration-speed/${company.integration_speed}.svg`)
                .default
            "
            class="w-16 h-16 pointer-events-none object-contain"
            alt="Link speed"
          />
          <p class="text-secondary">سرعة الربط</p>
          <a
            v-if="company.terms_and_conditions"
            :href="getAttachment(company.terms_and_conditions)"
            target="_blank"
            class="min-height-full text-secondary"
          >
            الشروط والأحكام
          </a>
        </div>
      </figure>
      <div class="p-xs border-e col-span-3">
        <h3 class="text-sm text-secondary mb-3">
          التأمين يشمل (مجاناً):
        </h3>
        <ul class="offers__features">
          <li
            v-for="item in offerBasicCovers(offer)"
            :key="item.id"
          >
            <span v-if="!item.additional">{{ item.cover_name_ar }}</span>
          </li>
        </ul>

        <ul
          v-if="!offerBasicCovers(offer).length"
          class="offers__noFeatures"
        >
        <li class="empty">
            {{ $t('offers_no_benefits') }}
        </li>
      </ul>
      </div>
      <div class="p-xs border-e col-span-3">
        <h3 class="text-sm text-secondary mb-3">
            {{ $t('offers_add_benefits') }}
        </h3>
        <ul v-if="offerAdditionalCovers(offer).length" class="offers__noFeatures">
          <li
            v-for="(item, index) in offerAdditionalCovers(
              offer
            )"
            :key="item.id"
          >
            <fieldset class="max-w-xs mb-3">
              <input
                type="checkbox"
                :id="`${item.id}#${index}`"
                @change="
                  toggleAdditionalCover(offer, item)
                "
              />
              <label :for="`${item.id}#${index}`" :class="item.isDimmed ? 'text-xs ps-1 text-secondary' : 'text-xs ps-1'">
                {{ item.cover_name_ar }}
                <small class="ps-3 text-secondary">
                  {{ item.amount }} {{ $t('sar') }}
                </small>
              </label>
            </fieldset>
          </li>
        </ul>
        <ul v-else class="offers__noFeatures">
          <li class="empty">
            {{ $t('offers_no_benefits') }}
          </li>
        </ul>
      </div>
      <div class="col-span-2">
        <ul class="offers__pricing px-1">
          <li>
            <small>
                {{ $t('offers_price_base') }}
            </small>
            <b>
              {{ getMainAmount(offer).toFixed(2) }}
              {{ $t('sar') }}
            </b>
          </li>
          <li
            :key="item.id"
            v-for="item in getAddonsAndDiscounts(offer)"
          >
            <small :title="item.price_type.arabic_description">{{ item.price_type.arabic_description }}</small>
            <b>
              {{ Number(item.amount).toFixed(2) }}
              {{ $t('sar') }}
            </b>
          </li>
          <li>
            <small>
                {{ $t('offers_price_total') }}
            </small>
            <b>
              {{ getTotalAmount(offer).toFixed(2) }}
              {{ $t('sar') }}
            </b>
          </li>

          <li>
            <small>
                {{ $t('offers_price_vat') }}
            </small>
            <b>
              {{ getVat(offer).toFixed(2) }}
              {{ $t('sar') }}
            </b>
          </li>
          <li class="border-t">
            <small>
              {{  $t("offers_price_endurance") }}
            </small>
            <b>
              2000 {{ $t('sar') }}
            </b>
          </li>
          <li>
            <span class="offers__vehicleValue">
               {{ $t('home_vehicle_value') }} {{ Number(vehicleValue).toFixed(2) }} {{ $t('sar') }}
            </span>
          </li>
          <li class="border-t">
            <small :title="$t('offers_price_total_after_vat')">
                {{ $t('offers_price_total_after_vat') }}
            </small>
            <b>
              {{
                Number(
                  getTotalAmount(offer) + getVat(offer)
                ).toFixed(2)
              }}
              {{ $t('sar') }}
            </b>
          </li>
        </ul>

        <jet-authentication-card simple>
          <form :action="route('orders.store')" method="post">
            <jet-input
              type="hidden"
              name="company_id"
              :value="company.id"
            />
            <jet-input
              type="hidden"
              name="product_id"
              :value="offer.id"
            />
            <jet-input type="hidden" name="search_id" :value="searchId" />
            <jet-input
              type="hidden"
              name="quotation_id"
              :value="offer.quotation_id"
            />
            <jet-input type="hidden" name="first_step" :value="1" />
            <jet-input
              type="hidden"
              name="product_type_code"
              :value="2"
            />
            <jet-input
              type="hidden"
              name="quotation_reference"
              :value="2"
            />
            <jet-input type="hidden" name="_token" :value="csrf" />
            <jet-input
              v-for="(cover, index) in offer.selectedAdditionalCovers"
              :key="cover"
              type="hidden"
              :name="`order_covers[${index}]`"
              :value="cover"
            />
            <jet-button type="submit" class="offers__buyNow">
              {{ $t('offers_buy') }}
            </jet-button>
          </form>
        </jet-authentication-card>
      </div>
    </div>
  </article>
</template>

<script>
import { defineComponent } from "vue";
import JetAuthenticationCard from "@/Jetstream/AuthenticationCard.vue";
import Modal from "@/components/Modal.vue";
import JetInput from "@/Jetstream/Input.vue";
import JetButton from "@/Jetstream/Button.vue";
// import JetValidationErrors from "@/Jetstream/ValidationErrors.vue";

export default defineComponent({
  name: "Offer",
  components: {
    JetAuthenticationCard,
    JetInput,
    JetButton,
    Modal,
    // JetValidationErrors,
  },
  props: {
    offer: {
      type: Object,
      required: true,
    },
    comprehensiveOffers: {
      type: Object,
      default: () => {},
    },
    company: {
      type: Object,
      required: true,
    },
    searchId: {
      type: String,
      required: true,
      default: "",
    },
    csrf: {
      type: String,
      required: true,
      default: "",
    },
    comprehensiveQuotationId: {
      type: String,
      default: "",
    },
    tax: {
      type: Number,
      required: false,
      default: 0,
    },
    vehicleValue: {
      type: Number,
      required: true,
      default: 0,
    },
    comprehensive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mainComprehensiveOffers: [],
      selectedDeductible: null,
      activeOffer: null,
      companiesCodes: {
        arabia: "1988",
        khaleej: "13",
        acig: "761",
        malath: "1900",
      },
    };
  },

  computed: {
    // basicCovers() {
    //   return this.offer.covers.filter((item) => !item.additional);
    // },
    deductibles() {
      if (!this.offer.deductible_offers || !this.offer.deductible_offers.length) return;
      let deductibles = [];
      this.offer.deductible_offers.forEach(offer => {
        // eslint-disable-next-line no-extra-boolean-cast
        if (!!offer.deductable) deductibles.push(offer.deductable)
        // eslint-disable-next-line no-extra-boolean-cast
        else if (!!offer.excess_value) deductibles.push(offer.excess_value)
      })

      deductibles = deductibles.sort((a, b) => parseFloat(a) - parseFloat(b)).reverse();

      return deductibles;
    },
    sortedComprehensiveOffers() {
      if (!this.comprehensiveOffers.length) return;
      let offers = this.comprehensiveOffers;
      offers = offers.sort((a, b) => {
        let firstDeductible = parseFloat(a.deductable || a.excess_value);
        let secondDeductible = parseFloat(b.deductable || b.excess_value);
        return firstDeductible - secondDeductible;
      });

      return offers.reverse();
    },
  },

  methods: {
    // getVat(offer) {
    //     return Number(
    //         offer.prices.find((price) => {
    //             return (
    //                 price.price_type?.code == "VAT" ||
    //                 (price.feature_code == 11 &&
    //                     price.feature_price_type == "Breakdown")
    //             );
    //         })?.amount
    //     );
    // },
    getAdditionalCoverAmount(offer){
        return (offer.selectedAdditionalCoversAmount ? offer.selectedAdditionalCoversAmount : 0);
    },
    getAdditionalCoverTaxes(offer){
        return (offer.selectedAdditionalCoversTaxes ? offer.selectedAdditionalCoversTaxes : 0);
    },
    getVat(offer) {
      switch (this.company.code) {
        case this.companiesCodes["arabia"]: {
          return this.getArabiaVat(offer) + this.getAdditionalCoverTaxes(offer);
        }
        case this.companiesCodes["khaleej"]: {
          return this.getKhlaeejVat(offer) + this.getAdditionalCoverTaxes(offer);
        }
        case this.companiesCodes["malath"]: {
          return this.getKhlaeejVat(offer) + this.getAdditionalCoverTaxes(offer);
        }
        case this.companiesCodes["acig"]: {
          return this.getAcigVat(offer) + this.getAdditionalCoverTaxes(offer);
        }
        default: {
          return 0 + this.getAdditionalCoverTaxes(offer);
        }
      }
    },
    getArabiaVat(offer) {
      return Number(
        offer.prices.find((price) => {
          return (
            price.price_type.english_description == "VAT" ||
            price.price_type.code == 11
          );
        })?.amount ?? 0
      );
    },
    getKhlaeejVat(offer) {
      return Number(
        offer.prices.find((price) => {
          return price.price_type.code == "VAT";
        })?.amount ?? 0
      );
    },
    getAcigVat(offer) {
      return Number(offer.total_tax);
    },
    getDiscount(offer) {
      return Number(
        offer.prices.find((price) => !!price.is_discount)?.amount
      ).toFixed(2);
    },
    getMainAmount(offer) {
      switch (this.company.code) {
        case this.companiesCodes["arabia"]: {
          return this.getArabiaMainAmount(offer);
        }
        case this.companiesCodes["khaleej"]: {
          return this.getKhlaeejMainAmount(offer);
        }
        case this.companiesCodes["malath"]: {
          return this.getKhlaeejMainAmount(offer);
        }
        case this.companiesCodes["acig"]: {
          return this.getAcigMainAmount(offer);
        }
        default: {
          return 0;
        }
      }
    },
    getArabiaMainAmount(offer) {
      return Number(
        offer.prices.find(
          (price) => price.price_type.english_description == "Basic Premium"//"Gross"
        )?.amount
      );
    },
    getKhlaeejMainAmount(offer) {
      return Number(
        offer.prices.find(
          (price) => price.price_type.english_description == "Basic Premium"
        )?.amount
      );
    },
    getAcigMainAmount(offer) {
      return Number(offer.gross_premium);
    },
    getAddonsAndDiscounts(offer) {
      switch (this.company.code) {
        case this.companiesCodes["arabia"]: {
          return this.getArabiaAddonsAndDiscounts(offer);
        }
        case this.companiesCodes["khaleej"]: {
          return this.getKhlaeejAddonsAndDiscounts(offer);
        }
        case this.companiesCodes["malath"]: {
          return this.getKhlaeejAddonsAndDiscounts(offer);
        }
        case this.companiesCodes["acig"]: {
          return this.getAcigAddonsAndDiscounts(offer);
        }
        default: {
          return [];
        }
      }
    },
    getArabiaAddonsAndDiscounts(offer) {
      let items = [];
      offer.prices?.forEach(function (price) {
        if (
          price.price_type.english_description != "VAT" &&
          price.price_type.code != 11 &&
          price.price_type.english_description != "Gross"
        ) {
          items.push(price);
        }
      });
      return items;
    },
    getKhlaeejAddonsAndDiscounts(offer) {
      let items = [];
      offer.prices?.forEach(function (price) {
        if (
          price.price_type.code != "VAT" &&
          price.price_type.english_description != "Basic Premium"
        ) {
          items.push(price);
        }
      });
      return items;
    },
    getAcigAddonsAndDiscounts(offer) {
      let items = [];
      offer.prices?.forEach(function (price) {
        if (price.is_discount == 1) {
          items.push(price);
        }
      });
      return items;
    },
    getTotalAmount(offer) {
      switch (this.company.code) {
        case this.companiesCodes["arabia"]: {
          return this.getArabiaTotalAmount(offer) + this.getAdditionalCoverAmount(offer);
        }
        case this.companiesCodes["khaleej"]: {
          return this.getKhlaeejTotalAmount(offer) + this.getAdditionalCoverAmount(offer);
        }
        case this.companiesCodes["malath"]: {
          return this.getKhlaeejTotalAmount(offer) + this.getAdditionalCoverAmount(offer);
        }
        case this.companiesCodes["acig"]: {
          return this.getAcigTotalAmount(offer) + this.getAdditionalCoverAmount(offer);
        }
        default: {
          return 0 + this.getAdditionalCoverAmount(offer);
        }
      }
    },
    getArabiaTotalAmount(offer) {
      var mainAmount = this.getArabiaMainAmount(offer);
      var addonsAndDiscounts = this.getArabiaAddonsAndDiscounts(offer);
      addonsAndDiscounts?.forEach(function (item) {
        if (item.is_discount) {
          mainAmount = mainAmount - parseFloat(item.amount);
        } else {
          mainAmount = mainAmount + parseFloat(item.amount);
        }
      });
      return Number(mainAmount);
    },
    getKhlaeejTotalAmount(offer) {
      var mainAmount = this.getKhlaeejMainAmount(offer);
      var addonsAndDiscounts = this.getKhlaeejAddonsAndDiscounts(offer);
      addonsAndDiscounts?.forEach(function (item) {
        if (item.is_discount) {
          mainAmount = mainAmount - parseFloat(item.amount);
        } else {
          mainAmount = mainAmount + parseFloat(item.amount);
        }
      });
      return Number(mainAmount);
    },
    getAcigTotalAmount(offer) {
      var mainAmount = this.getAcigMainAmount(offer);
      var addonsAndDiscounts = this.getAcigAddonsAndDiscounts(offer);
      addonsAndDiscounts?.forEach(function (item) {
        if (item.is_discount) {
          mainAmount = mainAmount - parseFloat(item.amount);
        } else {
          mainAmount = mainAmount + parseFloat(item.amount);
        }
      });
      return Number(mainAmount);
    },
    setComprehensiveOffer() {
      this.activeOffer = this.offer.deductible_offers.find(offer => {
        return offer.excess_value == this.selectedDeductible || offer.deductable == this.selectedDeductible;
      });
      this.activeOffer.company = this.offer.company;
    },
    getArabiaComprehensiveOffers() {
      var comprehensiveItemIndex = this.selectedDeductible
        ? this.detuctables.findIndex((d) => this.selectedDeductible.id == d.id)
        : 0;
      if (this.comprehensiveOffers && this.comprehensiveOffers.length) {
        this.mainComprehensiveOffers = [
          this.comprehensiveOffers[comprehensiveItemIndex],
        ];
      }
    },
    getKhlaeejComprehensiveOffers() {
      var comprehensiveItemIndex = this.selectedDeductible
        ? this.detuctables.findIndex((d) => this.selectedDeductible.id == d.id)
        : 0;
      if (this.comprehensiveOffers && this.comprehensiveOffers.length) {
        this.mainComprehensiveOffers = [
          this.comprehensiveOffers[comprehensiveItemIndex],
        ];
      }
    },
    getMalathComprehensiveOffers() {
      var comprehensiveItemIndex = this.selectedDeductible
        ? this.detuctables.findIndex((d) => this.selectedDeductible.id == d.id)
        : 0;
      if (this.comprehensiveOffers && this.comprehensiveOffers.length) {
        this.mainComprehensiveOffers = [
          this.comprehensiveOffers[comprehensiveItemIndex],
        ];
      }
    },
    getAcigComprehensiveOffers() {
      var comprehensiveItemIndex = this.selectedDeductible
        ? this.detuctables.findIndex((d) => this.selectedDeductible.id == d.id)
        : 0;
      if (this.comprehensiveOffers && this.comprehensiveOffers.length) {
        this.mainComprehensiveOffers = [
          this.comprehensiveOffers[comprehensiveItemIndex],
        ];
      }
    },
    offerBasicCovers(offer) {
      if (!offer || !offer.covers) return;
      return offer.covers.filter((item) => !item.additional);
    },
    offerAdditionalCovers(offer) {
      return offer.covers.filter((item) => item.additional);
    },
    getAttachment(path) {
      try {
        return "/storage/" + path;
      } catch (e) {
        return "";
      }
    },
    toggleAdditionalCover(offer, cover) {
      this.setOfferAdditionalCoversDefaultValue(offer);
      if (offer.selectedAdditionalCovers.includes(cover.id)) {
        this.deselectOfferCover(offer,cover);
      } else {
        this.selectOfferCover(offer,cover);
      }
      this.handleAcigDimmedAdditionalCovers(offer);

    },
    deselectOfferCover(offer,cover){
      const taxAmount = parseFloat(cover.tax_amount) ? parseFloat(cover.tax_amount) : 0;
      offer.selectedAdditionalCoversTaxes =
      offer.selectedAdditionalCoversTaxes - taxAmount;
      offer.selectedAdditionalCoversAmount =
      offer.selectedAdditionalCoversAmount - parseFloat(cover.amount);
      offer.selectedAdditionalCovers.splice(
        offer.selectedAdditionalCovers.indexOf(cover.id),
        1
      );
    },
    selectOfferCover(offer,cover) {
      const taxAmount = parseFloat(cover.tax_amount) ? parseFloat(cover.tax_amount) : 0;
      offer.selectedAdditionalCoversTaxes =
      offer.selectedAdditionalCoversTaxes + taxAmount;
      offer.selectedAdditionalCoversAmount =
      offer.selectedAdditionalCoversAmount + parseFloat(cover.amount);
      offer.selectedAdditionalCovers.push(
        cover.id
      );
    },
    handleAcigDimmedAdditionalCovers(offer){
      if(this.company.code == this.companiesCodes["acig"]){
        let partialCover = this.offerAdditionalCovers(offer).find(c => c.acig_cover_id == 1)  ;
        partialCover.isDimmed = offer.selectedAdditionalCovers.includes(this.offerAdditionalCovers(offer)?.find(c => c.acig_cover_id == 2)?.id) ;
      }
    },
    setOfferAdditionalCoversDefaultValue(offer){
        if(!offer.selectedAdditionalCovers){
            offer.selectedAdditionalCovers = [];
        }

        if(!offer.selectedAdditionalCoversTaxes){
            offer.selectedAdditionalCoversTaxes = 0;
        }

        if(!offer.selectedAdditionalCoversAmount){
            offer.selectedAdditionalCoversAmount = 0;
        }
    },
  },
  mounted() {
    if (this.comprehensive && this.offer.deductible_offers) {
      this.activeOffer = this.offer;
      if (this.offer.deductible_offers) {
        this.selectedDeductible = this.deductibles[0];
        this.setComprehensiveOffer();
      }
    }
  }
});
</script>
