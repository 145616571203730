<template>
    <app-layout>
        <Head>
            <title>Payment failed | Qariin</title>
        </Head>
        <section class="bg-secondary-300 py-24">
            <div
                class="flex flex-col items-center justify-center py-12 bg-white max-w-4xl mx-5 md:mx-auto rounded-md"
            >
                <img
                    src="~@qariin-fe/src/images/svg/failed.svg"
                    alt="Success"
                    class="w-60"
                />
                <h2 class="text-danger my-5">حدثت مشكلة اثناء الدفع</h2>
                <p
                    v-if="message"
                    class="max-w-lg text-center mx-auto mb-5 text-danger"
                >
                    {{ message }}
                </p>
                <a :href="url" class="bg-primary px-20 rounded-md text-white"
                    >العودة لعملية الشراء</a
                >
            </div>
        </section>
    </app-layout>
</template>

<script>
import { Head } from '@inertiajs/inertia-vue3'
import AppLayout from "@/Layouts/AppLayout.vue";
export default {
    components: {
        AppLayout,
        Head,
    },
    props: {
        url: {
            type: String,
            required: true,
        },
        message: {
            type: String,
        },
    },
};
</script>
