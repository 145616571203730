<template>
    <app-layout>
        <Head>
            <title>Payment success | Qariin</title>
        </Head>
        <section class="bg-secondary-300 py-24">
            <div
                class="flex flex-col items-center justify-center py-12 bg-white max-w-4xl mx-5 md:mx-auto rounded-md"
            >
                <img
                    src="~@qariin-fe/src/images/svg/success.svg"
                    alt="Success"
                    class="w-60"
                />
                <h2 class="text-success my-5">تمت عملية الدفع بنجاح</h2>
                <p class="mb-6 max-w-xl text-center text-sm">
                    وثيقتك جاهزة ، اطبعها الآن
                </p>
                <div class="flex w-full justify-center items-center mb-4">
                    <a
                        :href="'/order/'+order.id+'/print/invoice'"
                        class="bg-primary px-20 mr-1 rtl:ml-1 rounded-md max-w-[340px] text-white flex-grow flex-1 "
                        >طباعة الفاتورة</a
                    >
                    <a
                        :href="order?.policy?.policy_file_url || ''"
                        :class="order?.policy?.policy_file_url ? 'bg-primary px-20 mr-1 rtl:ml-1 rounded-md max-w-[340px] text-white flex-grow flex-1':'bg-gray-200 px-20 mr-1 rtl:ml-1 rounded-md max-w-[340px] text-white flex-grow flex-1 inactiveLink' "
                        >طباعة الوثيقة</a
                    >
                </div>
                <div class="flex w-full justify-center items-center">
                    <a
                        href="/user/profile"
                        class="bg-primary px-20 ml-1 rtl:mr-1 rounded-md max-w-[688px] text-white flex-grow flex-1"
                        >العودة إلى الحساب الشخصي</a
                    >
                </div>
            </div>
        </section>
    </app-layout>
</template>

<script>
import { Head } from '@inertiajs/inertia-vue3'
import AppLayout from "@/Layouts/AppLayout.vue";
export default {
    components: {
        AppLayout,
        Head,
    },
    data() {
        return {
            order: this.$page.props.order,
        };
    },
};
</script>
<style scoped>
.inactiveLink {
   pointer-events: none;
   cursor: default;
}
</style>
