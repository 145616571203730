<template>
    <app-layout title="Home">
        <div>
            <form
                :action="route('payment.checkout.submit')"
                class="paymentWidgets"
                data-brands="MADA VISA MASTER AMEX"
            ></form>
        </div>
    </app-layout>
</template>

<script>
import { defineComponent } from "vue";
// import AppLayout from "@/Layouts/AnnouncementLayout.vue";
// import Welcome from "@/Jetstream/Welcome.vue";

export default defineComponent({
    components: {
        // AppLayout,
        // Welcome
    },
    data() {
        return {
            checkoutId: this.$page.props.checkoutId,
        };
    },
    mounted() {
        const plugin = document.createElement("script");
        plugin.setAttribute(
            "src",
            "https://test.oppwa.com/v1/paymentWidgets.js?checkoutId=" +
                this.checkoutId
        );
        plugin.async = true;
        document.head.appendChild(plugin);
        const page_3d = document.createElement("script");
        page_3d.innerHTML = `var wpwlOptions = {
                                paymentTarget:'_top',
                            }`;
        page_3d.async = true;
        document.head.appendChild(page_3d);
    },
});
</script>
