<template>
    <app-layout>
        <Head>
            <title>Contact us | Qariin</title>
        </Head>
        <ErrorsAlert
            v-if="errorMessages && errorMessages.length"
            :errors="errorMessages"
        />
        <section class="bg-secondary-300">
            <div class="container grid grid-cols-1 lg:grid-cols-2 gap-x-6">
                <div class="contactus__info">
                    <h1 class="text-xl lg:text-3xl mb-6 text-secondary-900">تواصل معنا</h1>
                    <img
                        src="~@qariin-fe/src/images/new-map.png"
                        alt="Saudi Map"
                        class="w-full max-w-md rounded-lg"
                    />
                    <div class="contactus__socials">
                        <span class="text-tertiary">البريد الالكتروني</span>
                        <a
                            href="mailto:support@qariin.com"
                            class="col-span-3 text-sm text-secondary"
                            >support@qariin.com</a
                        >
                        <span class="text-tertiary">رقم الهاتف</span>
                        <a
                            href="tel:8001111051"
                            class="col-span-3 text-sm text-secondary"
                            >8001111051</a
                        >
                        <span class="text-tertiary">العنوان</span>
                        <div class="col-span-3 text-sm text-secondary">
                            <p class="pb-1">
                                المقر الرئيسي: الرياض - العليا - شارع العليا العام
                            </p>
                            <!-- <p>
                                الإدارة الإقليمية: الخبر - حي العقربية شارع
                                الأمير حمود
                            </p> -->
                        </div>
                        <span class="text-tertiary">وسائل التواصل</span>
                        <div class="flex col-span-3">
                            <a
                                href="https://www.instagram.com/qariinksa"
                                class="min-h-full"
                            >
                                <img
                                    src="~@qariin-fe/src/images/svg/instagram.svg"
                                    alt="Instagram"
                                    class="max-w-full max-h-full w-4 h-4"
                                />
                            </a>
                            <a
                                href="https://twitter.com/qariinksa"
                                class="min-h-full"
                            >
                                <img
                                    src="~@qariin-fe/src/images/svg/twitter.svg"
                                    alt="Twitter"
                                    class="max-w-full max-h-full w-4 h-4"
                                />
                            </a>
                            <a href="https://www.facebook.com/QariinKSA" class="min-h-full">
                                <img
                                    src="~@qariin-fe/src/images/svg/facebook.svg"
                                    alt="Facebook"
                                    class="max-w-full max-h-full w-4 h-4"
                                />
                            </a>
                            <a target="__blank" href="https://www.linkedin.com/company/%D9%82%D8%A7%D8%B1%D9%86-%D8%A7%D9%84%D9%88%D8%B3%D9%8A%D8%B7-%D9%84%D9%88%D8%B3%D8%A7%D8%B7%D8%A9-%D8%A7%D9%84%D8%AA%D8%A3%D9%85%D9%8A%D9%86-%D8%A7%D9%84%D8%A7%D9%84%D9%83%D8%AA%D8%B1%D9%88%D9%86%D9%8A/" class="min-h-full">
                                <img
                                    src="~@qariin-fe/src/images/svg/linkedin.svg"
                                    alt="linkedin"
                                    class="max-w-full max-h-full w-4 h-4"
                                />
                            </a>
                        </div>
                        <span class="text-tertiary">الشكاوي</span>
                        <a class="text-tertiary" :href="route('home')" data-modal="got-to-faqs"
                                @click.prevent="
                                    $refs.compalintsModal.toggleModal()
                                ">
                                <img :src="require('@qariin-fe/src/images/icons/info.svg').default" class="w-6 me-2" alt="Info icon" />
                                <span class="whitespace-nowrap text-primary">عرض آلية الشكوى</span>
                        </a>
                    </div>

                </div>
                <form action="" class="contactus__form" ref="contactUs">
                    <h2 class="text-lg lg:text-3xl self-start mb-5 text-secondary-900">
                        اكتب رسالتك
                    </h2>
                    <fieldset
                        class="selectbox mb-2"
                        :style="`--icon: url('${
                            require('@qariin-fe/src/images/svg/arrow-down-secondary.svg')
                                .default
                        }');`"
                    >
                        <select
                            name="reason"
                            id="reason"
                            v-model="input.reason"
                            required
                        >
                            <option value="null" selected disabled>
                                اسباب التواصل
                            </option>
                            <option value="complaint">شكوى</option>
                            <option value="inquiry">استفسار</option>
                            <option value="request">طلب</option>
                            <option value="observations">اراء و ملاحظات</option>
                        </select>
                    </fieldset>
                    <fieldset class="input input-w-auto bg-white">
                        <label for="name"> اسم العميل </label>
                        <input
                            v-model="input.customer_name"
                            type="text"
                            id="name"
                            required
                            name="name"
                            placeholder="اكتب اسم العميل"
                        />
                    </fieldset>
                    <fieldset
                        class="input input-w-auto bg-white"
                        :style="`--icon: url('${
                            require('@qariin-fe/src/images/icons/email.png')
                                .default
                        }');`"
                    >
                        <label for="email"> البريد الالكتروني </label>
                        <input
                            v-model="input.customer_email"
                            type="email"
                            id="email"
                            required
                            name="email"
                            placeholder="اكتب البريد الالكتروني"
                        />
                    </fieldset>
                    <fieldset
                        class="input input-w-auto bg-white"
                        :style="`--icon: url('${
                            require('@qariin-fe/src/images/icons/mobile.png')
                                .default
                        }');`"
                    >
                        <label for="phone"> رقم الجوال </label>
                        <input
                            v-model="input.customer_phone"
                            type="tel"
                            id="phone"
                            required
                            name="phone"
                            placeholder="اكتب رقم الجوال"
                        />
                    </fieldset>
                    <fieldset class="input input-w-auto input-no-icon bg-white">
                        <textarea
                            v-model="input.message"
                            name="message"
                            id="message"
                            class="w-full h-32"
                            placeholder="رسالتك هنا"
                            required
                        ></textarea>
                    </fieldset>
                    <label
                        for="contact-us-file"
                        class="flex items-center justify-center w-64 h-28 my-6 ms-3 px-xs relative cursor-pointer border border-dashed border-2 border-primary-400 rounded-lg"
                    >
                        <div
                            class="absolute -top-3 -right-3 w-10 h-10 rounded-full bg-primary p-2"
                        >
                            <img
                                src="@qariin-fe/src/images/icons/upload.svg"
                                alt="upload"
                            />
                        </div>
                        <div v-if="input.file" class="flex flex-col items-center justify-center">
                            <figure class="w-10 h-10 rounded-full bg-primary p-2 mb-3">
                                <img
                                    src="@qariin-fe/src/images/icons/done.svg"
                                    alt="Done"
                                />
                            </figure>
                            <span class="text-sm text-secondary">
                                {{ file_name }}
                            </span>
                        </div>
                        <img
                            v-else
                            src="@qariin-fe/src/images/icons/upload-primary.svg"
                            alt="Upload file"
                            class="w-28"
                        />
                        <input
                            class="w-0 h-0"
                            name="file"
                            id="contact-us-file"
                            type="file"
                            @change="setFile($event)"
                        />
                    </label>
                    <button
                        v-if="!loading"
                        @click="sendData($event)"
                        class="w-full max-w-xs bg-primary text-white rounded-md"
                        :class="{ disabled: !isValid }"
                    >
                        ارسال
                    </button>
                    <VueLottiePlayer
                        v-else
                        style="width: 150px; height: 70px"
                        name="Qariin loader"
                        loop
                        path="https://assets9.lottiefiles.com/private_files/lf30_1xgata3z.json"
                    />
                    <span v-if="success && !failed" class="text-sm text-success">
                        تم إرسال الرسالة بنجاح
                    </span>
                    <span v-if="failed && !success" class="text-sm text-danger"
                        >حدث خطأ, برجاء مراجعة البيانات</span
                    >
                </form>
            </div>
        </section>
    </app-layout>
    <Modal ref="compalintsModal" title="عرض آلية الشكوى">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
            <div class="border-e">
                <p class="text-tertiary">
                    يمكنكم تقديم شكواكم من خلال القنوات التالية :
                </p>
                <div class="w-full flex items-center justify-between border-b">
                    <span class="text-black py-3">1/ الرقم المجاني</span>
                    <a href="tel:+9668001111051" class="pe-4">(8001111051)</a>
                </div>
                <div class="w-full flex items-center justify-between border-b">
                    <span class="text-black py-3">2/ تطبيق قارن</span>
                    <p class="flex items-center justify-center pe-4">
                        <a href="#" target="__blank">
                            أيفون
                        </a>
                        <span class="px-2 text-black">|</span>
                        <a href="#" target="__blank">
                            أندرويد
                        </a>
                    </p>
                </div>
                <div class="w-full flex items-center justify-between border-b">
                    <span class="text-black py-3">3/ البريد الإلكتروني</span>
                    <a href="mailto:support@qariin.com" class="pe-4">( support@qariin.com )</a>
                </div>
                <div class="w-full flex items-center justify-between">
                    <span class="text-black py-3 border-b">4/ الموقع الالكتروني</span>
                    <a href="qariin.com" target="__blank" class="pe-4">qariin.com</a>
                </div>
                <div class="w-full flex items-center justify-between border-b">
                    <span class="text-black py-3">
                        5/ مواقع التواصل الاجتماعي
                    </span>
                    <div>
                        <a target="__blank" href="https://mobile.twitter.com/qariinksa">
                            <div class="p-2 w-8 h-8">
                                <img src="~@qariin-fe/src/images/svg/twitter.svg" alt="twitter"
                                    class="max-w-full max-h-full w-8 h-8" />
                            </div>
                        </a>
                        <a target="__blank" href="https://www.facebook.com/QariinKSA">
                            <div class="p-2 w-8 h-8">
                                <img src="~@qariin-fe/src/images/svg/facebook.svg" alt="Facebook"
                                    class="max-w-full max-h-full w-8 h-8" />
                            </div>
                        </a>
                        <a target="__blank" href="https://instagram.com/qariinksa">
                            <div class="p-2 w-8 h-8">
                                <img src="~@qariin-fe/src/images/svg/instagram.svg" alt="instagram"
                                    class="max-w-full max-h-full w-8 h-8" />
                            </div>
                        </a>
                        <a target="__blank" href="https://www.linkedin.com/company/%D9%82%D8%A7%D8%B1%D9%86-%D8%A7%D9%84%D9%88%D8%B3%D9%8A%D8%B7-%D9%84%D9%88%D8%B3%D8%A7%D8%B7%D8%A9-%D8%A7%D9%84%D8%AA%D8%A3%D9%85%D9%8A%D9%86-%D8%A7%D9%84%D8%A7%D9%84%D9%83%D8%AA%D8%B1%D9%88%D9%86%D9%8A/">
                            <div class="p-2 w-8 h-8">
                                <img src="~@qariin-fe/src/images/svg/linkedin.svg" alt="linkedin"
                                    class="max-w-full max-h-full w-8 h-8" />
                            </div>
                        </a>
                    </div>
                </div>
                <div class="w-full flex items-center justify-between border-b">
                    <span class="text-black py-3">
                        6/ زيارة إدارة الشكاوى في الشركة
                    </span>
                </div>
            </div>
            <div class="text-black text-sm">
                <p class="bg-warning-light text-text-warning p-5">
                    في حال تم تقديم شكوى فإن إدارة الشكاوى تتجاوب معها فورأ وتحيلها إلى الإدارة المعنية بالشركة لدراستها وحلها ثم إشعاركم بما تم حيالها وذلك وفقا للاجراءات التالية
                </p>
                <p class="py-3">1/ اشعاركم باستلام الشكوى خلال 8 ساعات عمل من إرساله، مع تسليمكم رقم المرجع الذي تستخدمه للمتابعة</p>
                <p class="py-3">2/ تقوم إدارة الشكاوى بإرسال ملاحظاتك مباشرة إلى الإدارة المعنية عبر نظام آلي متطور للتحقيق فيها واتخاذ الإجراءات المناسبة خلال مدة لاتتجاوز 10 أيام عمل من تاريخ استلام الطلب .</p>
                <p class="py-3">3/ إذا لم تقم الإدارة المعنية بالرد ، يرفع النظام ملاحظاتك أو شكواك آلياً إلى المستوى الإداري الأعلى حتى تصل إلى أعلى مستوى إداري بالشركة للرد عليها .</p>
                <p class="py-3">4/ في حال وجود أي نواقص تقوم إدارة الشكاوى بالتواصل معكم وطلبها منكم .</p>
                <p class="py-3">5/ في حال لم يتم الرد على شكواكم بعد مرور 5 أيام عمل أو عدم الرضا على حل الشكوى يمكنكم التوجه لتقديم شكوى في نظام <a href="https://www.samacares.sa/">ساما تهتم</a></p>
            </div>
        </div>
    </Modal>
</template>

<script>
import { Head } from '@inertiajs/inertia-vue3'
import AppLayout from "@/Layouts/AppLayout.vue";
import VueLottiePlayer from "vue-lottie-player";
import ErrorsAlert from "@/components/ErrorsAlert.vue";
import Modal from "@/components/Modal.vue";

export default {
    components: {
        AppLayout,
        Head,
        VueLottiePlayer,
        ErrorsAlert,
        Modal
    },
    data() {
        return {
            email: "support@qariin.sa",
            input: {
                reason: null,
                customer_name: null,
                customer_email: null,
                customer_phone: null,
                message: null,
                file: null,
            },
            file_name: null,
            loading: false,
            isValid: false,
            success: false,
            failed: false,
            errorMessages: [],
        };
    },
    watch: {
        input: {
            handler() {
                if (!this.$refs.contactUs) return;

                this.isValid =
                    this.$refs.contactUs.checkValidity() &&
                    !!this.input.message &&
                    !!this.input.reason &&
                    this.input.reason != "null";
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        setFile(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;

            this.input.file = files[0];
            this.file_name = files[0].name;
        },
        async sendData(e) {
            e.preventDefault();

            if (!this.isValid) return;

            let data = new FormData();
            data.append('reason', this.input.reason);
            data.append('customer_name', this.input.customer_name);
            data.append('customer_email', this.input.customer_email);
            data.append('customer_phone', this.input.customer_phone);
            data.append('message', this.input.message);
            data.append('file', this.input.file);

            this.errorMessages = [];

            this.loading = true;
            this.failed = false;
            this.success = false;
            var payload = data;

            await axios
                .post(route("contact-us.store"), payload)
                .then(() => {
                    Object.keys(this.input).forEach((key) => {
                        this.input[key] = null;
                    });
                    this.success = true;
                })
                .catch((error) => {
                    var { response } = error;
                    var { data } = response;

                    if (data.errors){
                        Object.keys(data.errors).forEach((key) => {
                            this.errorMessages.push(data.errors[key][0]);
                        });
                    }else{
                        this.errorMessages.push(data.message);
                    } 

                    this.isLoading = false;
                    this.failed = true;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>
