<template>
    <div>
        <div class="p-6 sm:px-20 bg-white border-b border-gray-200">
            Hello :D
        </div>
    </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
    components: {},
});
</script>
