<template>
    <div
        v-if="show"
        class="fixed top-3 ms-4 max-w-xs rounded-md p-xs text-black bg-white z-40 shadow-lg"
    >
        <div class="container flex items-start justify-start">
            <img :src="require('@qariin-fe/src/images/icons/cancel.svg').default" class="w-8 me-3" alt="Error">
            <ul>
                <li v-for="(error, index) in errors" :key="index">
                    <span class="text-xs">{{ error }}</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        errors: {
            type: Array,
            required: true,
            default: () => [],
        },
    },
    data() {
        return {
            show: true,
        };
    },
    mounted() {
        setTimeout(() => {
            this.show = false;
        }, 5000);
    },
};
</script>
