'use strict';

// Start Images and Styles
import '../styles/main.css';

// Import images directories
require.context('../images/svg', true);
require.context('../images/icons', true);
require.context('../images/recommended', true);
require.context('../images/guide-steps', true);
require.context('../images/why-qariin', true);
require.context('../images/static-pages', true);
require.context('../images/about-us', true);
require.context('../images/offers', true);

// Import images
import '../images/vision-2030.svg';
import '../images/google-play.svg';
import '../images/app-store.svg';
import '../images/central-bank.svg';

// End Images and Styles

// Start Homepage scripts
const body = document.querySelector('body');

const disabledItems = document.querySelectorAll('.disabled');

if (!!disabledItems.length) {
  disabledItems.forEach(item => {
    item.addEventListener('click', e => {
      e.preventDefault();
    });
  });
}

const mobileMenuToggler = document.querySelector('.header__buttons__toggler'),
  mobileMenu = document.querySelector('.header__mobile'),
  mobileMenuListItems = document.querySelectorAll('.header__mobile a'),
  mobileMenuClose = document.querySelector('.header__mobile__close'),
  mobileMenuBack = document.querySelector('.header__mobile__back'),
  mobileTopMenu = document.querySelector('.header__mobile__top'),
  mobileMenuChildren = document.querySelectorAll('.header__mobile__child');

mobileMenuToggler.addEventListener('click', e => {
  e.preventDefault();

  body.classList.add('remove-scroll');
  mobileMenuToggler.classList.add('opened');
  mobileMenu.classList.add('open');
  mobileMenu.classList.add('z-30');
});

mobileMenuClose.addEventListener('click', e => {
  e.preventDefault();

  body.classList.remove('remove-scroll');
  mobileMenuToggler.classList.remove('opened');
  mobileMenu.classList.remove('open');
  mobileMenu.classList.remove('z-30');
  mobileTopMenu.classList.remove('child-opened');
  mobileMenuChildren.forEach(child => {
    child.classList.remove('open');
    child.classList.remove('z-30');
  });
  mobileMenuBack.classList.add('invisible');
});

mobileMenuBack.addEventListener('click', e => {
  e.preventDefault();

  mobileTopMenu.classList.remove('child-opened');
  mobileMenuChildren.forEach(child => {
    child.classList.remove('open');
    child.classList.remove('z-30');
  });

  mobileMenuBack.classList.add('invisible');
});

mobileMenuListItems.forEach(item => {
  item.addEventListener('click', e => {
    if (item.classList.contains('header__mobile__hasChildren')) {
      e.preventDefault();
      const childName = e.target.closest('li').dataset.child;
      var targetChild;
      mobileMenuChildren.forEach(child => {
        if (childName == child.dataset.childName)
          targetChild = child;
      });

      mobileMenuBack.classList.remove('invisible');
      targetChild.classList.add('open');
      targetChild.classList.add('z-30');
      mobileTopMenu.classList.add('child-opened');
      return;
    }
    body.classList.remove('remove-scroll');
    mobileMenuToggler.classList.remove('opened');
    mobileMenu.classList.remove('open');
    mobileMenu.classList.remove('z-30');
  });
});

// core version + navigation, pagination modules:
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// configure Swiper to use modules
Swiper.use([Navigation, Pagination, Autoplay]);

// init Swiper for Homepage header section
setTimeout(() => {
  new Swiper('.homepage__landing__slider', {
    // Optional parameters
    direction: 'horizontal',
    slidesPerView: 1,

    autoplay: {
      delay: 4000,
    },
  });
}, 1000)

// init Swiper for recommended section
setTimeout(() => {
  new Swiper('.homepage__recommended__slider', {
    // Optional parameters
    direction: 'horizontal',
    loop: true,

    breakpoints: {
      1024: {
        slidesPerView: 5,
        spaceBetween: 20,
      },
      676: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      240: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
    },

    pagination: {
      el: '.swiper-pagination',
    },

    autoplay: {
      delay: 4000,
    },
  });
}, 1000)

const toggleInsuranceTypes = document.querySelectorAll('.toggle-insurance-types');

if (toggleInsuranceTypes.length) {
  toggleInsuranceTypes.forEach(toggler => {
    toggler.addEventListener('change', e => {
      const { target } = e,
        { id } = target,
        desiredInsurance = document.querySelector(`.${id}`),
        InsuranceTypes = document.querySelectorAll('.insurance-type');

      InsuranceTypes.forEach(type => {
        !type.classList.contains('hidden') && type.classList.add('hidden');
      });

      desiredInsurance.classList.remove('hidden');
    });
  });
}

const faqsQuestions = document.querySelectorAll('.faqs__question'),
  faqs = document.querySelectorAll('.faqs__faq');

if (!!faqsQuestions.length) {
  faqsQuestions.forEach(question => {
    question.addEventListener('click', e => {
      const faq = e.target.closest('.faqs__faq');
      const isActive = e.target.closest('.faqs__faq').classList.contains('active');

      faqs.forEach(question => {
        question.classList.contains('active') && faq != question  && question.classList.remove('active');
      });

      requestAnimationFrame(() => {
        faq.classList.toggle('active');
      });
    });
  });
}

const modalsContainer = document.querySelector('.modals'),
  pageModals = document.querySelectorAll('.modals__modal'),
  modalsClose = document.querySelectorAll('.modals__modal__close');

const _offersModalsTogglers = document.querySelectorAll('.offers__addons__button');

const _vehicleModalsTogglers = document.querySelectorAll('.vehicle__addons__button');

if (!!_offersModalsTogglers.length || !!_vehicleModalsTogglers.length) {

  const togglers = !!_offersModalsTogglers.length ? _offersModalsTogglers : _vehicleModalsTogglers;

  togglers.forEach(toggler => {
    toggler.addEventListener('click', () => {
      const targetModal = toggler.dataset.modal;

      pageModals.forEach(modal => {
        if (modal.id != targetModal) return;

        modal.id == targetModal;
        body.classList.add('remove-scroll');

        modalsContainer.classList.remove('hidden');

        modal.classList.remove('hidden');
      });
    });
  });
}

if (!!modalsClose.length) {
  modalsClose.forEach(close => {
    close.addEventListener('click', e => {
      const modal = e.target.closest('.modals__modal');

      body.classList.remove('remove-scroll');

      modal.classList.add('hidden');

      modalsContainer.classList.add('hidden');
    });
  });
}

if (!!modalsContainer) {
  modalsContainer.addEventListener('click', () => {
    modalsContainer.classList.add('hidden');

    pageModals.forEach(modal => {
      modal.classList.add('hidden');
    });
  });
}

if (!!pageModals.length) {
  pageModals.forEach(modal => {
    modal.addEventListener('click', e => {
      e.stopPropagation();
    });
  });
}
