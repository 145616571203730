<template>
    <div v-if="hasErrors">
        <ul class="mt-3 list-disc list-inside text-sm text-red-600">
            <li v-for="(error, key) in errors" :key="key">{{ error }}</li>
        </ul>
    </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
    computed: {
        errors() {
            return this.$page.props.errors;
        },

        hasErrors() {
            if (!this.errors) return false;
            return Object.keys(this.errors).length > 0;
        },
    },
});
</script>
