<template>
    <app-layout>
        <main class="staticPage staticPage--no-bg">
            <div class="container">
                <h1 class="text-xl lg:text-3xl text-black mt-16">
                    تأمين الحريق
                </h1>
                <section class="staticPage__info">
                    <div class="mt-8">
                        <p class="text-secondary max-w-md">
                            تغطي هذه الوثيقة المؤمن له عن الاضرار التي تلحق
                            بالممتلكات المؤمن عليها والموصوفة بجدول الوثيقة بسبب
                            الحريق او الصاعقة او الحريق الناتج عن الانفجار او
                            الاشتعال الذاتي حسب ما تقتضيه الوثيقة.
                        </p>
                        <a href="/" class="btn mt-8">قارن الان</a>
                    </div>
                    <img
                        class="staticPage__poster"
                        src="@qariin-fe/src/images/static-pages/fire.png"
                        srcset="
                            @qariin-fe/src/images/static-pages/fire.png    400w,
                            @qariin-fe/src/images/static-pages/fire@2x.png 800w
                        "
                        sizes="(max-width: 678px) 400px, 800px"
                        alt="main poster"
                    />
                </section>

                <section class="staticPage__features">
                    <div class="staticPage__feature">
                        <h2 class="staticPage__featureTitle">
                            :التغطيات الاضافية لتأمين الحريق
                        </h2>
                        <ul class="staticPage__list">
                            <li>الانفجار</li>
                            <li>انفجار مواسير المياة وطفح خزاناتها واجهزتها</li>
                            <li>اصطدام المركبات البرية</li>
                            <li>سقوط الطائرات او اية اشياء منها</li>
                            <li>
                                الاخطار الطبيعية (الزلازل – البراكين – العواصف –
                                الفيضان – البرد)
                            </li>
                        </ul>
                    </div>
                </section>
            </div>
        </main>
    </app-layout>
</template>

<script>
import AppLayout from "@/Layouts/AppLayout.vue";

export default {
    name: "FireInsurance",
    components: {
        AppLayout,
    },
};
</script>
