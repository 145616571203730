<template>
    <app-layout>
        <main class="staticPage">
            <div class="container">
                <h1 class="text-xl lg:text-3xl text-black mt-16">
                    تأمين اخطاء المهن الطبية وغير الطبية
                </h1>
                <section class="staticPage__info">
                    <div class="mt-8">
                        <p class="text-secondary max-w-md">
                            يقدّم الممارسون الصحيون العناية الطبية لمرضاهم
                            بالاحترافية، ولكن الخطأ البشري لا يمكن تفاديه
                            تماماً، لذلك اطلق البنك المركز السعودي تأمين أخطاء
                            ممارسة المهن الطبية وهي , تعويض المؤمن له عن كافة
                            المبالغ التي قد يترتب عليه قانونا ان يدفعها للغير
                            على سبيل التعويض (بما في ذلك تكاليف ومصاريف المدعي)
                            الناجمة عن اي اصابة جسدية او ذهنية او مرض او داء او
                            وفاة لاي مريض بسبب اهمال او خطأ او تقصير المؤمن له
                            في نطاق تأدية مهنته التي تحدث خلال مدة التأمين وداخل
                            المملكة العربية السعودية حسب ماتقضيه .الوثيقة
                        </p>
                        <a href="/" class="btn mt-8 inactiveLink">قارن الان</a>
                    </div>
                    <img
                        class="staticPage__poster"
                        src="@qariin-fe/src/images/static-pages/medical-malpractice-insurance.svg"
                        alt="main poster"
                    />
                </section>
                <section class="staticPage__features">
                    <div class="staticPage__feature">
                        <h2 class="staticPage__featureTitle">تشمل التغطية :</h2>
                        <ul class="staticPage__list">
                            <li>
                                أطباء الجراحة والنساء والولادة وأخصائيين
                                التخدير.
                            </li>
                            <li>الأطباء من الفئات الطبية الأخرى.</li>
                            <li>الصيادلة.</li>
                            <li>الممرضون والفنيون.</li>
                            <li>المسعفون والتقنيون.</li>
                        </ul>
                    </div>
                </section>
            </div>
        </main>
    </app-layout>
</template>

<script>
import AppLayout from "@/Layouts/AppLayout.vue";

export default {
    name: "MedicalMalpracticeInsurance",
    components: {
        AppLayout,
    },
};
</script>
<style scoped>
.inactiveLink {
   pointer-events: none;
   cursor: default;
   opacity: .2;
   background-color: rgba(0, 49, 94);
}
</style>
