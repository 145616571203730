<template>
    <app-layout>
        <main class="staticPage staticPage--no-bg">
            <div class="container">
                <h1 class="text-xl lg:text-3xl text-black mt-16">تأمين طبي</h1>
                <section class="staticPage__info">
                    <div class="mt-8">
                        <p class="text-secondary max-w-md">
                            التأمين الطبي هو أحد أنواع التأمين ضد مخاطر الظروف
                            الصحية لدى الفرد أو العائلة، ويشمل تكاليف فحصه
                            وتشخيصه وعلاجه، ودعمه النفسي والجسدي. وهو أحد الطرق
                            لإيصال الرعاية الصحية للمجتمع. حدود التغطية للتأمين
                            الصحي 1 مليون ريال سعودي سنويا حسب ما تقتضيه
                            الوثيقة
                        </p>
                        <a href="/" class="btn mt-8  inactiveLink">قارن الان</a>
                    </div>
                    <img
                        class="staticPage__poster"
                        src="@qariin-fe/src/images/static-pages/medical-insurance.svg"
                        alt="main poster"
                    />
                </section>
            </div>
        </main>
    </app-layout>
</template>

<script>
import AppLayout from "@/Layouts/AppLayout.vue";

export default {
    name: "MedicalInsurance",
    components: {
        AppLayout,
    },
};
</script>
<style scoped>
.inactiveLink {
   pointer-events: none;
   cursor: default;
   opacity: .2;
   background-color: rgba(0, 49, 94);
}
</style>
