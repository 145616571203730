<template>
    <div
        :class="{
            'min-h-screen flex flex-col sm:justify-center items-center pt-6 sm:pt-0 bg-gray-100':
                !simple,
        }"
    >
        <div>
            <slot name="logo" />
        </div>

        <div
            :class="{
                'w-full sm:max-w-md mt-6 px-6 py-4 bg-white shadow-md overflow-hidden sm:rounded-lg':
                    !simple,
            }"
        >
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        simple: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
