<template>
    <section class="orders mb-12">
        <div class="orders__header">
            <ul class="flex items-center border-b mb-8">
                <li>
                    <a
                        class="pe-3 min-h-full border-e pb-2 cursor-pointer border-b-4"
                        :class="{
                            'border-b-primary': activeOrders === 'pending',
                        }"
                        @click.prevent="activeOrders = 'pending'"
                        >طلبات معلقة</a
                    >
                </li>
                <li>
                    <a
                        class="me-3 min-h-full ps-3 pb-2 cursor-pointer border-b-4"
                        :class="{
                            'border-b-primary': activeOrders === 'completed',
                        }"
                        @click.prevent="activeOrders = 'completed'"
                        >طلبات مكتملة</a
                    >
                </li>
            </ul>
        </div>
        <div class="orders__list">
            <div v-if="activeOrders === 'pending'" class="orders__pending">
                <template v-if="notCompletedOrders.length">
                    <div
                        v-for="order in notCompletedOrders"
                        :key="order.id"
                        class="orders__order bg-white my-5 pb-xs px-xs rounded-md shadow-md max-w-2xl"
                    >
                        <div
                            class="orders__order__header flex flex-col md:flex-row pt-2 items-center justify-between mb-3 border-b"
                        >
                            <h3 class="text-secondary text-sm">قيد التسجيل</h3>
                            <div class="orders__order__actions">
                                <a
                                    :href="route('orders.show', order.id)"
                                    class="me-3 text-sm"
                                >
                                    <img
                                        src="@qariin-fe/src/images/icons/payment.svg"
                                        alt="Delete Order"
                                        class="w-4 me-2"
                                    />
                                    <span>استكمال عملية الدفع</span>
                                </a>
                                <button
                                    class="me-3 text-sm"
                                    @click="confirmingOrderDeletion = order.id"
                                >
                                    <img
                                        src="@qariin-fe/src/images/icons/delete.svg"
                                        alt="Delete Order"
                                        class="w-4 me-2"
                                    />
                                    <span>حذف الفاتورة</span>
                                </button>
                            </div>
                        </div>
                        <div class="grid grid-cols-1 md:grid-cols-3 gap-3">
                            <div
                                v-if="order.vehicle && order.vehicle.id"
                                class="orders__order__vehicle"
                            >
                                <h4 class="text-sm text-tertiary mb-2">
                                    وصف المركبة
                                </h4>
                                <p class="text-xs text-secondary">
                                    {{ order.vehicle.maker.name }}
                                    {{ order.vehicle.model_year }}
                                    {{ order.vehicle.major_color }}
                                </p>
                            </div>
                            <div class="orders__order__receipt">
                                <h4 class="text-sm text-tertiary mb-2">
                                    تفاصيل الايصال
                                </h4>
                                <p class="text-xs text-secondary">
                                    شراء من شركة {{ order.company.name_ar }}
                                </p>
                            </div>
                            <div class="orders__order__total">
                                <h4 class="text-sm text-tertiary mb-2">
                                    المجموع المستحق
                                </h4>
                                <p class="text-xs text-secondary">
                                    {{ order.total_price_after_promotional }} ر.س
                                </p>
                            </div>
                        </div>
                    </div>
                </template>
                <p v-else class="text-secondary-900">لا توجد معلومات لعرضها الان</p>
            </div>
            <div v-else class="orders__completed">
                <template v-if="completedOrders.length">
                    <div
                        v-for="order in completedOrders"
                        :key="order.id"
                        class="orders__order bg-white my-5 pb-xs px-xs rounded-md shadow-md max-w-2xl my-3"
                    >
                        <div
                            class="orders__order__header flex flex-col md:flex-row pt-2 items-center justify-between mb-3 border-b"
                        >
                            <h3 class="text-secondary text-sm">
                                #{{ order.merchant_transaction_id }}
                            </h3>
                            <div class="orders__order__actions">
                                <button
                                    class="me-3 text-sm"
                                    @click="orderDetails = order"
                                >
                                    <img
                                        src="@qariin-fe/src/images/icons/details.svg"
                                        alt="Delete Order"
                                        class="w-4 me-2"
                                    />
                                    <span>تفاصيل الطلب</span>
                                </button>
                                <a
                                    :href="route(`orders.invoice.show`, order.id)"
                                    class="me-3 text-sm"
                                >
                                    <img
                                        src="@qariin-fe/src/images/icons/print.svg"
                                        alt="Delete Order"
                                        class="w-8"
                                    />
                                    <span>طباعة الايصال</span>
                                </a>
                            </div>
                        </div>
                        <div class="grid grid-cols-1 md:grid-cols-3 gap-3">
                            <div
                                v-if="order.vehicle && order.vehicle.id"
                                class="orders__order__vehicle"
                            >
                                <h4 class="text-sm text-tertiary mb-2">
                                    وصف المركبة
                                </h4>
                                <p class="text-xs text-secondary">
                                    {{ order.vehicle.maker.name }}
                                    {{ order.vehicle.model_year }}
                                    {{ order.vehicle.major_color }}
                                </p>
                            </div>
                            <div class="orders__order__receipt">
                                <h4 class="text-sm text-tertiary mb-2">
                                    تفاصيل الايصال
                                </h4>
                                <p class="text-xs text-secondary">
                                    شراء من شركة {{ order.company.name_ar }}
                                </p>
                            </div>
                            <div class="orders__order__total">
                                <h4 class="text-sm text-tertiary mb-2">
                                    المجموع المستحق
                                </h4>
                                <p class="text-xs text-secondary">
                                    {{ order.total_price_after_promotional }} ر.س
                                </p>
                            </div>
                        </div>
                    </div>
                </template>
                <p v-else class="text-secondary-900">لا توجد معلومات لعرضها الان</p>
            </div>
        </div>
        <jet-dialog-modal :show="confirmingOrderDeletion" @close="closeModal">
            <template #title> حذف الفاتورة </template>

            <template #content>
                هل انت متأكد من حذف الفاتورة؟ في حالة حذف الفاتورة لا يمكن
                الرجوع عن هذه الخطوة.
            </template>

            <template #footer>
                <div class="flex items-center justify-">
                    <jet-secondary-button @click="closeModal">
                        الغاء
                    </jet-secondary-button>
                    <form
                        :action="
                            route('orders.destroy', confirmingOrderDeletion)
                        "
                        method="post"
                    >
                        <input type="hidden" name="_method" value="delete" />
                        <input type="hidden" name="_token" :value="csrf" />
                        <jet-danger-button type="submit" class="ml-2 mr-3">
                            حذف
                        </jet-danger-button>
                    </form>
                </div>
            </template>
        </jet-dialog-modal>
        <jet-dialog-modal :show="!!orderDetails" @close="closeDetailsModal">
            <template #title> تفاصيل الطلب </template>

            <template #content>
                <div>
                    <div class="payment__vehicle">
                        <article>
                            <div>
                                <span class="test-secondary text-xs">
                                    نوع التامين
                                </span>
                                <p class="text-black text-sm">
                                    {{
                                        orderDetails.product_type
                                            .arabic_description
                                    }}
                                </p>
                            </div>
                        </article>
                        <template
                            v-if="
                                orderDetails.vehicle && orderDetails.vehicle.id
                            "
                        >
                            <article>
                                <div>
                                    <span class="test-secondary text-xs">
                                        وصف المركبة
                                    </span>
                                    <p class="text-black text-sm">
                                        {{ orderDetails.vehicle.maker.name }}
                                        {{ orderDetails.vehicle.model_year }}
                                        {{ orderDetails.vehicle.major_color }}
                                    </p>
                                </div>
                            </article>
                            <article v-if="orderDetails.vehicle && orderDetails.vehicle.plate_number">
                                <figure
                                    class="offers__plate"
                                    :data-plate-number="`${orderDetails.vehicle.plate_number}`"
                                >
                                    <img
                                        src="~@qariin-fe/src/images/offers/car-plate.jpg"
                                        class="w-full h-full object-contain"
                                        alt="Car Plate"
                                    />
                                    <template
                                        v-if="
                                            orderDetails.vehicle
                                                .plate_letter1 &&
                                            orderDetails.vehicle
                                                .plate_letter2 &&
                                            orderDetails.vehicle.plate_letter3
                                        "
                                    >
                                        <span v-if="orderDetails.vehicle.plate_letter1.arabic_letter" class="offers__plate__arabic">{{
                                            `${orderDetails.vehicle.plate_letter3?.arabic_letter} ${orderDetails.vehicle.plate_letter2?.arabic_letter} ${orderDetails.vehicle.plate_letter1?.arabic_letter}`
                                        }}</span>
                                        <span class="offers__plate__english">{{
                                            `${orderDetails.vehicle.plate_letter1?.english_letter} ${orderDetails.vehicle.plate_letter2?.english_letter} ${orderDetails.vehicle.plate_letter3?.english_letter}`
                                        }}</span>
                                    </template>
                                </figure>
                            </article>
                            <article>
                                <div>
                                    <span class="test-secondary text-xs">
                                        الرقم التسلسلي/الجمركي
                                    </span>
                                    <p class="text-black text-sm">
                                        {{ orderDetails.vehicle.number || orderDetails.vehicle.custom_number }}
                                    </p>
                                </div>
                            </article>
                        </template>
                        <article>
                            <div>
                                <span class="test-secondary text-xs">
                                    تاريخ بدء الوثيقة
                                </span>
                                <p class="text-black text-sm">
                                    {{
                                        formatDate(
                                            orderDetails.insurance_start_year
                                        )
                                    }}
                                </p>
                            </div>
                        </article>
                        <article>
                            <div>
                                <span class="test-secondary text-xs">
                                    تاريخ إنتهاء الوثيقة
                                </span>
                                <p class="text-black text-sm">
                                    {{
                                        formatDate(
                                            orderDetails.insurance_expired_year
                                        )
                                    }}
                                </p>
                            </div>
                        </article>
                    </div>
                    <h3>بيانات طالب التأمين</h3>
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                        <div>
                            <h4 class="text-secondary text-sm">
                                اسم طالب التأمين
                            </h4>
                            <p>
                                {{ orderDetails.owner.arabic_full_name }}
                            </p>
                        </div>
                        <div>
                            <h4 class="text-secondary text-sm">
                                البريد الالكتروني لطالب التامين
                            </h4>
                            <p>
                                {{ orderDetails.owner.email }}
                            </p>
                        </div>
                        <div>
                            <h4 class="text-secondary text-sm">
                                العنوان الوطني
                            </h4>
                            <p>
                                {{ orderDetails.owner.city }}
                            </p>
                        </div>
                        <div>
                            <h4 class="text-secondary text-sm">
                                رقم الجوال لطالب التأمين
                            </h4>
                            <p>
                                {{ orderDetails.owner.phone }}
                            </p>
                        </div>
                    </div>
                    <div
                        class="flex items-center justify-between mt-12 mb-3 pb-2 border-b"
                    >
                        <h3 class="text-secondary">تفاصيل السعر</h3>
                        <h3 class="text-secondary">القيمة</h3>
                    </div>
                    <div class="payment__price__details">
                        <div class="payment__price__item">
                            <p>
                                <span> القسط الأساسي </span>
                            </p>
                            <span class="whitespace-nowrap"
                                >{{
                                    Number(
                                        orderDetails.original_total_price
                                    ).toFixed(2)
                                }}
                                ﷼</span
                            >
                        </div>
                        <div class="payment__price__item">
                            <p>
                                <span> المنافع الإضافية </span>
                            </p>
                            <span class="whitespace-nowrap"
                                >{{
                                    orderDetails.covers_amount > 0 ? Number(orderDetails.covers_amount).toFixed(2) : 0
                                }}
                                ﷼</span
                            >
                        </div>
                        <div class="payment__price__item">
                            <p>
                                <span> الخصومات</span>
                            </p>
                            <span class="whitespace-nowrap"
                                >{{
                                    discount
                                }}
                                ﷼</span
                            >
                        </div>
                        <div class="payment__price__item">
                            <p>
                                <span> الإجمالي قبل الضريبة </span>
                            </p>
                            <span class="whitespace-nowrap"
                                >{{
                                    Number(orderDetails.sub_total).toFixed(2)
                                }}
                                ﷼</span
                            >
                        </div>
                        <div class="payment__price__item">
                            <p>
                                <span> الضريبة المضافة </span>
                            </p>
                            <span class="whitespace-nowrap"
                                >{{
                                    Number(orderDetails.vat).toFixed(2)
                                }}
                                ﷼</span
                            >
                        </div>
                        <div class="payment__price__item mt-4">
                            <p>
                                <b> المجموع المستحق </b>
                            </p>
                            <span class="whitespace-nowrap"
                                >{{
                                    Number(orderDetails.total_price).toFixed(2)
                                }}
                                ﷼</span
                            >
                        </div>
                    </div>
                </div>
            </template>

            <template #footer>
                <jet-secondary-button @click="closeDetailsModal">
                    إغلاق
                </jet-secondary-button>
            </template>
        </jet-dialog-modal>
    </section>
</template>

<script>
import JetDialogModal from "@/Jetstream/DialogModal.vue";
import JetDangerButton from "@/Jetstream/DangerButton.vue";
import JetSecondaryButton from "@/Jetstream/SecondaryButton.vue";
import * as moment from "moment";

export default {
    components: {
        JetDialogModal,
        JetDangerButton,
        JetSecondaryButton,
    },
    props: {
        completedOrders: {
            type: Array,
            required: true,
            default: () => [],
        },
        notCompletedOrders: {
            type: Array,
            required: true,
            default: () => [],
        },
        csrf: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            activeOrders: "pending",
            confirmingOrderDeletion: null,
            orderDetails: null,
        };
    },
    computed: {
        discount() {
            const discounts = this.orderDetails.product.prices.filter(price => price.is_discount);
            
            return Number(discounts.reduce((sum, discount) => {
                return Number(discount.amount) + Number(sum)
            }, 0)).toFixed(2);
        },
    },
    methods: {
        closeModal() {
            this.confirmingOrderDeletion = null;
        },
        closeDetailsModal() {
            this.orderDetails = null;
        },
        formatDate(date) {
            return moment(date).format("MM/DD/YYYY");
        },
    },
};
</script>
