<template>
    <app-layout>
        <main class="staticPage">
            <!-- <div class="container">
                <h1 class="text-xl lg:text-3xl text-black mt-16">
                    خدمة المساعدة على الطريق
                </h1>
                <section class="staticPage__info">
                    <div class="mt-8">
                        <p class="text-secondary max-w-md">
                            تقدم للعملاء تأمين المركبات لمساعدتهم في حل مشاكل
                            الطوارئ والأعطال التي تواجههم اثناء قيادتهم للسيارة
                            في المملكة العربية السعودية.
                        </p>
                        <a href="/" class="btn mt-8">قارن الان</a>
                    </div>
                    <img
                        class="staticPage__poster"
                        src="@qariin-fe/src/images/static-pages/road-services.svg"
                        alt="main poster"
                    />
                </section>
                <section class="staticPage__features">
                    <div class="staticPage__feature">
                        <h2 class="staticPage__featureTitle">
                            الخدمات المشمولة:
                        </h2>
                        <ul
                            class="staticPage__list staticPage__list--twoCols staticPage__list--twoCols-md staticPage__feature--fullWidth"
                        >
                            <div>
                                <li>سحب المركبة.</li>
                                <li>نقل المركبة.</li>
                                <li>توفير الوقود.</li>
                                <li>شحن البطارية.</li>
                            </div>
                            <div>
                                <li>خدمة فتح الاقفال</li>
                                <li>استبدال الاطارات</li>
                                <li>السيارة البديلة</li>
                                <li>ليموزين</li>
                            </div>
                        </ul>
                    </div>
                </section>
            </div> -->
        </main>
    </app-layout>
</template>

<script>
import AppLayout from "@/Layouts/AppLayout.vue";

export default {
    name: "RoadServices",
    components: {
        AppLayout,
    },
};
</script>
