<template>
    <app-layout title="OTP">
        <Head>
            <title>Verify OTP | Qariin</title>
        </Head>
        <jet-authentication-card simple>
            <jet-validation-errors class="mb-4" />

            <form
                @submit.prevent="submit"
                class="flex flex-col items-center justify-center py-12"
            >
                <h1 class="text-4xl text-secondary-900 mb-2">
                    ادخل كود التفعيل
                </h1>
                <p class="text-tertiary text-sm max-w-sm mb-8">
                    أدخل رمز التحقق الذي تم ارساله علي رقم جوالك
                </p>
                <fieldset class="input password">
                    <jet-label for="otp">ادخل كود التفعيل</jet-label>
                    <input
                        type="number"
                        id="otp"
                        required
                        name="otp"
                        placeholder="ادخل كود التفعيل هنا..."
                        v-model="form.otp"
                        maxlength="4"
                        @input="validateLength($event, 'otp')"
                    />
                </fieldset>
                <jet-button
                    type="submit"
                    class="bg-primary text-white w-64 rounded-md"
                    :class="{ disabled: form.processing }"
                >
                    تأكيد
                </jet-button>
                <p>
                    <span class="text-sm text-tertiary">
                        إذا لم تتلقى رمز اضغط على
                    </span>
                    <button
                        @click.prevent="resendCode"
                        :class="{ 'opacity-25': timerEnabled }"
                        :disabled="timerEnabled"
                    >
                        إعادة إرسال
                        <span v-if="timerCount != 60" class="ps-2">{{
                            timerCount
                        }}</span>
                    </button>
                </p>
                <span
                    v-if="hasError"
                    class="inline-block mt-1 text-danger text-sm"
                    >برجاء التأكد من كود التفعيل</span
                >
                <span
                    v-if="resendHasError"
                    class="inline-block mt-1 text-danger text-sm"
                    >حدث خطأ اثناء إعادة الإرسال, برجاء المحاولة مرة اخرى</span
                >

                <p
                    class="mb-4 font-medium text-sm text-green-600 text-center"
                    v-if="verificationOTPSent"
                >
                    تم ارسال كود تفعيل جديد.
                </p>
            </form>
        </jet-authentication-card>
    </app-layout>
</template>

<script>
import { Head } from '@inertiajs/inertia-vue3'
import { defineComponent } from "vue";
import JetAuthenticationCard from "@/Jetstream/AuthenticationCard.vue";
import JetButton from "@/Jetstream/Button.vue";
import JetValidationErrors from "@/Jetstream/ValidationErrors.vue";
import AppLayout from "@/Layouts/AppLayout.vue";
import JetLabel from "@/Jetstream/Label.vue";

export default defineComponent({
    components: {
        AppLayout,
        Head,
        JetAuthenticationCard,
        JetButton,
        JetLabel,
        JetValidationErrors,
    },
    props: {
        user: {
            type: Object,
            required: true,
        },
        gettingIntendedUrl: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            hasError: false,
            verificationOTPSent: false,
            resendHasError: false,
            timerEnabled: false,
            timerCount: 60,
            form: {
                otp: null,
                value: null,
                column: "mobile",
            },
        };
    },

    watch: {
        timerEnabled(value) {
            if (value) {
                setTimeout(() => {
                    this.timerCount--;
                }, 1000);
            }
        },

        timerCount: {
            handler(value) {
                if (value > 0 && this.timerEnabled && value <= 60) {
                    setTimeout(() => {
                        this.timerCount--;
                    }, 1000);
                } else {
                    this.timerEnabled = false;
                    this.timerCount = 60;
                }
            },
            immediate: true, // This ensures the watcher is triggered upon creation
        },
    },
    methods: {
        validateLength(e) {
            const input = e.target;
            const fieldset = e.target.closest("fieldset");
            var value = this.form.otp.toString();

            if (value.length < input.maxLength)
                fieldset.classList.add("border-danger");
            else if (value.length > input.maxLength)
                this.form.otp = this.form.otp
                    .toString()
                    .slice(0, input.maxLength);
            else if (value.length == input.maxLength)
                fieldset.classList.remove("border-danger");
        },
        async submit() {
            const payload = this.form;
            this.hasError = false;

            await axios
                .post(route("verify.otp"), payload)
                .then((res) => {
                    if (res.data.success) {
                        localStorage.removeItem('otp_reason');
                        window.location = this.gettingIntendedUrl ? this.gettingIntendedUrl : "/";
                    }
                })
                .catch((err) => {
                    console.error(err);
                    this.hasError = true;
                });
        },
        async resendCode() {
            this.verificationOTPSent = false;
            this.timerEnabled = true;
            this.resendHasError = false;
            const otpReason = localStorage.getItem('otp_reason');

            const payload = {
                mobile: this.form.value,
                'otp-reason': otpReason,
            };

            this.hasError = false;

            await axios
                .post(route("send.mobile.otp"), payload)
                .then(() => {
                    this.verificationOTPSent = true;
                })
                .catch((err) => {
                    console.error(err);
                    this.resendHasError = true;
                });
        },
    },
    async mounted() {
        this.form.value = this.user.mobile;
        await this.resendCode();
    },
});
</script>
