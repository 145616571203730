<template>
    <app-layout>
        <main class="staticPage">
            <div class="container">
                <h1 class="text-xl lg:text-3xl text-black mt-16">
                    تأمين السفر
                </h1>
                <section class="staticPage__info">
                    <div class="mt-8">
                        <p class="text-secondary max-w-md">
                            يحصل المسافرون على تغطية شاملة على الرحلات الدولية
                            من المخاطر المرتبطة بالسفر خارج المملكة العربية
                            السعودية.
                        </p>
                        <a href="/" class="btn mt-8 inactiveLink">قارن الان</a>
                    </div>
                    <img
                        class="staticPage__poster"
                        src="@qariin-fe/src/images/static-pages/road-services.svg"
                        alt="main poster"
                    />
                </section>
                <section class="staticPage__features">
                    <div class="staticPage__feature">
                        <h2 class="staticPage__featureTitle">
                            من الحالات التي تغطيها شركات التأمين:
                        </h2>
                        <ul class="staticPage__list">
                            <li>الغاء أو تأخر الرحلات.</li>
                            <li>فقدان أو تأخر الامتعة.</li>
                            <li>فقدان جواز السفر.</li>
                            <li>المصاريف الطبية الطارئة.</li>
                            <div
                                class="bg-secondary-50 text-secondary p-2 rounded-md text-xs mt-3"
                            >
                                تتفاوت قيمة الحد الأقصى للتعويض الذي تدفعه
                                الشركة للمؤمن له حسب نوع التغطية التأمينية
                                المشمولة بوثيقة تأمين السفر.
                            </div>
                        </ul>
                    </div>
                    <div class="staticPage__feature">
                        <h2 class="staticPage__featureTitle">
                            مدة تامين السفر
                        </h2>
                        <ul class="staticPage__list staticPage__list--disabled">
                            <li>
                                المدة القصيرة: الرحلات الفردية يغطيها خلال مدة
                                لا تتجاوز 6 شهور.
                            </li>
                            <li>
                                التأمين السنوي: يغطي عدد من الرحلات التي اتخذت
                                أثناء السنة بحيث لا تتجاوز مدة الرحلة الواحدة 60
                                يوماً متصلة. إصلاح السيارة.
                            </li>
                        </ul>
                    </div>
                </section>
            </div>
        </main>
    </app-layout>
</template>

<script>
import AppLayout from "@/Layouts/AppLayout.vue";

export default {
    name: "TravelInsurance",
    components: {
        AppLayout,
    },
};
</script>
<style scoped>
.inactiveLink {
   pointer-events: none;
   cursor: default;
   opacity: .2;
   background-color: rgba(0, 49, 94);
}
</style>
