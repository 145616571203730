<template>
    <app-layout>
        <Head>
            <title>Terms & Conditions | Qariin</title>
        </Head>
        <section class="py-12 bg-secondary-50">
            <div class="container">
                <h1 class="text-xl lg:text-3xl text-secondary-900">
                    الشروط و الاحكام
                </h1>
                <p class="text-secondary max-w-xl my-2">
                    شركة اثنان واربعون الوسيط لوساطة التأمين الالكتروني مرخصة ومصرحة من
                    قبل البنك المركزي السعودي عند استخدامك للموقع يجب عليك
                    الموافقة على الشروط والاحكام التالية :
                </p>
            </div>
        </section>

        <section class="my-6">
            <div class="container">
                <h2 class="text-secondary-900 text-lg my-2">
                    أولا / التعريفات
                </h2>
                <p class="text-secondary my-1">
                    نحن أو أي كلمة تشير الى موقع اثنان واربعون يقصد بها (شركة اثنان واربعون الوسيط
                    لوساطة التأمين الالكتروني) انت او اي كلمة تشير إليك يقصد بها
                    مستخدم الموقع الفعلي المسجل بحساب الدخول مثل كلمة (مستخدم)
                    وغيرها مزود الخدمة ويقصد بها شركات التأمين التي يتم عرض
                    أسعارها على موقع اثنان واربعون
                </p>
            </div>
        </section>

        <section class="my-6">
            <div class="container">
                <h2 class="text-secondary-900 text-lg my-2">
                    ثانيا / الاحكام العامة
                </h2>
                <p class="text-secondary my-1">
                    يجب على مستخدم موقع اثنان واربعون أن يكون ذو أهلية قانونية.
                </p>
                <p class="text-secondary my-1">
                    بدخولك الى موقع اثنان واربعون واضافة معلوماتك الشخصية وبذلك تقر
                    بموافقتك على مشاركة و سحب معلوماتك مع الجهات الحكومية و الجهات ذات العلاقة.
                </p>
                <p class="text-secondary my-1">
                    انك تقر وتتعهد بانك أدخلت جميع البينات الصحيحة وفي حال
                    اكتشاف غير ذك فإنك تتحمل ألمسؤولية القانونية كاملة تتعهد
                    بعدم القيام باي شي يؤثر على سلامة ومعلومات موقع اثنان واربعون او
                    إدخال مواد ضاره وفي حال عمل ذلك يحق لموقع اثنان واربعون اللجوء الى
                    الجهات المختصة يحق لنا رفض بعض المعلومات او الوثائق حسب
                    تقديرنا استخدام موقع اثنان واربعون هو للاستخدام الشخصي فقط وغير
                    التجاري عدا التأمين الطبي للمؤسسة فقط حسب الشروط يمكنك
                    الاطلاع على شروط الوثيقة قبل إصدارها.
                </p>
                <p class="text-secondary my-1">
                    يجب ادخال رقم الجوال وبريد الإلكتروني صحيح لكي تتلقى التحديثات
                    الخاصة بالوثيقة شركة اثنان واربعون هي شركة وساطة تقوم بعرض الأسعار
                    للشركات المرخصة من قبل البنك المركزي السعودي وليس من
                    مسؤوليتها التعويض عن الالغاءات او الحوادث .
                </p>
                <p class="text-secondary my-1">
                    لكل شركة تأمين طريقتها الخاصة في عرض السعر
                </p>
            </div>
        </section>

        <section class="my-6">
            <div class="container">
                <h2 class="text-secondary-900 text-lg my-2">ثالثا / الدفع</h2>
                <p class="text-secondary my-1">
                    البطاقة البنكية ( مدى / فيزا / ماستر كارد ) سداد
                </p>
            </div>
        </section>
    </app-layout>
</template>

<script>
import { Head } from '@inertiajs/inertia-vue3'
import AppLayout from "@/Layouts/AppLayout.vue";
export default {
    components: {
        AppLayout,
        Head,
    },
};
</script>
