<template>
    <app-layout>
        <main class="staticPage">
            <div class="container">
                <h1 class="text-xl lg:text-3xl text-black mt-16">
                    تأمين العمالة المنزلية
                </h1>
                <section class="staticPage__info">
                    <div class="mt-8">
                        <p class="text-secondary max-w-md">
                            تضمن هذه الوثيقة حقوق الكفيل والعمالة على حد سواء
                            ويقصد بالعمالة المنزلية أي عامل/ة على كفالة فردية
                            ولديه اقامة سارية المفعول وفقا لتعريف وزارة العمل في
                            المملكة العربية السعودية وذلك حسب شروط .واستثناءات
                            وثيقة تأمين حماية عقود العمالة المنزلية حسب ما
                            تقتضيه الوثيقة.
                        </p>
                        <a href="/" class="btn mt-8 inactiveLink">قارن الان</a>
                    </div>
                    <img
                        class="staticPage__poster"
                        src="@qariin-fe/src/images/static-pages/domestic-labor-insurance.svg"
                        alt="main poster"
                    />
                </section>
                <section class="staticPage__features">
                    <div class="staticPage__feature">
                        <h2 class="staticPage__featureTitle">تشمل التغطية :</h2>
                        <ul class="staticPage__list">
                            <li>تكاليف العودة إلى الوطن</li>
                            <li>الهروب من العمالة المنزلية</li>
                            <li>
                                رفض العمالة المنزلية لمواصلة العمل بموجب العقد
                            </li>
                            <li>وفاة عامل الخدمة المنزلية</li>
                            <li>
                                العجز الكلي أو الجزئي الدائم نتيجة حادث مفاجئ
                            </li>
                            <li>تكاليف الاستبدال</li>
                            <li>أسباب طبية للعمالة المنزلية</li>
                        </ul>
                    </div>
                    <div class="staticPage__feature">
                        <h2 class="staticPage__featureTitle">
                            التغطيات الإضافية:
                        </h2>
                        <h3 class="text-black mb-4">منافع التعويض للمستفيد:</h3>
                        <ul class="staticPage__list">
                            <li>
                                منفعة التعويض للمستفيد من الوثيقة في حال الوفاة
                                العرضية للشخص المؤمن عليه (العمالة المنزلية).
                            </li>
                        </ul>
                    </div>
                </section>
            </div>
        </main>
    </app-layout>
</template>

<script>
import AppLayout from "@/Layouts/AppLayout.vue";

export default {
    name: "DomesticLaborInsurance",
    components: {
        AppLayout,
    },
};
</script>
<style scoped>
.inactiveLink {
   pointer-events: none;
   cursor: default;
   opacity: .2;
   background-color: rgba(0, 49, 94);
}
</style>
