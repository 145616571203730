<template>
    <app-layout>
        <main class="staticPage">
            <div class="container">
                <h1 class="text-xl lg:text-3xl text-black mt-16">
                    تأمين اصابة العمال
                </h1>
                <section class="staticPage__info">
                    <div class="mt-8">
                        <p class="text-secondary max-w-md">
                            تغطي هذه الوثيقة المؤمن له عن كافة المبالغ التي
                            سيكون مسؤول عن دفعها طبقا لقانون العمل السعودي لأي
                            موظف يعمل بصفة مباشرة في خدمة المؤمن له عن اي اصابة
                            شخصية ناتجة عن حادث او مرض عارض او وفاة وتنشأ اثناء
                            وبسبب مزاولة العمل لدى المؤمن له و خلال فترة التأمين
                            حسب ما تقتضيه الوثيقة.
                        </p>
                        <a href="/" class="btn mt-8">قارن الان</a>
                    </div>
                    <img
                        class="staticPage__poster"
                        src="@qariin-fe/src/images/static-pages/workers-injury-insurance.png"
                        srcset="
                            @qariin-fe/src/images/static-pages/workers-injury-insurance.png    400w,
                            @qariin-fe/src/images/static-pages/workers-injury-insurance@2x.png 800w
                        "
                        sizes="(max-width: 678px) 400px, 800px"
                        alt="main poster"
                    />
                </section>
            </div>
        </main>
    </app-layout>
</template>

<script>
import AppLayout from "@/Layouts/AppLayout.vue";

export default {
    name: "WorkersInjuryInsurance",
    components: {
        AppLayout,
    },
};
</script>
