<style lang="scss">
video {
    height: 100vh;
    width: 100vw;
}
</style>
<template>
    <!-- Page Heading -->

    <!-- Page Content -->
    <main>
        <slot></slot>
    </main>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
    props: {},

    components: {},

    data() {
        return {
            showingNavigationDropdown: false,
        };
    },

    mounted() {
        require("@qariin-fe/src/scripts/index.js");
    },

    methods: {},
});
</script>
