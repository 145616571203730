<template>
    <app-layout title="Forgot Password">
        <Head>
            <title>Forgot password | Qariin</title>
        </Head>
        <ErrorsAlert
            v-if="errorMessages && errorMessages.length"
            :errors="errorMessages"
        />
        <jet-authentication-card>
            <div v-if="status" class="mb-4 font-medium text-sm text-green-600">
                {{ status }}
            </div>

            <jet-validation-errors class="mb-4" />

            <div class="container flex justify-center mt-16 text-center">
                <form @submit.prevent="submit">
                    <h1 class="text-2xl lg:text-3xl">إستعادة كلمة المرور</h1>

                    <p class="text-sm mb-3">
                        <span class="text-secondary-500"
                            >لديك حساب ؟ <a href="/login">قم بالدخول إلى حسابك</a></span
                        >
                        <a
                            :href="route('login')"
                            class="text-primary-500 ms-2"
                            >تسجيل دخول</a
                        >
                    </p>

                    <fieldset class="input input-w-auto email mb-4" v-if="!success">
                        <jet-label for="text" value=" البريد الالكتروني أو رقم الجوال" />
                        <jet-input
                            type="text"
                            id="text"
                            required
                            name="value"
                            v-model="input.value"
                            placeholder="اكتب البريد الالكتروني أو رقم الجوال هنا..."
                            autofocus
                        />
                    </fieldset>

                    <template v-else>
                        <p class="text-success mb-2">
                            سيتم ارسال البيانات اللازمة
                        </p>
                        <fieldset class="input" style="width: 100%">
                            <jet-label for="otp">ادخل كود التفعيل</jet-label>
                            <input
                                type="number"
                                id="otp"
                                required
                                name="otp"
                                placeholder="ادخل كود التفعيل هنا..."
                                v-model="input.otp"
                                maxlength="4"
                                @input="validateLength($event, 'otp')"
                            />
                            <button
                                type="button"
                                class="absolute top-0 -left-1 z-10 text-xs min-h-full w-5"
                                @click.prevent="resendCode"
                                :class="{ 'opacity-25 left-3': timerEnabled }"
                                :disabled="timerEnabled"
                            >
                                <img
                                    :src="
                                        require('@qariin-fe/src/images/input-icons/reload.svg')
                                            .default
                                    "
                                    alt="Reload"
                                />
                                <span v-if="timerCount != 60" class="ps-2">{{
                                    timerCount
                                }}</span>
                            </button>
                        </fieldset>
                        <p
                            class="mb-4 font-medium text-sm text-green-600 text-center"
                            v-if="verificationOTPSent"
                        >
                            تم ارسال كود تفعيل جديد.
                        </p>
                        <!-- <button
                            @click.prevent="resendCode"
                            :class="{ 'opacity-25': timerEnabled }"
                            :disabled="timerEnabled"
                        >
                            إعادة إرسال
                            <span v-if="timerCount != 60" class="ps-2">{{
                                timerCount
                            }}</span>
                        </button> -->

                        <ul :class="{ active: passwordActive }">
                            <li :class="{ is_valid: contains_eight_characters }">
                                يحتوي على 8 حروف
                            </li>
                            <li :class="{ is_valid: contains_number }">
                                يحتوي على أرقام
                            </li>
                            <li :class="{ is_valid: contains_uppercase }">
                                يحتوي على أحرف كبيرة
                            </li>
                            <li :class="{ is_valid: contains_special_character }">
                                يحتوي على حرف خاص
                            </li>
                        </ul>

                        <fieldset class="input input-w-auto password mb-4">
                            <jet-label for="password">كلمة المرور الجديدة</jet-label>
                            <jet-input
                                type="password"
                                id="password"
                                required
                                autocomplete="new-password"
                                name="email"
                                placeholder="اكتب كلمة المرور هنا..."
                                v-model="input.password"
                                @keyup="checkPassword"
                                @focus="passwordActive = true"
                                @blur="blurPassword"
                            />
                        </fieldset>
                        <fieldset class="input input-w-auto password">
                            <jet-label for="password"
                                >اعد كتابة كلمة المرور الجديدة</jet-label
                            >
                            <jet-input
                                type="password"
                                required
                                autocomplete="new-password"
                                name="password_confirmation"
                                placeholder="اكتب كلمة المرور هنا..."
                                v-model="input.password_confirmation"
                            />
                        </fieldset>
                    </template>

                    <jet-button
                        v-show="!isLoading"
                        type="submit"
                        class="bg-primary text-white w-full rounded-md"
                        :class="{ 'opacity-25': !formIsValid && success }"
                        :disabled="!formIsValid && success"
                    >
                        {{ success ? "تغيير كلمة السر" : "ارسال الرابط" }}
                    </jet-button>
                    <VueLottiePlayer
                        v-show="isLoading"
                        style="width: 200px; height: 100px"
                        name="Qariin loader"
                        loop
                        path="https://assets9.lottiefiles.com/private_files/lf30_1xgata3z.json"
                    />
                </form>
            </div>
        </jet-authentication-card>
    </app-layout>
</template>

<script>
import { Head } from '@inertiajs/inertia-vue3'
import { defineComponent } from "vue";
import JetAuthenticationCard from "@/Jetstream/AuthenticationCard.vue";
import JetButton from "@/Jetstream/Button.vue";
import JetInput from "@/Jetstream/Input.vue";
import JetLabel from "@/Jetstream/Label.vue";
import JetValidationErrors from "@/Jetstream/ValidationErrors.vue";
import AppLayout from "@/Layouts/AppLayout.vue";
import ErrorsAlert from "@/components/ErrorsAlert.vue";
import VueLottiePlayer from "vue-lottie-player";

export default defineComponent({
    components: {
        ErrorsAlert,
        Head,
        AppLayout,
        JetAuthenticationCard,
        JetButton,
        JetInput,
        JetLabel,
        JetValidationErrors,
        VueLottiePlayer,
    },

    props: {
        status: String,
    },

    data() {
        return {
            input: {
                value: "",
                otp: "",
                password: "",
                password_confirmation: "",
            },
            isLoading: false,
            verificationOTPSent: false,
            timerEnabled: false,
            timerCount: 60,
            column: null,
            success: false,
            errorMessages: [],
            passwordActive: false,
            password_length: 0,
            contains_eight_characters: false,
            contains_number: false,
            contains_uppercase: false,
            contains_special_character: false,
            valid_password: false,
        };
    },

    computed: {
        formIsValid() {
            return this.input.password === this.input.password_confirmation
                && this.input.otp
                && this.valid_password
        }
    },

    watch: {
        
        timerEnabled(value) {
            if (value) {
                setTimeout(() => {
                    this.timerCount--;
                }, 1000);
            }
        },

        timerCount: {
            handler(value) {
                if (value > 0 && this.timerEnabled && value <= 60) {
                    setTimeout(() => {
                        this.timerCount--;
                    }, 1000);
                } else {
                    this.timerEnabled = false;
                    this.timerCount = 60;
                }
            },
            immediate: true, // This ensures the watcher is triggered upon creation
        },
    },

    methods: {
        async submit() {
            this.isLoading = true;
            this.errorMessages = [];

            if (this.success && this.column) {
                await axios
                    .post(route('new.forget.password'), {
                        column: this.column,
                        value: this.input.value,
                        otp: this.input.otp,
                        password: this.input.password,
                        password_confirmation: this.input.password_confirmation,
                    })
                    .then(() => {
                        window.location = route('login');
                    })
                    .catch(error => {
                        var { response } = error;
                        var { data } = response;

                        Object.keys(data.errors).forEach(key => {
                            data.errors[key].forEach(message => {
                                this.errorMessages.push(message)
                            })
                        })

                        this.value = null;
                    })
                    .finally(() => {
                        this.isLoading = false;
                    })
            } else {
                await axios
                    .post("/api/send/otp/forget/password", this.input)
                        .then(response => {
                            const { data } = response;
    
                            this.success = data.success;
                            this.column = data.data;
                            this.timerEnabled = true;
                        })
                        .catch(error => {
                            var { response } = error;
                            var { data } = response;

                            Object.keys(data.errors).forEach(key => {
                                data.errors[key].forEach(message => {
                                    this.errorMessages.push(message)
                                })
                            })

                            this.value = null;
                        })
                        .finally(() => {
                            this.isLoading = false;
                        })
            }
        },
        async resendCode() {
            this.verificationOTPSent = false;
            this.timerEnabled = true;
            
            await axios
                .post("/api/send/otp/forget/password", this.input)
                    .then(response => {
                        const { data } = response;

                        this.success = data.success;
                        this.column = data.data;
                        this.verificationOTPSent = true;
                    })
                    .catch(error => {
                        var { response } = error;
                        var { data } = response;

                        Object.keys(data.errors).forEach(key => {
                            data.errors[key].forEach(message => {
                                this.errorMessages.push(message)
                            })
                        })

                        this.value = null;
                    })
                    .finally(() => {
                        this.isLoading = false;
                    })
        },
        validateLength(e) {
            const input = e.target;
            const fieldset = e.target.closest("fieldset");
            var value = this.input.otp.toString();

            if (value.length < input.maxLength)
                fieldset.classList.add("border-danger");
            else if (value.length > input.maxLength)
                this.input.otp = this.input.otp
                    .toString()
                    .slice(0, input.maxLength);
            else if (value.length == input.maxLength)
                fieldset.classList.remove("border-danger");
        },
        blurPassword() {
            if (!this.valid_password) this.passwordActive = false
        },
        checkPassword() {
            this.password_length = this.input.password.length;
            const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

            if (this.password_length >= 8) {
                this.contains_eight_characters = true;
            } else {
                this.contains_eight_characters = false;
            }

            this.contains_number = /\d/.test(this.input.password);
            this.contains_uppercase = /[A-Z]/.test(this.input.password);
            this.contains_special_character = format.test(this.input.password);

            if (this.contains_eight_characters === true &&
                this.contains_special_character === true &&
                this.contains_uppercase === true &&
                this.contains_number === true) {
                this.valid_password = true;
            } else {
                this.valid_password = false;
            }
        }
    },
    mounted() {
        localStorage.setItem('otp_reason', 'forget-password-otp');
    }
});
</script>

<style scoped>
ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
    height: 0;
    transition: height 0.5s ease;
}

ul.active {
    height: 92px;
}

li {
    color: #525f7f;
    position: relative;
    font-size: 12px;
}

li:before {
    content: "";
    width: 0%;
    height: 2px;
    background: #2ecc71;
    position: absolute;
    left: 0;
    top: 50%;
    display: block;
    transition: all .6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.is_valid {
    color: rgba(136, 152, 170, 0.8);
}

.is_valid:before {
    width: 100%;
}

</style>
