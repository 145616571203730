<template>
    <app-layout>
        <section class="py-12 bg-secondary-50">
            <div class="container">
                <h1 class="text-2xl lg:text-3xl text-secondary-900">
                    سياسة الخصوصية
                </h1>
                <p class="text-secondary max-w-xl my-2">
                    نحن هنا نهتم بحماية بياناتك ونحرص عليها بجدية تامة، وذلك من
                    خلال التزامنا بالتعليمات الصادرة من البنك المركزي السعودي
                    (ساما)، وحرصا منا على ذلك فلقد تم صياغة سياسة الخصوصية هذه،
                    وهي كالتالي:
                </p>
            </div>
        </section>

        <section class="my-6">
            <div class="container">
                <h2 class="text-secondary-900 text-2xl my-2">نطاق السياسة :</h2>
                <p class="text-secondary my-1">
                    وضعت سياسة الخصوصية بشكل مفصل وواضح، وذلك من أجل تقديم خدمة
                    أفضل لحماية بياناتك من اي ضرر، ولسهولة حصولك على وثيقتك
                    التأمينية، وعليه نلتزم في منصة قارن بالمبادئ الرئيسية
                    والقواعد العامة لحماية البيانات الشخصية وكذلك المبادئ
                    الرئيسية والقواعد العامة لمشاركة البيانات.
                </p>
            </div>
        </section>

        <section class="my-6">
            <div class="container">
                <h2 class="text-secondary-900 text-xl my-2">
                    المبادئ الرئيسية لحماية البيانات الشخصية
                </h2>
                <h2 class="text-secondary-900 text-lg my-2">
                    المبدأ الأول: المسؤولية
                </h2>
                <p class="text-secondary my-1">
                    أن يتم تحديد وتوثيق سياسات وإجراءات الخصوصية الخاصة بجهة
                    التحكم واعتمادها من قبل المسؤول الاول بالجهة (او من يفوضه)،
                    ونشرها الى جميع الاطراف المعنية لتطبيقها.
                </p>
                <h2 class="text-secondary-900 text-lg my-2">
                    المبدأ الثاني: الشفافية
                </h2>
                <p class="text-secondary my-1">
                    أن يتم إعداد إشعار عن سياسات وإجراءات الخصوصية الخاصة يُحدد
                    فيه الأغراض التي من أجلها تم معالجة البيانات الشخصية وذلك
                    بصورة محددة وواضحة وصريحة.
                </p>
                <h2 class="text-secondary-900 text-lg my-2">
                    المبدأ الثالث: الاختيار والموافقة
                </h2>
                <p class="text-secondary my-1">
                    أن يتم تحديد جميع الخيارات الممكنة لصاحب البيانات الشخصية
                    والحصول على موافقته (الضمنية أو الصريحة) فيما يتعلق بجمع
                    بياناته واستخدامها أو الإفصاح عنها.
                </p>
                <h2 class="text-secondary-900 text-lg my-2">
                    المبدأ الرابع: الحد من جمع البيانات
                </h2>
                <p class="text-secondary my-1">
                    أن يقتصرجمع البيانات الشخصية على الحد الأدنى من البيانات
                    الذي يمكن من تحقيق الأغراض المحددة في إشعار الخصوصية.
                </p>
                <h2 class="text-secondary-900 text-lg my-2">
                    المبدأ الخامس: الحد من استخدام البيانات والاحتفاظ بها
                    والتخلص منها
                </h2>
                <p class="text-secondary my-1">
                    أن يتم تقييد معالجة البيانات الشخصية بالأغراض المحددة في
                    إشعار الخصوصية والتي من أجلها قدم صاحب البيانات موافقته
                    (الضمنية أو الصريحة)، والاحتفاظ بها طالما كان ذلك ضروريا
                    لتحقيق الأغراض المحددة أو لما تقتضيه الأنظمة واللوائح
                    والسياسات المعمول بها في المملكة وإتلافها بطريقة آمنة تمنع
                    التسريب، أو الفقدان، أو الاختلاس، أو إساءة الاستخدام، أو
                    الوصول غير المصرح به نظامًا.
                </p>
                <h2 class="text-secondary-900 text-lg my-2">
                    المبدأ السادس: الوصول إلى البيانات
                </h2>
                <p class="text-secondary my-1">
                    أن يتم تحديد وتوفير الوسائل التي من خلالها يمكن لصاحب
                    البيانات الوصول إلى بياناته الشخصية لمراجعتها وتحديثها
                    وتصحيحها.
                </p>
                <h2 class="text-secondary-900 text-lg my-2">
                    المبدأ السابع: الحد من الإفصاح عن البيانات
                </h2>
                <p class="text-secondary my-1">
                    أن يتم تقييد الإفصاح عن البيانات الشخصية للأطراف الخارجية
                    بالأغراض المحددة في إشعار الخصوصية والتي من أجلها قدم صاحب
                    البيانات موافقته الضمنية أو الصريحة.
                </p>
                <h2 class="text-secondary-900 text-lg my-2">
                    المبدأ الثامن: أمن البيانات
                </h2>
                <p class="text-secondary my-1">
                    أن يتم حماية البيانات الشخصية من التسرب، أو التلف، أو
                    الفقدان، أو الاختلاس، أو إساءة الاستخدام، أو التعديل أو
                    الوصول غير المصرح به -وفقا لما يصدر من الهيئة الوطنية للأمن
                    السيبراني والجهات ذات الاختصاص.
                </p>
                <h2 class="text-secondary-900 text-lg my-2">
                    المبدأ التاسع: جودة البيانات
                </h2>
                <p class="text-secondary my-1">
                    أن يتم الاحتفاظ بالبيانات الشخصية بصورة دقيقة، وكاملة، وذات
                    علاقة مباشرة بالأغراض المحددة في إشعار الخصوصية.
                </p>
                <h2 class="text-secondary-900 text-lg my-2">
                    المبدأ العاشر: المراقبة والامتثال
                </h2>
                <p class="text-secondary my-1">
                    أن يتم مراقبة الامتثال لسياسات وإجراءات الخصوصية الخاصة بجهة
                    التحكم، ومعالجة الاستفسارات والشكاوى والنزاعات المتعلقة
                    بالخصوصية.
                </p>
            </div>
        </section>

        <section class="my-6">
            <div class="container">
                <h2 class="text-secondary-900 text-xl my-4">
                    استخدام معلوماتك الشخصية
                </h2>
                <p class="text-secondary my-1">
                    تم إعداد سياسة الخصوصية وذلك بهدف الحفاظ على معلوماتك
                    والاحتفاظ بثقتك واحترام حقك في حماية هذه المعلومات، وعليه
                    نود إحاطتك بأننا سوف نستخدم هذه المعلومات لإدارة التامين
                    الخاص بك، وضمان التامين، ومعالجة المطالبات، والتحليل
                    الاحصائي.
                </p>
                <p class="text-secondary my-1">
                    سيتم حفظ معلوماتك الخاصة عند التسجيل في الموقع الالكتروني او
                    عند شراء أحد المنتجات او الخدمات المقدمة لك لمشاركتها مع
                    منظومة التأمين المتعلقة بقارن على سبيل المثال الجهات ذات
                    العلاقة، والوكلاء، وشركات التأمين المصرح لهم من قبل الجهات
                    الرسمية، وفي حال الحاجة الى مشاركتها مع اي جهة ليست ذات
                    علاقة لن يتم مشاركتها الا في حال الحصول على اذن او سمح
                    القانون بذلك.
                </p>
                <p class="text-secondary my-1">
                    منصة قارن تحافظ على بياناتك وتعمل على الأنظمة واللوائح
                    والسياسات المعمول بها في المملكة العربية السعودية، وإتلافها
                    بطريقة آمنة تمنع التسرب، أو الفقدان، أو الاختلاس، أو إساءة
                    الاستخدام، أو الوصول غير المصرح به نظامًا.
                </p>
            </div>
        </section>

        <section class="my-6">
            <div class="container">
                <h2 class="text-secondary-900 text-xl my-4">
                    استخدام المعلومات الشخصية في الاغراض التسويقية
                </h2>
                <p class="text-secondary my-1">
                    قد نستخدم المعلومات التي تقدمها لنا، أو قد يستخدمها أعضاء
                    آخرون أو شركات أخرى يتم اختيارها بعناية، وذلك لأغراض بحثية
                    أو لإعلامك بمنتجات أو خدمات أخرى قد تمثل لك أهمية، وذلك ما
                    لم تنصحنا بخلاف ذلك عند التواصل معك، حيث سُيتاح لك دائمًا
                    خيار الانسحاب، وفي حالة تزويدك لنا بمعلومات الاتصال أو كنت
                    تفضل عدم تلقي معلومات تسويقية أو المشاركة في البحث، يرجى
                    الاتصال بنا وإبلاغنا بذلك.
                </p>
            </div>
        </section>

        <section class="my-6">
            <div class="container">
                <h2 class="text-secondary-900 text-xl my-4">
                    ما هي البيانات التي يتم جمعها؟
                </h2>
                <h2 class="text-secondary-900 text-lg my-2">
                    ملفات تعريف الارتباط (Cookies)
                </h2>
                <p class="text-secondary my-1">
                    قد يقوم الموقع بتخزين ما يسمى بملفات تعريف الارتباط
                    “Cookies” على الكمبيوتر الخاص بك عندما تقوم بزيارة المنصة.
                    إن ملفات تعريف الارتباط “Cookies” هي جزء من البيانات التي
                    تحددك كمستخدم بشكل فريد، كما يمكن استخدامها لتطوير معرفتك
                    بالمنصة ولفهم قاعدة مستخدم هذه المنصة على نحو أفضل.
                </p>
                <p class="text-secondary my-1">
                    يتم إعداد معظم المتصفحات في البداية لقبول ملفات تعريف
                    الارتباط، كما يمكنك إعادة إعداد المتصفح الخاص بك لرفض كافة
                    ملفات تعريف الارتباط أو للتنبيه عندما يتم إرسال ملفات تعريف
                    الارتباط، كما يرجى ملاحظة أن بعض الخدمات التي يتم عرضها على
                    هذه المنصة قد لا تعمل بشكل صحيح إذا قمت برفض ملفات تعريف
                    الارتباط، حيث أننا قد نقوم / لا نقوم بربط المعلومات التي
                    نقوم بتخزينها في ملفات تعريف الارتباط بأي معلومات تعريف
                    شخصية تقدمها لنا على المنصة.
                </p>
                <h2 class="text-secondary-900 text-lg my-2">
                    المعلومات الشخصية
                </h2>
                <p class="text-secondary my-1">
                    تجلب منصة قارن معلوماتك الشخصية الموجودة من خلال شركة علم
                    لأمن المعلومات، وتقوم منصة قارن بحفظ هذه المعلومات.
                </p>
                <p class="text-secondary my-1">
                    تلتزم منصة قارن بالمبادئ الرئيسية والقواعد العامة لحماية
                    البيانات الشخصية، وكذلك المبادئ الرئيسية والقواعد العامة
                    لمشاركة البيانات.
                </p>
                <h2 class="text-secondary-900 text-lg my-2">الموقع الجغرافي</h2>
                <p class="text-secondary my-1">
                    قد يُطلب من المستخدم في بعض الأحيان الموافقة على تحديد
                    الموقع الجغرافي له، للاستفادة من بعض الخدمات المقدمة في
                    المنصة الوطنية الموحدة وتطبيقها.
                </p>
            </div>
        </section>

        <section class="my-6">
            <div class="container">
                <h2 class="text-secondary-900 text-xl my-4">
                    ما هو الغرض من جمع البيانات؟
                </h2>
                <p class="text-secondary my-1">
                    استخدام منصة قارن لبروتوكول الإنترنت الخاص بك يساعد على
                    تشخيص المشكلات التي تحدث في الخوادم الخاصة بها، ويساعدها
                    أيضًا على إجراء الإحصاء اللازم لقياس استخدام الموقع (عدد
                    الزوار ولغة جهاز الكمبيوتر الذي تستخدمه)، المنصة لا تسمح لأي
                    جهة خارج إطار الفريق الفني لها بالاطلاع على بروتوكول
                    الإنترنت الخاص بك.
                </p>
                <p class="text-secondary my-1">
                    يتم جمع معلوماتك الشخصية خلف شبكات مؤمَّنة ولا يمكن التصفح
                    إليها إلا بواسطة عدد محدود من الأشخاص الذين يمتلكون تصريح
                    دخول خاص إلى مثل هذه الأنظمة، كما هم ملزومين بالحفاظ على
                    سرية المعلومات.
                </p>
            </div>
        </section>

        <section class="my-6">
            <div class="container">
                <h2 class="text-secondary-900 text-xl my-4">
                    انتقال آمن للمعلومات
                </h2>
                <p class="text-secondary my-1">
                    نحن نضمن لك في منصة قارن بأن نحرص على تقديم أعلى إجراءات
                    الأمن لحماية المعلومات التي تقدمها لنا، وكذلك نعمل على تشفير
                    المعلومات التي تعتبر حساسة (مثل أرقام بطاقات الائتمان) أو
                    البيانات التي يتعين الحفاظ على سريتها توافقاً مع المتطلبات
                    القانونية، حيث يتم تشفيرها بشهادة (SSL) وهو بروتوكول أمن
                    يقوم بإنشاء ارتباط مشفر بين خادم ويب ومستعرض ويب فهي تحافظ
                    على أمان اتصالات الإنترنت وتمنع المجرمين من قراءة المعلومات
                    المنقولة بين نظامين أو تعديلها.
                </p>
                <p class="text-secondary my-1">
                    وفي حالة اختيارك لكلمة السر الخاصة بحسابك الشخصي، فإنه من
                    صميم مسؤولياتك المحافظة على سرية هذه الكلمة وعدم مشاركتها مع
                    الآخرين، فإذا كنت تشترك في استخدام الحاسب الآلي مع شخص آخر،
                    فيلزم عليك أن تقوم بتسجيل الخروج من حسابك الشخصي قبل انهاء
                    الاستخدام.
                </p>
            </div>
        </section>

        <section class="my-6">
            <div class="container">
                <h2 class="text-secondary-900 text-xl my-4">حقوق المستخدم:</h2>
                <h2 class="text-secondary-900 text-lg my-2">
                    يحق لأي مستخدم يزود المنصة بمعلوماته الشخصية التالي:
                </h2>
                <p class="text-secondary my-1">
                    الوصول إلى المعلومات. الحصول على المعلومات. مراجعة وتعديل
                    المعلومات. سحب الموافقة وحذف المعلومات.
                </p>
            </div>
        </section>

        <section class="my-6">
            <div class="container">
                <h2 class="text-secondary-900 text-xl my-4">
                    حق النشر والعلامة التجارية
                </h2>
                <p class="text-secondary my-1">
                    تمتلك منصة قارن العلامات التجارية، والشعارات وعلامات الخدمة
                    التي تظهر في هذا الموقع. ولا يجوز استخدام هذه العلامات دون
                    موافقة كتابية مسبقة من قبلنا قبل الاستخدام مع تحملك طائلة
                    المسؤولية القانونية في حال قيامك بأي نوع من الاستخدام الخاطئ
                    والمنافي للشروط والاحكام المذكورة هنا.
                </p>
                <p class="text-secondary my-1">
                    كما ان لوائح البنك المركزي السعودي وسياسة قارن تمنع منعاً
                    باتاً استخدام منصة قارن من قبل وسطاء التأمين أو المندوبين
                    إلا للأغراض المصرح لهم القيام بها من خلال المنصة. وان منصة
                    قارن للاستخدام الشخصي فقط وهي حصرية للفرد صاحب الشأن وغير
                    قابل للتحويل الى أي طرف ثالث.
                </p>
                <p class="text-secondary my-1">
                    بالإضافة إلى ان المواد الموجودة في هذه المنصة محمية بحق
                    النشر ولا يجوز تعديل أي جزء من هذه المواد أو إعادة إنتاجه أو
                    تخزينه في أي نظام استرداد، أو نقله أو نسخه أو توزيعه أو
                    استخدامه بأي طريقة أخرى لأغراض تجارية أو عامة دون موافقة
                    كتابية مسبقة من منصة قارن.
                </p>
            </div>
        </section>

        <section class="my-6">
            <div class="container">
                <h2 class="text-secondary-900 text-lg my-2">التعديلات</h2>
                <p class="text-secondary my-1">
                    يجوز لنا تغيير سياسة الخصوصية هذه في أي وقت دون إشعار مسبق،
                    وتعتبر أنت مسؤولا عن الاطلاع على كل ما هو جديد من خلال قراءة
                    سياسة الخصوصية هذه قبل الوصول إلى المنصة، وأنت توافق على أن
                    النسخة الحالية من سياسة الخصوصية تسري على عند استخدامك
                    المنصة: WWW.QARIIN.COM
                </p>
            </div>
        </section>

        <section class="my-6">
            <div class="container">
                <h2 class="text-secondary-900 text-lg my-2">
                    القانون الواجب التطبيق والمحاكم المختصة:
                </h2>
                <p class="text-secondary my-1">
                    تخضع شروط الاستخدام هذه وتفسر وفقاً لقانون المملكة العربية
                    السعودية. وتخضع المنازعات الناشئة ذات العلاقة بشروط
                    الاستخدام للاختصاص القضائي الحصري لمحاكم الرياض لدى المملكة
                    العربية السعودية.
                </p>
            </div>
        </section>

        <section class="my-6">
            <div class="container">
                <h2 class="text-secondary-900 text-lg my-2">
                    إذا كانت لديك أية أسئلة أو استفسارات حول سياسة الخصوصية
                    وممارسات الخصوصية لدينا، فيمكنك الاتصال بنا على:
                </h2>
                <div class="flex flex-col items-start justify-start">
                    <span class="text-tertiary">البريد الالكتروني</span>
                    <a
                        href="mailto:support@qariin.com"
                        class="text-sm text-secondary"
                        >support@qariin.com</a
                    >
                    <span class="text-tertiary">رقم الهاتف</span>
                    <a href="tel:8001111051" class="text-sm text-secondary"
                        >8001111051</a
                    >
                </div>
            </div>
        </section>
    </app-layout>
</template>

<script>
import AppLayout from "@/Layouts/AppLayout.vue";
export default {
    components: {
        AppLayout,
    },
};
</script>
