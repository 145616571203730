<template>
    <app-layout title="Home">
        <form action="" class="max-w-xs mx-auto my-12">
            <h1 class="text-2xl lg:text-3xl">البيانات الاساسية للمركبة</h1>
            <h3 class="text-base my-2">
                <span class="text-secondary-500">نوع تسجيل المركبة</span>
            </h3>
            <div class="flex items-center justify-start mt-1 mb-3">
                <fieldset class="me-10">
                    <input
                        v-model="input.licenseType"
                        value="form"
                        type="radio"
                        name="insurance-type"
                        id="form"
                        checked
                    />
                    <label for="form">استمارة</label>
                </fieldset>
                <fieldset>
                    <input
                        v-model="input.licenseType"
                        value="customs"
                        type="radio"
                        name="insurance-type"
                        id="customs"
                    />
                    <label for="customs">بطاقة جمركية</label>
                </fieldset>
            </div>
            <div v-if="input.licenseType === 'form'">
                <fieldset
                    class="input input-w-auto"
                    style="--icon: url('./src/images/icons/email.png')"
                >
                    <label for="vehicle-number">الرقم التسلسلي للمركبة</label>
                    <input
                        v-model="input.vehicle_sequence"
                        type="text"
                        id="vehicle-number"
                        required
                        name="vehicle-number"
                        placeholder="اكتب الرقم التسلسلي للمركبة هنا..."
                    />
                </fieldset>
            </div>
            <div v-else>
                <fieldset
                    class="selectbox mb-2"
                    :style="`--icon: url('${
                        require('@qariin-fe/src/images/svg/arrow-down-secondary.svg')
                            .default
                    }');`"
                >
                    <label for="car-year">سنة صنع السيارة</label>
                    <select
                        name="car-year"
                        id="car-year"
                        v-model="input.vehicle_model_year"
                        required
                    >
                        <option value="null" selected disabled>
                            اختر السنة
                        </option>
                        <option value="2022">2022</option>
                        <option value="2022">2021</option>
                        <option value="2022">2020</option>
                    </select>
                </fieldset>
                <fieldset
                    class="input input-w-auto"
                    style="--icon: url('./src/images/icons/password.png')"
                >
                    <label for="customs-value">الرقم الجمركي</label>
                    <input
                        v-model="input.vehicle_custom_number"
                        type="text"
                        id="customs-value"
                        required
                        name="customs-value"
                        placeholder="اكتب الرقم الجمركي هنا..."
                    />
                </fieldset>
            </div>
            <fieldset
                class="input input-w-auto"
                style="--icon: url('./src/images/icons/password.png')"
            >
                <label for="vehicle-value">قيمة المركبة</label>
                <input
                    v-model="input.vehicle_value"
                    type="number"
                    id="vehicle-value"
                    required
                    name="vehicle-value"
                    placeholder="اكتب قيمة المركبة هنا..."
                />
            </fieldset>
            <div class="flex items-center justify-center mt-5">
                <button
                    type="submit"
                    class="bg-primary text-white w-full rounded-md me-2"
                >
                    التالــــــــي
                </button>
                <button
                    type="button"
                    class="disabled bg-primary text-white w-full rounded-md"
                >
                    السابق
                </button>
            </div>
        </form>
    </app-layout>
</template>

<script>
import { defineComponent } from "vue";
import AppLayout from "@/Layouts/AppLayout.vue";
import Welcome from "@/Jetstream/Welcome.vue";
import JetInput from "@/Jetstream/Input.vue";
import JetLabel from "@/Jetstream/Label.vue";
import JetValidationErrors from "@/Jetstream/ValidationErrors.vue";

export default defineComponent({
    components: {
        AppLayout,
        JetValidationErrors,
        JetInput,
        JetLabel,
        Welcome,
    },
    data() {
        return {
            input: {
                licenseType: "form",
                start_date: null,
                vehicle_sequence: null,
                vehicle_model_year: null,
                vehicle_custom_number: null,
                vehicle_value: null,
            },
        };
    },

    methods: {
        submit() {
            this.form.post(this.route("search"), {
                onFinish() {
                    console.log(arguments);
                },
            });
        },
    },
});
</script>
