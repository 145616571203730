<template>
    <app-layout>
        <main class="staticPage">
            <div class="container">
                <h1 class="text-xl lg:text-3xl text-black mt-16">
                    تأمين المركبات
                </h1>
                <section class="staticPage__info">
                    <div class="mt-8">
                        <p class="text-secondary max-w-md">
                            هو عقد خطي بين طرفين الاول يدعى المؤمن (شركة التأمين
                            في العادة) والثاني المؤمن له (مالك المركبة)، وبموجب
                            هذا العقد يلتزم المؤمن بجبر الضرر وتعويض الموؤمن له
                            عن الأضرار التي تحصل لمركبته حسب ما يغطيه العقد من
                            اضرار، وذلك مقابل قسط مالي يدفعه المؤمن له حسب ما
                            يتفق عليه بين الطرفين (سنوياً) .
                        </p>
                        <a href="/" class="btn mt-8">قارن الان</a>
                    </div>
                    <img
                        class="staticPage__poster"
                        src="@qariin-fe/src/images/static-pages/vehicles-insurance.svg"
                        alt="main poster"
                    />
                </section>
                <section class="staticPage__features">
                    <div class="staticPage__feature">
                        <h2 class="staticPage__featureTitle">
                            تأمين الطرف الثالث (ضد الغير) التأمين الالزامي:
                        </h2>
                        <p class="text-sm text-secondary mb-2">
                            وهو عباره عن تغطية الاضرار التي تلحق بالسيارة
                            المؤمنة وممتلكات الغير نتيجة وقوع حادث.
                        </p>

                        <h2 class="text-lg mb-3 text-black font-bold">
                            يشمل التغطيات:
                        </h2>
                        <ul class="staticPage__list staticPage__list--twoCols">
                            <div>
                                <li>الخسائر المادية</li>
                                <li>الأضرار الجسدية</li>
                            </div>
                            <li>حالات الوفاة</li>
                        </ul>
                    </div>
                    <div class="staticPage__feature">
                        <h2 class="staticPage__featureTitle">تأمين الشامل:</h2>
                        <p class="text-sm text-secondary mb-2">
                            هو عبارة عن تغطية الأضرار التي تسبب بها المؤمن له أو
                            وقعت عليه سواء كانت للمؤمن له أو للطرف .الثالث نتيجة
                            وقوع حادث
                        </p>

                        <h2 class="text-lg mb-3 text-black font-bold">
                            يشمل التغطيات:
                        </h2>
                        <ul class="staticPage__list staticPage__list--twoCols">
                            <div>
                                <li>المسؤولية تجاه الغير</li>
                                <li>إصلاح السيارة.</li>
                            </div>
                            <div>
                                <li>الكوارث الطبيعية.</li>
                                <li>سرقة السيارات.</li>
                            </div>
                        </ul>
                    </div>
                    <div
                        class="staticPage__feature staticPage__feature--fullWidth"
                    >
                        <h2 class="staticPage__featureTitle">
                            تغطيات يمكن إضافتها لتأمين الطرف الثالث
                        </h2>

                        <ul
                            class="staticPage__list staticPage__list--twoCols staticPage__list--twoCols-lg"
                        >
                            <div>
                                <li>إضافة سائق.</li>
                                <li>تغطية أوسع للحدود الجغرافية.</li>
                                <li>استبدال المركبة.</li>
                                <li>وقت التصليح.</li>
                                <li>تغطية الحريق.</li>
                                <li>تغطية البَرَد والفيضانات.</li>
                            </div>
                            <div>
                                <li>مصاريف سحب المركبة.</li>
                                <li>تغطية انكسار الزجاج.</li>
                                <li>السرقة.</li>
                                <li>تغطية الحوادث الشخصية للسائق.</li>
                                <li>تغطية الحوادث الشخصية للركاب.</li>
                                <li>تغطية الايذاء العمدي.</li>
                            </div>
                        </ul>
                    </div>
                    <div
                        class="staticPage__feature staticPage__feature--fullWidth"
                    >
                        <h2 class="staticPage__featureTitle">
                            تغطيات يمكنك إضافتها لوثيقة التأمين الشامل
                        </h2>

                        <ul
                            class="staticPage__list staticPage__list--twoCols staticPage__list--twoCols-lg"
                        >
                            <div>
                                <li>تغطية الحوادث الشخصية للسائق.</li>
                                <li>المقتنيات الشخصية (داخل السيارة).</li>
                                <li>تغطية الحوادث الشخصية للركاب.</li>
                                <li>
                                    تغطية مجانية لسيارات الدفع الرباعي على الطرق
                                    الغير معبده.
                                </li>
                                <li>امتداد جغرافي للتغطية.</li>
                            </div>
                            <div>
                                <li>تغطية ايجار سيارة بديلة.</li>
                                <li>تغطية ضياع مفتاح السيارة أو السرقة.</li>
                                <li>تغطية للزجاج.</li>
                                <li>تغطية كرسي الأطفال.</li>
                                <li>مصاريف سحب سيارة.</li>
                            </div>
                        </ul>
                    </div>
                </section>
            </div>
        </main>
    </app-layout>
</template>

<script>
import AppLayout from "@/Layouts/AppLayout.vue";

export default {
    name: "VehicleInsurance",
    components: {
        AppLayout,
    },
};
</script>
