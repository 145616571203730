require("./bootstrap");

import { createApp, h } from "vue";
import { createInertiaApp } from "@inertiajs/inertia-vue3";
import { InertiaProgress } from "@inertiajs/progress";
import Pusher from "pusher-js";
// import pdf from 'vue-pdf';
import { i18nVue } from 'laravel-vue-i18n'

const appName =
    window.document.getElementsByTagName("title")[0]?.innerText || "Laravel";

createInertiaApp({
    resolve: (name) => require(`./Pages/${name}.vue`),
    setup({ el, app, props, plugin }) {
        const root = createApp({ render: () => h(app, props) })
            .use(plugin)
            .use(i18nVue, {
                resolve: lang => {
                    props.$activeLanguage = lang;
                    return import(`../lang/${lang}.json`)
                },
            })
            .mixin({ methods: { route } })
            .mount(el)
        
        return root;
    },
});

InertiaProgress.init({ color: "#4B5563" });

// Enable pusher logging - don't include this in production
Pusher.logToConsole = true;
