<template>
    <jet-form-section @submitted="updatePassword">
        <template #title> تحديث كلمه المرور </template>

        <template #description> تأكد من استخدام كلمة مرور عشوائية </template>

        <template #form>
            <fieldset
                class="input col-span-6 sm:col-span-4"
                :style="`--icon: url('${
                    require('@qariin-fe/src/images/icons/password.png').default
                }')`"
            >
                <jet-label for="old-password">كلمة مرور الحاليه</jet-label>
                <jet-input
                    type="password"
                    id="old-password"
                    required
                    autocomplete
                    name="old-password"
                    placeholder="اكتب كلمة المرور هنا..."
                    v-model="form.current_password"
                />
                <jet-input-error
                    :message="form.errors.current_password"
                    class="mt-2"
                />
            </fieldset>
            <fieldset
                class="input col-span-6 sm:col-span-4"
                :style="`--icon: url('${
                    require('@qariin-fe/src/images/icons/password.png').default
                }')`"
            >
                <jet-label for="new-password">كلمة مرور الجديدة</jet-label>
                <jet-input
                    type="password"
                    id="new-password"
                    required
                    autocomplete
                    name="new-password"
                    placeholder="اكتب كلمة المرور هنا..."
                    v-model="form.password"
                    @keyup="checkPassword"
                    @focus="passwordActive = true"
                    @blur="blurPassword"
                />
                <jet-input-error :message="form.errors.password" class="mt-2" />
            </fieldset>

            <div class="col-span-6 sm:col-span-4">
                <ul :class="{ active: passwordActive }">
                    <li :class="{ is_valid: contains_eight_characters }">
                        يحتوي على 8 حروف
                    </li>
                    <li :class="{ is_valid: contains_number }">
                        يحتوي على أرقام
                    </li>
                    <li :class="{ is_valid: contains_uppercase }">
                        يحتوي على أحرف كبيرة
                    </li>
                    <li :class="{ is_valid: contains_special_character }">
                        يحتوي على حرف خاص
                    </li>
                </ul>
            </div>
            

            <fieldset
                class="input password col-span-6 sm:col-span-4"
                :style="`--icon: url('${
                    require('@qariin-fe/src/images/icons/password.png').default
                }')`"
            >
                <jet-label for="confirm-password"
                    >اعد كلمة المرور الجديدة</jet-label
                >
                <jet-input
                    type="password"
                    id="confirm-password"
                    required
                    autocomplete
                    name="confirm-password"
                    placeholder="اكتب كلمة المرور هنا..."
                    v-model="form.password_confirmation"
                />
                <jet-input-error
                    :message="form.errors.password_confirmation"
                    class="mt-2"
                />
            </fieldset>
        </template>

        <template #actions>
            <jet-action-message :on="form.recentlySuccessful" class="mr-3">
                تم الحفظ
            </jet-action-message>

            <jet-button
                class="bg-primary text-white rounded-md px-md"
                :class="{ 'opacity-25': form.processing }"
                :disabled="form.processing"
            >
                حفظ
            </jet-button>
        </template>
    </jet-form-section>
</template>

<script>
import { defineComponent } from "vue";
import JetActionMessage from "@/Jetstream/ActionMessage.vue";
import JetButton from "@/Jetstream/Button.vue";
import JetFormSection from "@/Jetstream/FormSection.vue";
import JetInput from "@/Jetstream/Input.vue";
import JetInputError from "@/Jetstream/InputError.vue";
import JetLabel from "@/Jetstream/Label.vue";

export default defineComponent({
    components: {
        JetActionMessage,
        JetButton,
        JetFormSection,
        JetInput,
        JetInputError,
        JetLabel,
    },

    data() {
        return {
            form: this.$inertia.form({
                current_password: "",
                password: "",
                password_confirmation: "",
            }),
            passwordActive: false,
            password_length: 0,
            contains_eight_characters: false,
            contains_number: false,
            contains_uppercase: false,
            contains_special_character: false,
            valid_password: false,
        };
    },

    methods: {
        updatePassword() {
            this.form.put(route("user-password.update"), {
                errorBag: "updatePassword",
                preserveScroll: true,
                onSuccess: () => this.form.reset(),
                onError: () => {
                    if (this.form.errors.password) {
                        this.form.reset("password", "password_confirmation");
                        this.$refs.password.focus();
                    }

                    if (this.form.errors.current_password) {
                        this.form.reset("current_password");
                        this.$refs.current_password.focus();
                    }
                },
            });
        },
        blurPassword() {
            if (!this.valid_password) this.passwordActive = false
        },
        checkPassword() {
            this.password_length = this.form.password.length;
            const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

            if (this.password_length >= 8) {
                this.contains_eight_characters = true;
            } else {
                this.contains_eight_characters = false;
            }

            this.contains_number = /\d/.test(this.form.password);
            this.contains_uppercase = /[A-Z]/.test(this.form.password);
            this.contains_special_character = format.test(this.form.password);

            if (this.contains_eight_characters === true &&
                this.contains_special_character === true &&
                this.contains_uppercase === true &&
                this.contains_number === true) {
                this.valid_password = true;
            } else {
                this.valid_password = false;
            }
        }
    },
});
</script>

<style scoped>
ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
    height: 0;
    transition: height 0.5s ease;
}

ul.active {
    height: 92px;
}

li {
    color: #525f7f;
    position: relative;
    font-size: 12px;
}

li:before {
    content: "";
    width: 0%;
    height: 2px;
    background: #2ecc71;
    position: absolute;
    left: 0;
    top: 50%;
    display: block;
    transition: all .6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.is_valid {
    color: rgba(136, 152, 170, 0.8);
}

.is_valid:before {
    width: 100%;
}
</style>
