<template>
    <section v-if="open" class="modal" @click="toggleModal">
        <div class="container relative">
            <div
                id="add-more-info"
                class="modal__content"
                :class="{ wide: wide, 'modal__content--md': small }"
                @click.stop
            >
                <button class="modal__content__close" @click="toggleModal">
                    &times;
                </button>
                <h2 class="text-xl mt-8 mb-4 text-black border-b pb-xs">
                    {{ title }}
                </h2>
                <div>
                    <slot />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
    name: "Modal",
    props: {
        title: {
            type: String,
            default: "Modal Title",
            required: true,
        },
        wide: {
            type: Boolean,
            default: false,
        },
        small: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            open: false,
        };
    },
    computed: {},
    methods: {
        toggleModal() {
            this.open = !this.open;
            if (this.open)
                document.querySelector("body").classList.add("remove-scroll");
            else
                document
                    .querySelector("body")
                    .classList.remove("remove-scroll");
        },
    },
});
</script>
