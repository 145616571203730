<template>
    <app-layout>
        <main class="staticPage staticPage--no-bg">
            <div class="container">
                <h1 class="text-xl lg:text-3xl text-black mt-16">
                    التأمين البحري
                    <p>بضائع - عقد مفتوح</p>
                </h1>
                <section class="staticPage__info">
                    <div class="mt-8">
                        <p class="text-secondary max-w-md">
                            تغطي هذه الوثيقة المؤمن له طبقا لشروط مجمع .مكتتبي
                            التأمين ب لندن حسب ما تقتضيه الوثيقة.
                        </p>
                        <a href="/" class="btn mt-8">قارن الان</a>
                    </div>
                    <img
                        class="staticPage__poster"
                        src="@qariin-fe/src/images/static-pages/marine-insurance-open.png"
                        srcset="
                            @qariin-fe/src/images/static-pages/marine-insurance-open.png    400w,
                            @qariin-fe/src/images/static-pages/marine-insurance-open@2x.png 800w
                        "
                        sizes="(max-width: 678px) 400px, 800px"
                        alt="main poster"
                    />
                </section>
            </div>
        </main>
    </app-layout>
</template>

<script>
import AppLayout from "@/Layouts/AppLayout.vue";

export default {
    name: "MarineInsuranceOpen",
    components: {
        AppLayout,
    },
};
</script>
