<template>
    <Modal wide ref="modal" title="جدول استحقاق الخصم">
        <div class="table_container">
            <table>
                <thead>
                    <tr>
                        <th>عدد السنين</th>
                        <th>نسبه الخصم للتأمين ضد الغير</th>
                        <th>نسبة الخصم للتأمين الشامل</th>
                        <th>نسبة الخصم بعد مطالبة واحدة للتامين ضد الغير</th>
                        <th>نسبة الخصم بعد مطالبة واحدة للتامين الشامل</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="row in data"
                        :key="row.id"
                        :class="{ active: row.applied }"
                    >
                        <td>
                            {{ row.years }}
                        </td>
                        <td>{{ row.tpl_ncd_scale }}%</td>
                        <td>{{ row.company_ncd_scale }}%</td>
                        <td>{{ row.percent_after_one_demand_in_tpl || 0 }}%</td>
                        <td>
                            {{
                                row.percent_after_one_demand_in_comprehensive ||
                                0
                            }}%
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </Modal>
</template>

<script>
import { defineComponent } from "vue";
import Modal from "@/components/Modal";

export default defineComponent({
    name: "NCDTableModal",
    components: {
        Modal,
    },
    props: {
        data: {
            type: Array,
            required: true,
            default: () => [],
        },
    },
    data() {
        return {
            open: false,
        };
    },
    computed: {},
    methods: {
        toggleModal() {
            this.$refs.modal.toggleModal();
        },
    },
});
</script>
