<template>
    <app-layout>
        <main class="staticPage staticPage--no-bg">
            <div class="container">
                <h1 class="text-xl lg:text-3xl text-black mt-16">
                    تأمين المحلات التجارية
                </h1>
                <section class="staticPage__info">
                    <div class="mt-8">
                        <p class="text-secondary max-w-md">
                            يوفر تغطية شاملة لجميع انواع المحلات التجارية
                            بإستثناء بعض المحلات المحددة في وثيقة التأمين وتتضمن
                            التغطية بموجب الوثيقة تغطيات قياسية ضد الحريق
                            والصواعق ، والسرقة بالاضافة الى امكانية التعويض عن
                            ايجار وحدة بديلة في حالة تعرض المحل التجاري لأضرار
                            او خسائر حسب ما تقضيه الوثيقة.
                        </p>
                        <a href="/" class="btn mt-8">قارن الان</a>
                    </div>
                    <img
                        class="staticPage__poster"
                        src="@qariin-fe/src/images/static-pages/shop.png"
                        srcset="
                            @qariin-fe/src/images/static-pages/shop.png    400w,
                            @qariin-fe/src/images/static-pages/shop@2x.png 800w
                        "
                        sizes="(max-width: 678px) 400px, 800px"
                        alt="main poster"
                    />
                </section>
            </div>
        </main>
    </app-layout>
</template>

<script>
import AppLayout from "@/Layouts/AppLayout.vue";

export default {
    name: "AllRisksInsuranceAndProperty",
    components: {
        AppLayout,
    },
};
</script>
