<template>
    <app-layout>
        <ErrorsAlert
            v-if="errorMessages && errorMessages.length"
            :errors="errorMessages"
        />
        <main class="staticPage staticPage--no-bg">
            <div class="container">
                <h1 class="text-xl lg:text-3xl text-black mt-16">
                    طلب الغاء وثيقة
                </h1>
                <section class="staticPage__info">
                    <div class="mt-8">
                        <p class="text-secondary max-w-md">
                            برجاء تحميل الملف المرفق ادناه و ملء البيانات وإعادة
                            رفعة و ارسالة الينا
                        </p>
                        <a
                            :href="route('download.cancellation.request')"
                            class="otherInfo__addons__button otherInfo__addons__button--hidden otherInfo__addons__button--download"
                        >
                            <span>تحميل طلب الغاء وثيقة</span>
                            <span class="icon">
                                <img
                                    class="w-full mt-0.5"
                                    src="@qariin-fe/src/images/icons/download.svg"
                                    alt="Download"
                                />
                            </span>
                        </a>
                        <form
                            v-if="!isLoading"
                            ref="form"
                            :action="route('submit.tickets.attachment')"
                            method="post"
                            enctype="multipart/form-data"
                            class="w-full flex flex-col items-start justify-center"
                        >
                            <label
                                for="cancel-policy"
                                class="flex items-center justify-center w-64 h-28 my-6 ms-3 px-xs relative cursor-pointer border border-dashed border-2 border-primary-400 rounded-lg"
                            >
                                <div
                                    class="absolute -top-3 -right-3 w-10 h-10 rounded-full bg-primary p-2"
                                >
                                    <img
                                        src="@qariin-fe/src/images/icons/upload.svg"
                                        alt="upload"
                                    />
                                </div>
                                <div v-if="input.file" class="flex flex-col items-center justify-center">
                                    <figure class="w-10 h-10 rounded-full bg-primary p-2 mb-3">
                                        <img
                                            src="@qariin-fe/src/images/icons/done.svg"
                                            alt="Done"
                                        />
                                    </figure>
                                    <span class="text-sm text-secondary">
                                        {{ input.file }}
                                    </span>
                                </div>
                                <img
                                    v-else
                                    src="@qariin-fe/src/images/icons/upload-primary.svg"
                                    alt="Upload file"
                                    class="w-28"
                                />
                                <input
                                    class="w-0 h-0"
                                    name="file"
                                    id="cancel-policy"
                                    type="file"
                                    required
                                    @change="setFile($event)"
                                />
                            </label>
                            <input type="hidden" name="_token" :value="csrf" />
                            <input
                                type="hidden"
                                name="reason"
                                value="الغاء وثيقة"
                            />
                            <input
                                type="submit"
                                class="bg-primary text-white rounded-md cursor-pointer px-24 py-2 ms-2"
                                @click.prevent="submit"
                                :class="{ disabled: !input.file }"
                                value="ارسال"
                            />
                        </form>
                        <div
                            v-else
                            class="flex items-center justify-center my-1"
                        >
                            <VueLottiePlayer
                                style="width: 200px; height: 100px"
                                name="Qariin loader"
                                loop
                                path="https://assets9.lottiefiles.com/private_files/lf30_1xgata3z.json"
                            />
                        </div>
                    </div>
                    <img
                        class="staticPage__poster"
                        src="@qariin-fe/src/images/cancel-policy.png"
                        srcset="
                            @qariin-fe/src/images/cancel-policy.png    400w,
                            @qariin-fe/src/images/cancel-policy@2x.png 800w
                        "
                        alt="main poster"
                    />
                </section>
            </div>
        </main>
    </app-layout>
</template>

<script>
import AppLayout from "@/Layouts/AppLayout.vue";
import VueLottiePlayer from "vue-lottie-player";
import ErrorsAlert from "@/components/ErrorsAlert.vue";

export default {
    name: "CancelPolicy",
    components: {
        AppLayout,
        VueLottiePlayer,
        ErrorsAlert,
    },
    props: {
        csrf: {
            type: String,
            required: true,
            default: "",
        },
        // eslint-disable-next-line vue/prop-name-casing
        general_errors: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            isLoading: false,
            input: {
                file: null,
            },
        };
    },
    computed: {
        errorMessages() {
            if (!this.general_errors) return;

            var errors = [];
            Object.keys(this.general_errors).forEach((key) => {
                errors.push(this.general_errors[key][0]);
            });

            return errors;
        },
    },
    methods: {
        setFile(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;

            this.input.file = files[0].name;
        },
        submit() {
            if (!this.input.file) return;
            this.isLoading = true;

            this.$refs.form.submit();
        },
    },
};
</script>

<style></style>
