<template>
    <app-layout title="Register">
        <Head>
            <title>Register | Qariin</title>
        </Head>
        <jet-authentication-card>
            <jet-validation-errors class="mb-4" />

            <div class="container flex justify-center mt-16 text-center">
                <form @submit.prevent="submit">
                    <h1 class="text-2xl lg:text-3xl">مستخدم جديد</h1>
                    <p>
                        <span class="text-secondary-500 text-xs"
                            >لديك حساب ؟ قم بالدخول إلى حسابك</span
                        >
                        <a :href="route('login')" class="text-primary-500 ms-1"
                            >تسجيل دخول</a
                        >
                    </p>
                    <fieldset class="input email">
                        <jet-label for="email" value="البريد الالكتروني" />
                        <jet-input
                            type="email"
                            id="email"
                            required
                            name="email"
                            v-model="form.email"
                            placeholder="اكتب البريد الالكتروني هنا..."
                            autofocus
                        />
                    </fieldset>

                    <fieldset class="input">
                        <jet-label for="id" value="رقم الهويه أو الاقامه" />
                        <jet-input
                            type="number"
                            id="id"
                            required
                            name="id"
                            v-model="form.identity_number"
                            placeholder="اكتب رقم الهويه أو الاقامه هنا..."
                        />
                    </fieldset>

                    <ul :class="{active: passwordActive}">
                        <li :class="{ is_valid: contains_eight_characters }">
                            يحتوي على 8 حروف
                        </li>
                        <li :class="{ is_valid: contains_number }">
                            يحتوي على أرقام
                        </li>
                        <li :class="{ is_valid: contains_uppercase }">
                            يحتوي على أحرف كبيرة
                        </li>
                        <li :class="{ is_valid: contains_special_character }">
                            يحتوي على حرف خاص
                        </li>
                    </ul>

                    <fieldset class="input password">
                        <jet-label for="password">كلمة المرور</jet-label>
                        <jet-input
                            type="password"
                            id="password"
                            required
                            autocomplete="new-password"
                            name="email"
                            placeholder="اكتب كلمة المرور هنا..."
                            v-model="form.password"
                            @keyup="checkPassword"
                            @focus="passwordActive = true"
                            @blur="blurPassword"
                        />
                    </fieldset>

                    <jet-button
                        type="submit"
                        class="bg-primary text-white w-64 rounded-md"
                        :class="{ 'opacity-25': !formIsValid }"
                        :disabled="!formIsValid"
                        >مستخدم جديد</jet-button
                    >

                    <fieldset class="w-64 text-end mt-2">
                        <jet-checkbox
                            type="checkbox"
                            id="terms-agreed"
                            name="terms-agreed"
                            v-model:checked="form.terms"
                        />
                        <label for="terms-agreed" class="text-xs font-normal"
                            >بالنقر على مستخدم جديد، فإنك توافق على الشروط
                            والأحكام التي نتبعها وأنك قرأت <a href="/privacy-and-policy" class="inline p-0 m-0 min-h-full font-semibold underline" target="_blank" @click.stop>سياسة الخصوصية</a>. ,و
                            تقر أن جميع البيانات المدخلة صحيحة و أتحمل مسؤولية
                            صحتها.</label
                        >
                    </fieldset>
                </form>
            </div>
        </jet-authentication-card>
    </app-layout>
</template>

<script>
import { Head } from '@inertiajs/inertia-vue3'
import { defineComponent } from "vue";
import JetAuthenticationCard from "@/Jetstream/AuthenticationCard.vue";
// import JetAuthenticationCardLogo from "@/Jetstream/AuthenticationCardLogo.vue";
import JetButton from "@/Jetstream/Button.vue";
import JetInput from "@/Jetstream/Input.vue";
import JetCheckbox from "@/Jetstream/Checkbox.vue";
import JetLabel from "@/Jetstream/Label.vue";
import JetValidationErrors from "@/Jetstream/ValidationErrors.vue";
// import { Head, Link } from "@inertiajs/inertia-vue3";
import AppLayout from "@/Layouts/AppLayout.vue";

export default defineComponent({
    components: {
        AppLayout,
        Head,
        JetAuthenticationCard,
        JetButton,
        JetInput,
        JetCheckbox,
        JetLabel,
        JetValidationErrors,
    },

    data() {
        return {
            form: this.$inertia.form({
                name: null,
                email: null,
                password: null,
                identity_number: null,
                terms: false,
            }),
            passwordActive: false,
            password_length: 0,
            contains_eight_characters: false,
            contains_number: false,
            contains_uppercase: false,
            contains_special_character: false,
            valid_password: false,
        };
    },

    computed: {
        formIsValid() {
            return this.form.email
             && this.form.password
             && this.form.identity_number
             && this.valid_password
             && this.form.terms
        }
    },

    methods: {
        submit() {
            this.form.post(this.route("register"), {
                // onFinish: () =>
                //     this.form..reset("password"),
            });
        },
        blurPassword() {
            if (!this.valid_password) this.passwordActive = false
        },
        checkPassword() {
            this.password_length = this.form.password.length;
            const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

            if (this.password_length >= 8) {
                this.contains_eight_characters = true;
            } else {
                this.contains_eight_characters = false;
            }

            this.contains_number = /\d/.test(this.form.password);
            this.contains_uppercase = /[A-Z]/.test(this.form.password);
            this.contains_special_character = format.test(this.form.password);

            if (this.contains_eight_characters === true &&
                this.contains_special_character === true &&
                this.contains_uppercase === true &&
                this.contains_number === true) {
                this.valid_password = true;
            } else {
                this.valid_password = false;
            }
        }
    },
    mounted() {
        localStorage.setItem('otp_reason', 'register-otp');
    }
});
</script>

<style scoped>
ul {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
    overflow: hidden;
    height: 0;
    transition: height 0.5s ease;
}

ul.active {
    height: 92px;
}

li {
	color: #525f7f;
	position: relative;
    font-size: 12px;
}

li:before {
  content: "";
	width: 0%; height: 2px;
	background: #2ecc71;
	position: absolute;
	left: 0; top: 50%;
	display: block;
	transition: all .6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.is_valid { color: rgba(136, 152, 170, 0.8); }
.is_valid:before { width: 100%; }

</style>
