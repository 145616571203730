<template>
    <app-layout title="Reset Password">
        <jet-authentication-card>
            <jet-validation-errors class="mb-4" />

            <div class="container flex justify-center mt-16 text-center">
                <form @submit.prevent="submit">
                    <h1 class="text-2xl lg:text-3xl mb-3">
                        إعادة تعيين كلمة المرور
                    </h1>

                    <fieldset class="input input-w-auto password">
                        <jet-label for="password">كلمة مرور الجديدة</jet-label>
                        <jet-input
                            type="password"
                            id="password"
                            required
                            autocomplete="new-password"
                            name="email"
                            placeholder="اكتب كلمة المرور هنا..."
                            v-model="form.password"
                        />
                    </fieldset>

                    <fieldset class="input input-w-auto password">
                        <jet-label for="password"
                            >اعد كتابة كلمة المرور الجديدة</jet-label
                        >
                        <jet-input
                            type="password"
                            required
                            autocomplete="new-password"
                            name="password_confirmation"
                            placeholder="اكتب كلمة المرور هنا..."
                            v-model="form.password_confirmation"
                        />
                    </fieldset>

                    <jet-button
                        type="submit"
                        class="bg-primary text-white w-64 rounded-md"
                        :class="{ 'opacity-25': form.processing }"
                        :disabled="form.processing"
                        >تعديل</jet-button
                    >
                </form>
            </div>
        </jet-authentication-card>
    </app-layout>
</template>

<script>
import { defineComponent } from "vue";
import JetAuthenticationCard from "@/Jetstream/AuthenticationCard.vue";
import JetButton from "@/Jetstream/Button.vue";
import JetInput from "@/Jetstream/Input.vue";
import JetLabel from "@/Jetstream/Label.vue";
import JetValidationErrors from "@/Jetstream/ValidationErrors.vue";
import AppLayout from "@/Layouts/AppLayout.vue";

export default defineComponent({
    components: {
        AppLayout,
        JetAuthenticationCard,
        JetButton,
        JetInput,
        JetLabel,
        JetValidationErrors,
    },

    props: {
        email: String,
        token: String,
    },

    data() {
        return {
            form: this.$inertia.form({
                token: this.token,
                email: this.email,
                password: "",
                password_confirmation: "",
            }),
        };
    },

    methods: {
        submit() {
            this.form.post(this.route("password.update"), {
                onFinish: () =>
                    this.form.reset("password", "password_confirmation"),
            });
        },
    },
});
</script>
