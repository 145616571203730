<template>
    <app-layout title="Home">
        <Head>
            <title>Welcome | Qariin</title>
        </Head>
        <ErrorsAlert
            v-if="errorMessages && errorMessages.length"
            :errors="errorMessages"
        />
        <section class="homepage__categories">
            <div class="container">
                <ul class="homepage__categories__main">
                    <li class="homepage__categories__category">
                        <a href="#" class="active">
                            {{ $t('header_services_motor') }}
                        </a>
                    </li>
                    <li class="homepage__categories__category">
                        <a href="/soon">
                            {{ $t('header_services_medical') }}
                        </a>
                    </li>
                    <li class="generalInsurance dropdown">
                        <a
                            href="/soon"
                            class="whitespace-nowrap text-secondary-500 whitespace-nowrap text-sm lg:text-base"
                            >
                            {{ $t('header_services_general') }}
                        </a>
                        <!-- <img
                            class="w-3 h-3 ms-2"
                            src="@qariin-fe/src/images/svg/arrow-down.svg"
                            alt="arrow-down"
                        /> 
                        <ul
                            class="dropdown__generalInsurances grid grid-cols-2 gap-4"
                        >
                            <li>
                                <a href="/travel-care-insurance"
                                    >تأمين رعاية السفر</a
                                >
                            </li>
                            <li>
                                <a href="/marine-insurance"
                                    >التأمين البحري – بضائع – شحنة فردية</a
                                >
                            </li>
                            <li>
                                <a href="/breach-of-trust-insurance"
                                    >تأمين خيانة الأمانة</a
                                >
                            </li>
                            <li>
                                <a href="/marine-insurance-open"
                                    >التأمين البحري – بضائع – عقد مفتوح</a
                                >
                            </li>
                            <li>
                                <a href="/medical-malpractice-insurance"
                                    >تأمين اخطاء ممارسة المهن الطبية</a
                                >
                            </li>
                            <li>
                                <a href="/fire-insurance">تأمين الحريق</a>
                            </li>
                            <li>
                                <a href="/workers-injury-insurance"
                                    >تأمين اصابة العمال</a
                                >
                            </li>
                            <li>
                                <a href="/soon">تأمين المسكن</a>
                            </li>
                            <li>
                                <a href="/domestic-labor-insurance"
                                    >تأمين حماية عقود العمالة المنزلية</a
                                >
                            </li>
                            <li>
                                <a href="/all-risks-insurance-and-property"
                                    >تأمين جميع الاخطار – الممتلكات</a
                                >
                            </li>
                            <li>
                                <a href="/land-transport-insurance-for-goods"
                                    >تأمين النقل البري للبضائع ( اخطار الطريق
                                    )</a
                                >
                            </li>
                            <li>
                                <a href="/shop-insurance"
                                    >تأمين المحلات التجارية</a
                                >
                            </li>
                            <li>
                                <a href="/risks-of-land-transport"
                                    >تأمين جميع اخطار النقل البري</a
                                >
                            </li>
                            <li>
                                <a href="/soon">تأمين الطاقة</a>
                            </li>
                        </ul> -->
                    </li>
                </ul>
            </div>
        </section>

        <section class="homepage__landing mb-6">
            <div class="container">
                <article
                    class="grid grid-cols-1 lg:grid-cols-2 gap-x-6 gap-y-6"
                >
                    <div class="homepage__content">
                        <h1
                            class="max-w-md text-2xl lg:text-4xl lg:text-3xl font-semibold text-black"
                        >
                            {{ $t('home_compare') }}
                        </h1>

                        <form
                            ref="basicForm"
                            action="#"
                            class="grid grid-cols-1 md:grid-cols-2 gap-4 text-secondary-900"
                        >
                            <div>
                                <p class="text-secondary-400 text-sm mb-2 mt-5">
                                    {{ $t('home_insurance_type') }}
                                </p>
                                <div
                                    class="flex items-center justify-start mt-1 mb-4"
                                >
                                    <button
                                        type="button"
                                        :class="{
                                            'bg-primary text-white':
                                                input.insuranceCategory ==
                                                'persons',
                                            'bg-secondary-100 text-secondary-400':
                                                input.insuranceCategory !=
                                                'persons',
                                        }"
                                        class="rounded-ts-md rounded-bs-md min-h-full py-2 px-8 text-sm w-1/2 whitespace-nowrap"
                                        @click="
                                            toggleSearch(
                                                'insuranceCategory',
                                                'persons',
                                                [
                                                    'commercial_registration_number',
                                                ]
                                            )
                                        "
                                    >
                                        {{ $t('home_individuals') }}
                                    </button>
                                    <button
                                        type="button"
                                        class="rounded-te-md rounded-be-md min-h-full py-2 px-8 text-sm whitespace-nowrap w-1/2"
                                        :class="{
                                            'bg-primary text-white':
                                                input.insuranceCategory ==
                                                'companies',
                                            'bg-secondary-100 text-secondary-400':
                                                input.insuranceCategory !=
                                                'companies',
                                        }"
                                        @click="
                                            toggleSearch(
                                                'insuranceCategory',
                                                'companies',
                                                []
                                            )
                                        "
                                    >
                                        {{ $t('home_facilities') }}
                                    </button>
                                </div>
                                <p class="text-secondary-400 text-sm my-2">
                                    {{ $t('home_insurance_purpose') }}
                                </p>
                                <div
                                    class="flex items-center justify-start mt-1 mb-4"
                                >
                                    <button
                                        type="button"
                                        :class="{
                                            'bg-primary text-white':
                                                input.insuranceType == 'new',
                                            'bg-secondary-100 text-secondary-400':
                                                input.insuranceType != 'new',
                                            'cursor-not-allowed opacity-25': disableNewInsurance,
                                        }"
                                        class="rounded-ts-md rounded-bs-md min-h-full py-2 px-8 text-sm w-1/2 whitespace-nowrap"
                                        @click="
                                            toggleSearch(
                                                'insuranceType',
                                                'new',
                                                ['buyer_identity'],
                                                disableNewInsurance,
                                            )
                                        "
                                    >
                                        {{ $t('home_new_insurance') }}
                                    </button>
                                    <button
                                        type="button"
                                        class="rounded-te-md rounded-be-md min-h-full py-2 px-8 text-sm whitespace-nowrap w-1/2"
                                        :class="{
                                            'bg-primary text-white':
                                                input.insuranceType ==
                                                'transfer',
                                            'bg-secondary-100 text-secondary-400':
                                                input.insuranceType !=
                                                'transfer',
                                            'cursor-not-allowed opacity-25': disableTransferOwnership,
                                        }"
                                        @click="
                                            toggleSearch(
                                                'insuranceType',
                                                'transfer',
                                                [],
                                                false,
                                                disableTransferOwnership
                                            )
                                        "
                                    >
                                        {{  $t('home_transfer_ownership')}}
                                    </button>
                                </div>
                                <div
                                    v-if="input.insuranceType === 'new'"
                                    class="mt-2"
                                >
                                    <fieldset
                                        class="input input-w-auto email"
                                        :style="`--icon: url('${
                                            require('@qariin-fe/src/images/input-icons/identity.svg')
                                                .default
                                        }');`"
                                    >
                                        <label
                                            v-if="input.insuranceCategory =='persons'"
                                            for="id-number"
                                        >
                                            {{ $t('home_id_number') }}
                                        </label>
                                        <label v-else for="id-number">
                                            {{ $t('home_unified_number') }}
                                        </label>
                                        <input
                                            v-model="input.identity_number"
                                            type="number"
                                            id="id-number"
                                            required
                                            name="id-number"
                                            :placeholder="$t('home_id_number_placeholder')"
                                            maxlength="10"
                                            @input="
                                                validateLength(
                                                    $event,
                                                    'identity_number'
                                                )
                                            "
                                        />
                                    </fieldset>
                                </div>
                                <div v-else class="mt-2">
                                    <fieldset
                                        class="input input-w-auto email"
                                        :style="`--icon: url('${
                                            require('@qariin-fe/src/images/input-icons/identity.svg')
                                                .default
                                        }');`"
                                    >
                                        <label
                                            v-if="input.insuranceCategory != 'companies'"
                                            for="seller-id"
                                        >
                                            {{ $t('home_seller_id') }}
                                        </label>
                                        <label v-else for="id-number">
                                            {{ $t('home_unified_number') }}
                                        </label>
                                        <input
                                            v-model="input.identity_number"
                                            type="number"
                                            id="seller-id"
                                            required
                                            name="seller-id"
                                            :placeholder="$t('home_id_number_placeholder')"
                                            maxlength="10"
                                            @input="
                                                validateLength(
                                                    $event,
                                                    'identity_number'
                                                )
                                            "
                                        />
                                    </fieldset>
                                    <fieldset
                                        class="input input-w-auto email"
                                        :style="`--icon: url('${
                                            require('@qariin-fe/src/images/input-icons/identity.svg')
                                                .default
                                        }');`"
                                    >
                                        <label for="buyer-id">
                                            {{ $t('home_buyer_id_number') }}
                                        </label>
                                        <input
                                            v-model="input.buyer_identity"
                                            type="number"
                                            id="buyer-id"
                                            required
                                            name="buyer-id"
                                            :placeholder="$t('home_id_number_placeholder')"
                                            maxlength="10"
                                            @input="
                                                validateLength(
                                                    $event,
                                                    'buyer_identity'
                                                )
                                            "
                                        />
                                    </fieldset>
                                </div>
                                <fieldset
                                    class="input input-w-auto email"
                                    :class="{
                                        'border-danger': !dateOfInsurance,
                                    }"
                                    :style="`--icon: url('${
                                        require('@qariin-fe/src/images/input-icons/calendar.svg')
                                            .default
                                    }');`"
                                    @click.stop="$refs.insuranceStartDate.showPicker()"
                                >
                                    <label for="insurance-date">
                                        {{ $t('home_insurance_start_date') }}
                                    </label>
                                    <div class="flex items-center">
                                        <select
                                            v-model="input.start_date_dd"
                                            class="date_select text-sm"
                                        >
                                            <option value="null" disabled>
                                                {{ $t('day') }} 
                                            </option>
                                            <option
                                                v-for="day in 31"
                                                :key="day"
                                                :value="
                                                    day < 10 ? `0${day}` : day
                                                "
                                            >
                                                {{ day }}
                                            </option>
                                        </select>
                                        <span> - </span>
                                        <select
                                            v-model="input.start_date_mm"
                                            class="date_select text-sm"
                                        >
                                            <option value="null" disabled>
                                                {{ $t('month') }}
                                            </option>
                                            <option
                                                v-for="month in 12"
                                                :key="month"
                                                :value="
                                                    month < 10
                                                        ? `0${month}`
                                                        : month
                                                "
                                            >
                                                {{ month }}
                                            </option>
                                        </select>
                                        <span> - </span>
                                        <select
                                            v-model="input.start_date_yy"
                                            class="date_select text-sm"
                                        >
                                            <option value="null" disabled>
                                                {{ $t('year') }}
                                            </option>
                                            <option
                                                v-for="year in validInsuranceYears"
                                                :key="year"
                                                :value="year"
                                            >
                                                {{ year }}
                                            </option>
                                        </select>
                                    </div>
                                    <span
                                        v-if="startDateFailed"
                                        class="text-danger text-xs pt-1"
                                    >
                                        {{ $t('home_insurance_start_date_error') }}
                                    </span>
                                </fieldset>
                                <fieldset
                                    v-if="!hijri"
                                    class="input input-w-auto email"
                                    :class="{ 'border-danger': !dateOfBirth }"
                                    :style="`--icon: url('${
                                        require('@qariin-fe/src/images/input-icons/calendar.svg')
                                            .default
                                    }');`"
                                >
                                    <label for="insurance-date">
                                        {{ $t('birth_date') }}
                                    </label>
                                    <div class="flex items-center">
                                        <select
                                            v-model="input.date_of_birth_dd"
                                            class="date_select text-sm"
                                        >
                                            <option value="null" disabled>
                                                {{ $t('day') }}
                                            </option>
                                            <option
                                                v-for="day in 31"
                                                :key="day"
                                                :value="day"
                                            >
                                                {{ day }}
                                            </option>
                                        </select>
                                        <span> - </span>
                                        <select
                                            v-model="input.date_of_birth_mm"
                                            class="date_select text-sm"
                                        >
                                            <option value="null" disabled>
                                                {{ $t('month') }}
                                            </option>
                                            <option
                                                v-for="month in 12"
                                                :key="month"
                                                :value="month"
                                            >
                                                {{ month }}
                                            </option>
                                        </select>
                                        <span> - </span>
                                        <select
                                            v-model="input.date_of_birth_yy"
                                            class="date_select text-sm"
                                        >
                                            <option value="null" disabled>
                                                {{ $t('year') }}
                                            </option>
                                            <option
                                                v-for="year in 100"
                                                :key="year"
                                                :value="
                                                    new Date().getFullYear() -
                                                    year
                                                "
                                            >
                                                {{
                                                    new Date().getFullYear() -
                                                    year
                                                }}
                                            </option>
                                        </select>
                                    </div>
                                </fieldset>
                                <fieldset
                                    v-else
                                    class="input input-w-auto email"
                                    :class="{
                                        'border-danger': !hijriDateOfBirth,
                                    }"
                                    :style="`--icon: url('${
                                        require('@qariin-fe/src/images/input-icons/calendar.svg')
                                            .default
                                    }');`"
                                >
                                    <label for="insurance-date">
                                        {{ $t('hijri_birth_date') }}
                                    </label>
                                    <div class="flex items-center">
                                        <select
                                            v-model="input.date_of_birth_dd"
                                            class="date_select text-sm"
                                        >
                                            <option value="null" disabled>
                                                {{ $t('day') }}
                                            </option>
                                            <option
                                                v-for="day in 30"
                                                :key="day"
                                                :value="day"
                                            >
                                                {{ day }}
                                            </option>
                                        </select>
                                        <span> - </span>
                                        <select
                                            v-model="input.date_of_birth_mm"
                                            class="date_select text-sm"
                                        >
                                            <option value="null" disabled>
                                                {{ $t('month') }}
                                            </option>
                                            <option
                                                v-for="month in 12"
                                                :key="month"
                                                :value="month"
                                            >
                                                {{ month }}
                                            </option>
                                        </select>
                                        <span> - </span>
                                        <select
                                            v-model="input.date_of_birth_yy"
                                            class="date_select text-sm"
                                        >
                                            <option value="null" disabled>
                                                {{ $t('year') }}
                                            </option>
                                            <option
                                                v-for="year in 100"
                                                :key="year"
                                                :value="currentHijriYear - year"
                                            >
                                                {{ currentHijriYear - year }}
                                            </option>
                                        </select>
                                    </div>
                                </fieldset>
                            </div>
                            <div class="pt-2">
                                <h3 class="text-base my-2">
                                    <span class="text-secondary-400 text-sm">
                                        {{ $t('home_registration_type') }}
                                    </span>
                                </h3>
                                <div
                                    class="flex items-center justify-start mt-1 mb-4"
                                >
                                    <button
                                        type="button"
                                        :class="{
                                            'bg-primary text-white':
                                                input.licenseType == 'form',
                                            'bg-secondary-100 text-secondary-400':
                                                input.licenseType != 'form',
                                        }"
                                        class="rounded-ts-md rounded-bs-md min-h-full py-2 px-8 text-sm w-1/2"
                                        @click="
                                            toggleSearch(
                                                'licenseType',
                                                'form',
                                                [
                                                    'vehicle_custom_number',
                                                    'vehicle_model_year',
                                                ]
                                            )
                                        "
                                    >
                                        {{ $t('home_registration_type_form') }}
                                    </button>
                                    <button
                                        type="button"
                                        class="rounded-te-md rounded-be-md min-h-full py-2 px-8 text-sm whitespace-nowrap w-1/2"
                                        :class="{
                                            'bg-primary text-white':
                                                input.licenseType == 'customs',
                                            'bg-secondary-100 text-secondary-400':
                                                input.licenseType != 'customs',
                                        }"
                                        @click="
                                            toggleSearch(
                                                'licenseType',
                                                'customs',
                                                ['vehicle_number'],
                                            )
                                        "
                                    >
                                        {{ $t('home_registration_type_customs') }}
                                    </button>
                                </div>
                                <div v-if="input.licenseType === 'form'">
                                    <fieldset class="input input-w-auto">
                                        <label for="vehicle-number">
                                            {{ $t('home_vehicle_serial_number') }}
                                        </label>
                                        <input
                                            v-model="input.vehicle_number"
                                            type="number"
                                            id="vehicle-number"
                                            required
                                            name="vehicle-number"
                                            :placeholder="$t('home_vehicle_serial_number_placeholder')"
                                            maxlength="9"
                                            minlength="8"
                                            @input="
                                                validateLength(
                                                    $event,
                                                    'vehicle_number'
                                                )
                                            "
                                        />
                                    </fieldset>
                                </div>
                                <div v-else>
                                    <fieldset
                                        class="input input-w-auto"
                                        :style="`--icon: url('${
                                            require('@qariin-fe/src/images/input-icons/car.svg')
                                                .default
                                        }');`"
                                    >
                                        <label for="car-model-year">
                                            {{ $t('home_vehicle_model_year') }}
                                        </label>
                                        <input
                                            v-model="input.vehicle_model_year"
                                            type="text"
                                            id="car-model-year"
                                            required
                                            name="car-model-year"
                                            :placeholder="$t('home_vehicle_model_year_placeholder')"
                                        />
                                    </fieldset>
                                    <fieldset class="input input-w-auto">
                                        <label for="customs-value">
                                            {{ $t('home_vehicle_customs_number') }}
                                        </label>
                                        <input
                                            v-model="
                                                input.vehicle_custom_number
                                            "
                                            type="number"
                                            id="customs-value"
                                            required
                                            name="customs-value"
                                            :placeholder="$t('home_vehicle_customs_number_placeholder')"
                                        />
                                    </fieldset>
                                </div>
                                <fieldset
                                    v-if="
                                        input.insuranceCategory == 'companies'
                                    "
                                    class="input input-w-auto"
                                >
                                    <label for="commercial-number">
                                        {{ $t('home_vehicle_commercial_number') }}
                                    </label>
                                    <input
                                        v-model="
                                            input.commercial_registration_number
                                        "
                                        type="number"
                                        id="commercial-number"
                                        required
                                        name="commercial-number"
                                        :placeholder="$t('home_vehicle_commercial_number_placeholder')"
                                    />
                                </fieldset>
                                <fieldset
                                    class="input input-w-auto"
                                    :style="`--icon: url('${
                                        require('@qariin-fe/src/images/input-icons/car.svg')
                                            .default
                                    }');`"
                                >
                                    <label for="vehicle-value">
                                        {{ $t('home_vehicle_value') }}
                                    </label>
                                    <input
                                        v-model="input.vehicle_value"
                                        type="number"
                                        id="vehicle-value"
                                        required
                                        name="vehicle-value"
                                        :placeholder="$t('home_vehicle_value_placeholder')"
                                    />
                                </fieldset>
                            </div>
                            <div>
                                <form>
                                    <template v-if="!input.captchaPassed">
                                        <div
                                            class="flex items-center justify-start max-w-sm"
                                        >
                                            <fieldset
                                                class="relative input input-w-auto"
                                                ref="captcha_field"
                                            >
                                                <label for="code">
                                                    {{ $t('home_verification_code') }}
                                                </label>
                                                <input
                                                    v-model="input.captcha"
                                                    type="text"
                                                    id="code"
                                                    required
                                                    name="code"
                                                    :placeholder="$t('home_verification_code_placeholder')"
                                                />
                                                <button
                                                    type="button"
                                                    class="absolute top-0 -end-1 z-10 text-xs min-h-full w-5"
                                                    @click="refreshCaptcha"
                                                >
                                                    <img
                                                        :src="
                                                            require('@qariin-fe/src/images/input-icons/reload.svg')
                                                                .default
                                                        "
                                                        alt="Reload"
                                                    />
                                                </button>
                                            </fieldset>
                                            <figure
                                                class="ms-3 w-64"
                                                v-html="
                                                    (!captchaRefreshed &&
                                                        captcha) ||
                                                    newCaptcha
                                                "
                                            ></figure>
                                        </div>
                                        <button
                                            class="text-xs border border-primary rounded-md p-1 min-h-full"
                                            @click.prevent="
                                                checkCaptcha($event)
                                            "
                                        >
                                            {{ $t('home_verify_verification_code') }}
                                        </button>
                                    </template>
                                    <p v-else class="text-xs text-tertiary">
                                        {{ $t('home_verify_verification_code_success') }}
                                    </p>
                                    <p
                                        v-if="input.captchaFailed"
                                        class="text-xs text-danger"
                                    >
                                        {{ $t('home_verify_verification_code_failed') }}
                                    </p>
                                </form>
                                <fieldset class="max-w-xs my-3">
                                    <input
                                        v-model="input.terms_agreed"
                                        type="checkbox"
                                        id="terms-agreed"
                                        name="terms-agreed"
                                    />
                                    <label for="terms-agreed" class="text-xs">
                                        {{ $t('home_agree_to_give_qariin_its_rights') }}
                                    </label>
                                </fieldset>
                                <template v-if="!isLoading">
                                    <div
                                        class="flex flex-col items-start justify-center"
                                    >
                                        <button
                                            type="submit"
                                            class="bg-primary-900 text-white w-full max-w-xs rounded-md"
                                            :class="{ disabled: !formIsValid }"
                                            @click.prevent="sendData()"
                                        >
                                            {{ $t('home_submit') }}
                                        </button>
                                    </div>
                                </template>
                                <template v-else>
                                    <VueLottiePlayer
                                        style="width: 200px; height: 100px"
                                        name="Qariin loader"
                                        loop
                                        path="https://assets9.lottiefiles.com/private_files/lf30_1xgata3z.json"
                                    />
                                </template>
                            </div>
                        </form>
                        <p class="text-lg text-black mt-4">
                            {{ $t('home_slogan') }}
                        </p>
                    </div>

                    <div
                        v-if="sliderImages.length"
                        class="homepage__landing__slider py-10 overflow-hidden"
                    >
                        <img :src="require('@qariin-fe/src/images/sama.png').default" alt="SAMA Logo" class="w-64 mx-auto">
                        <div class="swiper-wrapper">
                            <article
                                v-for="(slide, index) in sliderImages"
                                :key="index"
                                class="swiper-slide h-full w-full"
                            >
                                <img
                                    :src="getImagePath(slide.image_path)"
                                    :alt="slide.title_en || slide.title_ar"
                                    class="rounded-md h-full w-full object-contain"
                                />
                            </article>
                        </div>
                    </div>
                </article>
            </div>
        </section>

        <section class="homepage__recommended">
            <div class="container text-center">
                <h2
                    class="relative a-20 text-2xl lg:text-4xl text-black font-semibold"
                >
                    {{ $t('home_partners') }}
                </h2>

                <div class="homepage__recommended__slider overflow-hidden">
                    <div class="swiper-wrapper h-full">
                        <article class="swiper-slide">
                            <img
                                src="@qariin-fe/src/images/partners/agig.png"
                                class="w-40 h-28 object-contain"
                                alt="agig"
                            />
                        </article>
                        <article class="swiper-slide">
                            <img
                                src="@qariin-fe/src/images/partners/Amana.png"
                                class="w-40 h-28 object-contain"
                                alt="Amana"
                            />
                        </article>
                        <article class="swiper-slide">
                            <img
                                src="@qariin-fe/src/images/partners/arabia.png"
                                class="w-40 h-28 object-contain"
                                alt="arabia"
                            />
                        </article>
                        <!-- <article class="swiper-slide">
                            <img
                                src="@qariin-fe/src/images/partners/Jazera.png"
                                class="w-40 h-28 object-contain"
                                alt="Jazera"
                            />
                        </article> -->
                        <article class="swiper-slide">
                            <img
                                src="@qariin-fe/src/images/partners/malath.png"
                                class="w-40 h-28 object-contain"
                                alt="malath"
                            />
                        </article>
                        <!-- <article class="swiper-slide">
                            <img
                                src="@qariin-fe/src/images/partners/saico.png"
                                class="w-40 h-24 object-contain"
                                alt="saico"
                            />
                        </article> -->
                        <article class="swiper-slide">
                            <img
                                src="@qariin-fe/src/images/partners/union.png"
                                class="w-40 h-28 object-contain"
                                alt="union"
                            />
                        </article>
                        <div class="swiper-pagination"></div>
                    </div>
                </div>
            </div>
        </section>

        <section
            class="homepage__guide"
            style="--icon: url('@qariin-fe/src/images/guide-bg.jpg')"
        >
            <div class="container">
                <h2 class="text-2xl text-white font-semibold text-center px-md lg:px-0 mb-4 lg:mb-0">
                    {{ $t('home_how_to_make_it') }}
                </h2>
                <p class="text-white text-center text-sm mt-1">
                    {{ $t('home_how_to_make_it_slogan') }}
                </p>

                <div class="homepage__guide__steps">
                    <article>
                        <figure>
                            <img
                                src="@qariin-fe/src/images/guide-steps/step-1.png"
                                alt="Step 1"
                            />
                        </figure>
                        <p>{{ $t('home_how_to_make_it_step1') }}</p>
                    </article>
                    <figure class="step">
                        <span>01</span>
                    </figure>
                    <article>
                        <figure>
                            <img
                                src="@qariin-fe/src/images/guide-steps/step-2.png"
                                alt="Step 2"
                            />
                        </figure>
                        <p>{{ $t('home_how_to_make_it_step2') }}</p>
                    </article>
                    <figure class="step">
                        <span>02</span>
                    </figure>
                    <article>
                        <figure>
                            <img
                                src="@qariin-fe/src/images/guide-steps/step-3.png"
                                alt="Step 3"
                            />
                        </figure>
                        <p>{{ $t('home_how_to_make_it_step3') }}</p>
                    </article>
                    <figure class="step">
                        <span>03</span>
                    </figure>
                    <article>
                        <figure>
                            <img
                                src="@qariin-fe/src/images/guide-steps/step-4.png"
                                alt="Step 4"
                            />
                        </figure>
                        <p>{{ $t('home_how_to_make_it_step4') }}</p>
                    </article>
                </div>
            </div>
        </section>

        <section class="homepage__why-qariin">
            <div class="container">
                <h2 class="text-2xl text-black font-semibold text-center">
                    {{ $t('home_why_qariin') }}
                </h2>
                <p class="text-tertiary text-center">
                    {{ $t('home_why_qariin_slogan') }}
                </p>

                <div
                    class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-6 mt-8 place-items-center mb-16"
                >
                    <article
                        class="max-w-xs h-64 flex flex-col items-center justify-start"
                    >
                        <img
                            src="@qariin-fe/src/images/why-qariin/time.png"
                            alt="Time"
                            class="w-20 h-40 object-contain"
                        />
                        <h3 class="my-3 text-secondary-900">
                            {{ $t('home_why_qariin_feature1') }}
                        </h3>
                        <p class="text-tertiary text-sm w-52 text-center">
                            {{ $t('home_why_qariin_paragraph1') }}
                        </p>
                    </article>
                    <article
                        class="max-w-xs h-64 flex flex-col items-center justify-start"
                    >
                        <img
                            src="@qariin-fe/src/images/why-qariin/discount.png"
                            alt="Discount"
                            class="w-20 h-40 object-contain"
                        />
                        <h3 class="my-3 text-secondary-900">
                            {{ $t('home_why_qariin_feature2') }}
                        </h3>
                        <p class="text-tertiary text-sm w-52 text-center">
                            {{ $t('home_why_qariin_paragraph2') }}
                        </p>
                    </article>
                    <article
                        class="max-w-xs h-64 flex flex-col items-center justify-start"
                    >
                        <img
                            src="@qariin-fe/src/images/why-qariin/pay.png"
                            alt="Pay"
                            class="w-20 h-40 object-contain"
                        />
                        <h3 class="my-3 text-secondary-900">
                            {{ $t('home_why_qariin_feature3') }}
                        </h3>
                        <p class="text-tertiary text-sm w-52 text-center">
                            {{ $t('home_why_qariin_paragraph3') }}
                        </p>
                    </article>
                    <article
                        class="max-w-xs h-64 flex flex-col items-center justify-start"
                    >
                        <img
                            src="@qariin-fe/src/images/why-qariin/support.png"
                            alt="Time"
                            class="w-20 h-40 object-contain"
                        />
                        <h3 class="my-3 text-secondary-900">
                            {{ $t('home_why_qariin_feature4') }}
                        </h3>
                        <p class="text-tertiary text-sm w-52 text-center">
                            {{ $t('home_why_qariin_paragraph4') }}
                        </p>
                    </article>
                    <article
                        class="max-w-xs flex flex-col items-center justify-start lg:mt-12"
                    >
                        <img
                            src="@qariin-fe/src/images/why-qariin/saudi.png"
                            alt="Saudi"
                            class="w-20 h-40 object-contain"
                        />
                        <h3 class="my-3 text-secondary-900"> 
                            {{ $t('home_why_qariin_feature5') }}
                        </h3>
                        <p class="text-tertiary text-sm w-52 text-center">
                            {{ $t('home_why_qariin_paragraph5') }}
                        </p>
                    </article>
                    <article
                        class="max-w-xs h-64 flex flex-col items-center justify-start"
                    >
                        <img
                            src="@qariin-fe/src/images/why-qariin/clear.png"
                            alt="Clear"
                            class="w-20 h-40 object-contain"
                        />
                        <h3 class="my-3 text-secondary-900">
                            {{ $t('home_why_qariin_feature6') }}
                        </h3>
                        <p class="text-tertiary text-sm w-52 text-center">
                            {{ $t('home_why_qariin_paragraph6') }}
                        </p>
                    </article>
                </div>
            </div>
        </section>
    </app-layout>
</template>

<script>
import { defineComponent } from "vue";
import { Head } from '@inertiajs/inertia-vue3'
import AppLayout from "@/Layouts/AppLayout.vue";
import ErrorsAlert from "@/components/ErrorsAlert.vue";
// import Welcome from "@/Jetstream/Welcome.vue";
// import DatePicker from "@/components/DatePicker.vue";
import axios from "axios";
import VueLottiePlayer from "vue-lottie-player";
import * as moment from "moment";
import { Inertia } from '@inertiajs/inertia'



// core version + navigation, pagination modules:
import Swiper, { Navigation, Pagination, Autoplay } from "swiper";

// configure Swiper to use modules
Swiper.use([Navigation, Pagination, Autoplay]);

export default defineComponent({
    components: {
        AppLayout,
        Head,
        ErrorsAlert,
        // Welcome,
        VueLottiePlayer,
        // DatePicker,
    },
    props: {
        captcha: {
            type: String,
            required: true,
        },
        currentHijriYear: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isLoading: false,
            errorMessages: [],
            hijri: false,
            input: {
                start_date: null, // required
                start_date_dd: null, // required
                start_date_mm: null, // required
                start_date_yy: null, // required
                identity_number: null, //required
                vehicle_value: null, // required
                vehicle_type: null, // required
                individuals: null,
                commercial_registration_number: null,
                new_insurance: null,
                insuranceType: "new", // if new ? new_insurance = 1 : transfer_ownership = 1
                insuranceCategory: "persons", // if new ? new_insurance = 1 : transfer_ownership = 1
                vehicle_sequence: null,
                vehicle_custom: null,
                vehicle_custom_number: null, // required if vehicle_custom = 1
                vehicle_model_year: null, // required if vehicle_custom = 1
                vehicle_number: null, // required if vehicle_sequence = 1
                transfer_ownership: null,
                buyer_identity: null, // required if transfer_ownership = 1
                companies: null,
                date_of_birth: null,
                date_of_birth_mm: null,
                date_of_birth_yy: null,
                captcha: null,
                captchaFailed: false,
                captchaPassed: false,
                terms_agreed: false,
                licenseType: "form", // if form ? companies = 0 : companies = 1
            },
            disableNewInsurance: false,
            disableTransferOwnership: false,
            dateOfBirth: true,
            hijriDateOfBirth: true,
            dateOfInsurance: true,
            newCaptcha: null,
            captchaRefreshed: false,
            startDateFailed: false,
            formIsValid: false,
            hasError: false,
            startDate: null,
            sliderImages: [],
        };
    },
    computed: {
        validInsuranceYears() {
            const today = new Date();
            const maxValidDate = new Date(new Date().setDate(today.getDate() + 30));
            const validYears = [...new Set([today.getFullYear(), maxValidDate.getFullYear()])];

            return validYears;
        },
        tomorrow() {
            var date = new Date();

            date; //# => Fri Apr 01 2011 11:14:50 GMT+0200 (CEST)

            date.setDate(date.getDate() + 1);

            return date.toISOString().split('T')[0];
        },
        nextMonth() {
            // Create a new Date object for the current date
            const currentDate = new Date();

            // Get the month and year of the current date
            const currentDay = currentDate.getDate();
            const currentMonth = currentDate.getMonth();
            const currentYear = currentDate.getFullYear();

            // Calculate the month and year of the next month
            let nextMonth = currentMonth + 1;
            let nextYear = currentYear;

            if (nextMonth > 11) {
                // If the next month is greater than 11 (December), add 1 to the year and set the month to 0 (January)
                nextMonth = 0;
                nextYear += 1;
            }

            // Create a new Date object for the first day of the next month
            const nextMonthDate = new Date(nextYear, nextMonth, currentDay);

            return nextMonthDate.toISOString().split('T')[0];
        }
    },
    watch: {
        input: {
            handler() {
                if (!this.$refs.basicForm) return;

                this.checkStartDate();

                this.formIsValid =
                    this.$refs.basicForm.checkValidity() &&
                    this.input.captchaPassed &&
                    !this.startDateFailed &&
                    this.input.terms_agreed &&
                    this.dateOfBirth &&
                    this.dateOfInsurance &&
                    this.input.date_of_birth_mm &&
                    this.input.date_of_birth_yy &&
                    !this.hasError;
            },
            deep: true,
            immediate: true,
        },
        "input.identity_number"(value) {
            if (this.input.insuranceType == "new") {
                var string = value.toString();
                this.hijri = string.startsWith("1");
            }
        },
        "input.buyer_identity"(value) {
            if (this.input.insuranceType == "transfer") {
                var string = value.toString();
                this.hijri = string.startsWith("1");
            }
        },
    },
    methods: {
        getImagePath(imagePath) {
            return `${window.location.origin}/${imagePath}`;
        },
        toggleSearch(key, value, keysToReset, isNewInsuranceDisabled = false, disableTransferOwnership = false) {
            if (isNewInsuranceDisabled || disableTransferOwnership) return;

            this.input[key] = value;

            if (keysToReset.length)
                keysToReset.forEach((key) => {
                    this.input[key] = null;
                });

            if (key == 'insuranceCategory' && value == 'companies') {
                this.toggleSearch('insuranceType', 'transfer', []);
                this.disableNewInsurance = true;
            } else if (key == 'insuranceCategory' && value == 'persons') {
                this.disableNewInsurance = false;
            }

            if (key == 'licenseType' && value == 'customs') {
                this.toggleSearch('insuranceCategory', 'persons', []);
                this.toggleSearch('insuranceType', 'new', []);
                this.disableTransferOwnership = true;
            } else if (key == 'licenseType' && value == 'form') {
                this.disableTransferOwnership = false;
            }
        },
        async getSliderImages() {
            await axios.get("/api/sliders/index").then(({ data }) => {
                this.sliderImages = data.data;
            });
        },
        validateDate(date, key) {
            this[key] = moment(date, "D-M-YYYY", true).isValid();
        },
        validateLength(e, key) {
            var input = e.target;
            var fieldset = e.target.closest("fieldset");
            var value = this.input[key].toString();

            if (value.length < input.minLength) {
                this.hasError = true;
                fieldset.classList.add("border-danger");
            } else if (value.length > input.maxLength)
                this.input[key] = this.input[key]
                    .toString()
                    .slice(0, input.maxLength);
            else if (value.length <= input.maxLength &&  value.length >= input.minLength) {
                fieldset.classList.remove("border-danger");
                this.hasError = false;
            }
        },
        async refreshCaptcha() {
            this.input.captcha = null;
            await fetch(route("refresh-captcha"))
                .then((res) => res.json())
                .then((response) => {
                    this.newCaptcha = response.captcha;
                    this.captchaRefreshed = true;
                });
        },
        async checkCaptcha(e) {
            var captcha = this.input.captcha;
            if (!captcha) {
                this.$refs.captcha_field.classList.add("border-danger");
                return;
            }

            e.preventDefault();
            await axios
                .post("/validate/captcha", { captcha })
                // .then(response => response.json())
                .then((response) => {
                    var { data } = response;

                    if (!data.status) {
                        throw new Error(data.message);
                    }
                    this.$refs.captcha_field.classList.remove("border-danger");
                    this.input.captchaPassed = true;
                    this.input.captchaFailed = false;
                })
                .catch((error) => {
                    console.error(error);
                    this.input.captchaPassed = false;
                    this.input.captchaFailed = true;
                    this.refreshCaptcha();
                });
        },
        checkStartDate() {
            if (
                !this.input.start_date_dd &&
                !this.input.start_date_mm &&
                !this.input.start_date_yy
            )
                return;

            var startDate = `${this.input.start_date_yy}-${this.input.start_date_mm}-${this.input.start_date_dd}`;
            var today = new Date();

            if (new Date(startDate) > new Date() && new Date(startDate) <= new Date(new Date().setDate(today.getDate() + 30))) {
                this.startDateFailed = false;
            } else {
                this.startDateFailed = true;
                return;
            }
        },
        async sendData() {
            if (!this.formIsValid) return;

            this.errorMessages = [];
            this.isLoading = true;

            if (this.input.insuranceType === "new") {
                this.input.new_insurance = 1;
                this.input.transfer_ownership = null;
            } else {
                this.input.transfer_ownership = 1;
                this.input.new_insurance = null;
            }

            if (this.input.licenseType === "form") {
                this.input.vehicle_type = 2;
                this.input.vehicle_sequence = 1;
                this.input.vehicle_custom = null;
            } else {
                this.input.vehicle_type = 3;
                this.input.vehicle_sequence = null;
                this.input.vehicle_custom = 1;
            }

            if (this.input.insuranceCategory == "persons") {
                this.input.individuals = 1;
                this.input.companies = null;
            }
            else {
                this.input.companies = 1;
                this.input.individuals = null;
            }

            this.input.date_of_birth = `${this.input.date_of_birth_mm}-${this.input.date_of_birth_yy}`;


            Object.keys(this.input).forEach((key) => {
                if (this.input[key] === null) delete this.input[key];
            });

            Inertia.remember(JSON.stringify(this.input),"input_data")

            await axios
                .post("api/search", this.input)
                .then((response) => {
                    var { data } = response;

                    this.isLoading = false;
                    window.location = `${route(`other-info`)}?id=${
                        data.data
                    }&vehicle=${data.vehicle_id}`;
                })
                .catch((error) => {
                    var { response } = error;
                    var { data } = response;

                    this.input.captchaPassed = false;
                    this.input.captcha = null;
                    this.refreshCaptcha();

                    if (data.errors)
                        Object.keys(data.errors).forEach((key) => {
                            this.errorMessages.push(data.errors[key][0]);
                        });
                    else this.errorMessages.push(data.message);

                    this.isLoading = false;
                    console.error(error);
                });
        },
    },
    async mounted() {
        //reset input data
        
        var date = new Date();

        date = date.setDate(date.getDate() + 1);

        this.input.start_date = new Date(date).toISOString().substring(0, 10);

        var restoredData = Inertia.restore("input_data");

        if(restoredData){
          this.input = JSON.parse(restoredData)
        }else{
            this.input = {
                start_date: this.input.start_date, // required
                start_date_dd: null, // required
                start_date_mm: null, // required
                start_date_yy: null, // required
                identity_number: null, //required
                vehicle_value: null, // required
                vehicle_type: null, // required
                individuals: null,
                commercial_registration_number: null,
                new_insurance: null,
                insuranceType: "new", // if new ? new_insurance = 1 : transfer_ownership = 1
                insuranceCategory: "persons", // if new ? new_insurance = 1 : transfer_ownership = 1
                vehicle_sequence: null,
                vehicle_custom: null,
                vehicle_custom_number: null, // required if vehicle_custom = 1
                vehicle_model_year: null, // required if vehicle_custom = 1
                vehicle_number: null, // required if vehicle_sequence = 1
                transfer_ownership: null,
                buyer_identity: null, // required if transfer_ownership = 1
                companies: null,
                date_of_birth: null,
                date_of_birth_mm: null,
                date_of_birth_yy: null,
                captcha: null,
                captchaFailed: false,
                captchaPassed: false,
                terms_agreed: false,
                licenseType: "form", // if form ? companies = 0 : companies = 1
            };
        }

        if (this.input.identity_number) {
            this.hijri = this.input.identity_number.toString().startsWith("1");
        }

        await axios.get("/api/sliders/index").then(({ data }) => {
            this.sliderImages = data.data;
        });

        setTimeout(() => {
            this.refreshCaptcha();
        }, 30000);
    },
});
</script>
