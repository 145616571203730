<template>
    <app-layout title="Home">
        <video loop autoplay muted controls>
            <source src="qariin-video.mp4" type="video/mp4" />
            Your browser does not support the video tag.
        </video>
    </app-layout>
</template>

<script>
import { defineComponent } from "vue";
import AppLayout from "@/Layouts/AnnouncementLayout.vue";
import Welcome from "@/Jetstream/Welcome.vue";

export default defineComponent({
    components: {
        AppLayout,
        Welcome,
    },
});
</script>
