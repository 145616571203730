<template>
  <app-layout>
      <Head>
          <title>FAQs | Qariin</title>
      </Head>
      <section class="faqs__header">
      <div class="container">
        <h1>الأسئلة الشائعة</h1>
        <p>
          ياهلا بالزين ، هنا موجود إجابات كل الأسئلة التي ممكن تجي فبالك وبالتفصيل … ولاسمح الله احتجت مساعدتنا اضغط على
          تواصل معنا وارسل استفسارك وابشر بأفضل خدمة من فريق العمل .
        </p>
        <ul>
          <li>
            <a href="#about">عن قارن</a>
          </li>
          <li>
            <a href="#pre-sales">قبل عملية الشراء</a>
          </li>
          <li>
            <a href="#within-sales">خلال عملية الشراء</a>
          </li>
          <li>
            <a href="#after-sales">بعد عملية الشراء</a>
          </li>
        </ul>
      </div>
    </section>

    <section id="about">
      <div class="container">
        <h2 class="faqs__subHeading">عن قارن</h2>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>ماهي منصة قارن ؟</span>
          </h3>
          <p class="faqs__answer">
            هي منصة إلكترونية تقدم خدماتها للأفراد والمنشأت الصغيرة والمتوسطة ، لديها من الخبرات والمؤهلات الكافية لتقديم أفضل الحلول التأمينية وذلك من خلال محركات البحث الإلكترونية في المنصة لتوفير الأسعار المناسبة والخدمات والتغطيات التأمينية الأمثل للعملاء بشكل مباشر ومميز.
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>ماذا يميز قارن عن المنصات الأخرى ؟</span>
          </h3>
          <p class="faqs__answer">
            <ul>
                <li>سهولة إصدار عروض الأسعار التأمينية وإصدار الوثائق.</li>
                <li>توفير أكثر من منتج تأميني في منصة قارن</li>
                <li>توفير أكثر من طريقة دفع إلكترونية سهلة وآمنة في منصة قارن.</li>
                <li>توفير خدمة للعملاء على مدار الساعة بأرقام خدمة مجانية.</li>
                <li>الحماية العالية في منصة قارن لمعلومات العميل.</li>
                <li>إتخاذ التدابير الإحترازية التقنية للحد من الإحتيال التأميني للعميل وشركات التأمين.</li>
                <li>إتخاذ التدابير الإحترازية التقنية للحد من الاختراقات الخارجية للمنصة. </li>
            </ul>
          </p>
        </article>
      </div>
    </section>

    <section id="pre-sales">
      <div class="container">
        <h2 class="faqs__subHeading">قبل عملية الشراء</h2>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>ما هو الفرق بين تأمين ضد الغير (طرف ثالث) والتأمين الشامل؟</span>
          </h3>
          <p class="faqs__answer faqs__answer--heigh">
            <ul class="list-disc">
                <li>تأمين الطرف الثالث (التأمين الإلزامي): هو التأمين الإلزامي على المركبات ويعد الحد الأدنى من أنواع التأمين على المركبات ويشمل بالتعويض الطرف الاخر المسمى بـ ( الطرف الثالث ) فقط وبحد اقصى لغاية 10,000,000 ريال سعودي.</li>
                <li>
                    تشمل التغطيات:
                    <ul class="ps-3">
                        <li>الأضرار الجسدية للطرف الثالث.</li>
                        <li>تلف ممتلكات الطرف الثالث.</li>
                        <li>حالات الوفاة للطرف الثالث.</li>
                    </ul>
                </li>
                <li>تأمين الطرف الثالث مع منافع أخرى:  هو تأمين ضد الغير بالاضافة الى بعض المميزات التي تم اختيارها من قبل العميل بمبلغ إضافي خارج عن سعر الوثيقة الإلزامية.</li>
                <li>
                    ومن المنافع الإضافية جمعاً او فرداً :
                    <ul class="ps-3">
                        <li>الحوادث الشخصية للسائق </li>
                        <li>الحوادث الشخصية للركاب</li>
                        <li>حدود جغرافية أوسع.</li>
                        <li>الكوارث الطبيعية</li>
                        <li>سيارة بديلة.</li>
                        <li>السرقة.</li>
                        <!-- <li>خدمة المساعدة على الطريق.</li> -->
                    </ul>
                </li>
                <li>تأمين الشامل: وهو عبارة عن تغطية جميع الاضرار التي تسبب بها المؤمن له او وقعت عليه سواء كان للمؤمن له او الطرف الاخر المسمى
 بـ ( الطرف الثالث) بمقابل نسبة تحمل يحددها المؤمن عند شراء الوثيقة
</li>
                <li>
                    تشمل التغطيات:
                    <ul class="ps-3">
                        <li>المسؤولية تجاه الغير.</li>
                        <li>إصلاح السيارة للمؤمن له والطرف الاخر.</li>
                        <li>الكوارث الطبيعية.</li>
                        <li>سرقة السيارة.</li>
                    </ul>
                </li>
                <li>
                    تغطيات يمكنك إضافتها لوثيقة التأمين الشامل :
                    <ul class="ps-3">
                        <li>تغطية الحوادث الشخصية للسائق.</li>
                        <li>تغطية الحوادث الشخصية للركاب.</li>
                        <li>تغطية ايجار سيارة بديلة.</li>
                        <li>امتداد جغرافي للتغطية.</li>
                        <li>مصاريف سحب سيارة.</li>
                        <li>عدم تطبيق شرط الإستهلاك.</li>
                        <li>تغطية ضياع مفتاح السيارة أو السرقة.</li>
                        <li>تغطية للزجاج.</li>
                        <li>تغطية مجانية لسيارات الدفع الرباعي على الطرق الغير معبده.</li>
                        <li>المقتنيات الشخصية (داخل السيارة).</li>
                        <li>تغطية كرسي الأطفال.</li>
                    </ul>
                </li>
            </ul>
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>هل تقوم شركة التأمين بتعويضي عن إصلاح المركبة بسبب حادث مروري في حال كانت وثيقة التأمين الخاصة بمركبتي هي وثيقة تأمين ضد الغير (طرف ثالث)؟</span>
          </h3>
          <p class="faqs__answer">
            شركة التأمين لا تعوض حامل وثيقة تأمين ضد الغير (طرف ثالث) لإصلاح مركبته الخاصة، وإنما تقوم بتعويض الطرف الثالث في حال كانت هناك مسؤولية للحادث من قبل حامل الوثيقة، وذلك حسب الشروط والاستثناءات الموضحة في وثيقة التأمين الموحدة.
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>ماهي الوثيقة الموحدة للتأمين الإلزامي على المركبات؟</span>
          </h3>
          <p class="faqs__answer">
            وثيقة التأمين الإلزامي هي التي تغطي الأضرار التي تسببها مركبتك للغير
) الأشخاص والممتلكات) ، وهذه الوثيقة لا تغطي الأضرار التي تلحق بمركبتك أو تلحق بك عند قيادة المركبة. مثال : عند وقوع حادث تكون أنت المتسبب به ، ستعوض شركة التأمين المتضرر عن جميع الأضرار التي تسببت بها لغيرك حسب حدود وثيقة التأمين الموحدة ولن تدفع الشركة أي تعويض لقاء الأضرار التي وقعت لك أو لمركبتك .
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>ماهي الوثيقة الموحدة لتأمين الشامل ؟</span>
          </h3>
          <p class="faqs__answer">
            تعد وثيقة التأمين الشامل للمركبات برنامج تأميني يلزم شركة التأمين بتعويض المؤمن له عن الخسائر والأضرار الملحقة بالسيارة المؤمنة، بالإضافة إلى تغطية الأضرار التي تلحق بالغير أو مركبته او اي خسائر مادية او وفيات في حال كان المؤمن له هو المتسبب وذلك وفقاً للشروط والأحكام والإستثناءات والملحقات الموضحة في وثيقة التأمين.
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>ما هي الشروط العامة للوثيقة التأمين الموحدة ؟  </span>
          </h3>
          <p class="faqs__answer">
            يرجى الرجوع وقراءة الوثيقة الموحدة للتأمين الإلزامي على المركبات لمعرفة الشروط للوثيقة الموحدة.
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>لم يقع لي أي حادث منذ سنوات ولم أستفد من التأمين. لماذا أقوم بالتأمين في كل سنة؟</span>
          </h3>
          <p class="faqs__answer">
            نتمنى لك السلامة دائماً ، لحماية نفسك من المخاطر المترتبة في حال تعرضك – لا قدّر الله – لأي حادث  ، حيث يساعدك التأمين على مواجهة المخاطر التي قد تتعرض لها حيث تتكفل شركة التأمين بتحمل الأعباء المالية الناتجة عن وقوع هذه المخاطر مقابل أقساط معينة وتجنبا للمخالفات المرورية.
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>هل منصة قارن آمنة للدفع الإلكتروني؟</span>
          </h3>
          <p class="faqs__answer">
            بالتأكيد ، نتعامل بحرص في عملية الدفع عن طريق خدمة سداد، وعن طريق البطاقات الائتمانية ، المرتبطة مع البنوك السعودية ، حيث الحماية العالية في منصة قارن لمعلومات العميل مع إتخاذ التدابير الإحترازية التقنية كما يقوم الفريق بمراقبة المعاملات المالية للبحث عن أي عمليات غير مصرح بها من البطاقات الائتمانية للعملاء.
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>هل منصة قارن معتمده ومصرّحة من الجهات الرسمية؟</span>
          </h3>
          <p class="faqs__answer">
            طبعاً ، منصة قارن مصرحة من البنك المركزي السعودي، وهي منصة التأمين الأفضل في المملكة.
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>هل تتطلب عملية الشراء إرسال صور للوثائق الشخصية مثل الهوية الوطنية؟</span>
          </h3>
          <p class="faqs__answer">
            لا حاجة لإرسال صور للوثائق الرسمية عند الشراء ،نقوم بمنصة قارن بالتحقق من صحة المعلومات المقدمة من قبلك عن طريق الربط مع مركز المعلومات الوطني والجهات الرسمية ذات الصلة ويتم رفع صور المستندات المطلوبة عند رفع المطالبات عن طريق المنصة
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>هل يمكن نقل التأمين من شخص لآخر عند شراء مركبة مستعملة على سبيل المثال؟</span>
          </h3>
          <p class="faqs__answer">
            لا يمكن نقل تأمين السيارة من شخص لآخر، وإنما يجب الحصول على تأمين جديد في حال شراء مركبة جديدة أو نقل ملكية مركبة، بحسب أنظمة البنك المركزي السعودي.
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>هل أستطيع شراء أكثر من وثيقة تأمين لنفس المركبة؟</span>
          </h3>
          <p class="faqs__answer">
            لا يمكن حمل أو شراء أكثر من وثيقتين على نفس المركبة وبنفس اسم المالك خلال نفس الفترة
 علماً أنه يمكن إضافة وثيقة ثالثة جديدة في حال تبقى (14) يوم على انتهاء الوثيقة الأولى ، بحسب أنظمة ولوائح البنك المركزي السعودي.
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>هل عملية شراء وثيقة التأمين عن طريق منصة قارن فورية؟ وهل سوف أحصل على الوثيقة بشكل فوري؟</span>
          </h3>
          <p class="faqs__answer">
            نعم، نوفر قنوات دفع آمنة وفورية (مدى -  STC PAY - APPLE PAY - سداد – بطاقات ائتمانية- قطاف – تابي - تمارا)، وبعد أن تتم عملية شراء الوثيقة بأحد تلك القنوات فإن الوثيقة سوف تصدر للمالك بشكل فوري ويتم إرسال الوثيقة وتفاصيلها عن طريق البريد الإلكتروني ورقم  الجوال المسجل للعميل. كما يمكن تحميل أو طباعة الوثيقة من حسابك الخاص في منصة قارن
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>تبقى على انتهاء وثيقة التأمين الخاصة بي (10) أيام وأريد أن أجدد التأمين للمركبة، ماذا أعمل؟ وهل يمكنني الحصول على خصم من شركة التأمين ؟</span>
          </h3>
          <p class="faqs__answer">
            نعم ، يمكنك تجديد وإصدار وثيقة تأمين جديدة قبل انتهاء وثيقة التأمين الحالية ويمكنك الاستفادة من خصم عدم وجود مطالبات بالإضافة لخصم الولاء في حال كان تجديد الوثيقة في نفس  شركة التأمين السابقة .
 من خلال الدخول على منصة قارن وطلب تأمين جديد على أن يكون اختيارك لـ "تاريخ بدء الوثيقة" هو اليوم التالي من تاريخ انتهاء الوثيقة الحالية.
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>لا أقوم بتجديد تأمين المركبة بعد انتهاء وثيقة التأمين السابقة مباشرةً وإنما أقوم بذلك بعد عدة أيام. هل هناك أي مشكلة في ذلك؟</span>
          </h3>
          <p class="faqs__answer">
            عزيزي نأمل منك الا تترك المركبة بدون تأمين  حتى ليوم واحد ولذلك لحماية نفسك من المخاطر المترتبة في حال تعرضك – لا قدّر الله – لأي حادث  ، حيث تجديدك للتأمين مباشرة لايعرضك للخطر بإذن الله ويمكنك من الاستفادة من خصومات التجديد والولاء .
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>هل التسعيرات التي تقدمها منصة قارن من شركات التأمين تشمل خصم عدم وجود مطالبات؟</span>
          </h3>
          <p class="faqs__answer">
            نعم، نقوم بمنصة قارن بربط مع الجهات المعنية لضمان حصول عملائه على أفضل الأسعار ومن ذلك الحصول على جميع الخصومات المتوفرة ومن بينها خصم "عدم وجود مطالبات" وخصم "ولاء الشركة" والتي أقرها البنك المركزي السعودي على جميع شركات التأمين للتأمين الإلزامي (تأمين ضد الغير) للأفراد ويتم توضيح مبلغ الخصم بالتسعيرة.
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>ريد أن أقوم بنقل ملكية مركبة وإصدار "رخصة السير" للمركبة (الاستمارة) من المرور، هل تضمن منصة قارن ارسال وثيقة التأمين لمركز المعلومات الوطني (المرور) بشكل فوري بعد عملية الشراء؟</span>
          </h3>
          <p class="faqs__answer">
            نعم , إن آلية العمل مع شركائنا في شركات التأمين هي القيام بإرسال وتصدير وثيقة التأمين إلى مركز المعلومات الوطني (المرور) فور أن تتم عملية شراء وثيقة التأمين بنجاح..
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>هل تسعيرات الوثائق التي تقدمها منصة قارن تنافسية؟</span>
          </h3>
          <p class="faqs__answer">
            اكيد ، نحرص في منصة قارن على أن تكون اسعار الوثائق هي أسعار منافسة مقارنة بسوق التأمين.
          </p>
        </article>
      </div>
    </section>

    <section id="within-sales">
      <div class="container">
        <h2 class="faqs__subHeading">خلال عملية الشراء</h2>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>ما هو العنوان الوطني؟ وكيف اعرف عنواني الوطني؟</span>
          </h3>
          <p class="faqs__answer">
            العنوان الوطني هو مكان وعنوان إقامة حامل الوثيقة (مالك الوثيقة/المؤمن له).
بإمكانك معرفة عنوانك الوطني عن طريق بوابة العنوان الوطني
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>ما المقصود برقم الهوية ؟</span>
          </h3>
          <p class="faqs__answer">
            هو رقم يتكون من عشرة أرقام موجود في بطاقة الأحوال/الاقامة
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>ما المقصود برقم الشركة وكيف أستطيع الحصول عليه؟</span>
          </h3>
          <p class="faqs__answer">
            رقم الشركة هو رقم الحاسب الآلي في مركز المعلومات الوطني، ويمكن إيجاده في الزاوية اليسرى العلوية للسجل التجاري للشركة فوق رقم السجل التجاري ويبدأ بالرقم (7) .
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>ما هو الرقم التسلسلي للمركبة؟ وأين أستطيع إيجاده؟</span>
          </h3>
          <p class="faqs__answer">
            الرقم التسلسلي للمركبة هو الرقم الموجود في أسفل بطاقة "رخصة السير" للمركبة (الاستمارة).
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>ما هو “رقم البطاقة الجمركية” للمركبة؟ وأين أستطيع إيجاده؟</span>
          </h3>
          <p class="faqs__answer">
            هو رقم البطاقة الصادر من مصلحة الجمارك والموجود في الركن الأيسر العلوي للبطاقة
(فقط أرقام بدون العلامات).
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>
              ما المقصود بتاريخ انتهاء الهوية؟
            </span>
          </h3>
          <p class="faqs__answer">
            هو تاريخ انتهاء صلاحية بطاقة الهوية لمالك المركبة والموجود أسفل رقم الهوية
            ( بطاقة الأحوال/الإقامة )
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>
ما المقصود بسنة الميلاد؟
            </span>
          </h3>
          <p class="faqs__answer">
            هو تاريخ الميلاد لمالك المركبة والموجود على الهوية ( بطاقة الاحوال/الاقامة )
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>
ما هي المدخلات التي تؤثر على سعر الوثيقة؟
            </span>
          </h3>
          <p class="faqs__answer">
            توجد عدة مدخلات تؤثر على سعر وثيقة التأمين ، فعلى سبيل المثال لا الحصر، نوع المركبة وعمر السائق والمنطقة التي سوف يتم استخدام المركبة فيها والغرض من استخدام المركبة، كل ذلك قد يؤثر على سعر وثيقة التأمين. كذلك هناك عوامل أخرى تؤثر على سعر الوثيقة كوجود مطالبات سابقة أو حوادث تسبب فيها مالك المركبة أو أحد السائقين لها أو عدم الانتظام في التأمين .
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>
هل إضافة قائد إضافي للمركبة أقل من ٢١ سنة ستؤثر على سعر الوثيقة؟
            </span>
          </h3>
          <p class="faqs__answer">
            نعم سيؤثر على سعر الوثيقة.
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>
كيف اعرف "نوع وثيقة المركبة"؟
            </span>
          </h3>
          <p class="faqs__answer">
            في حال كانت المركبة المراد التأمين عليها مسجلة لدى المرور وتحمل رخصة سير فنوع التسجيل يكون ( استمارة ) ، أما إذا لم يكن للمركبة رخصة سير (استمارة) -أي أنها سيارة جديدة من الوكالة أو تم استيرادها من الخارج-، فنوع التسجيل في تلك الحالة يكون "البطاقة الجمركية".
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>
ما المقصود بالغرض من استخدام المركبة؟
            </span>
          </h3>
          <p class="faqs__answer">
            إذا كان هناك (أي) استخدام آخر مع الاستخدام الشخصي (حتى لو كان قليلاً أو يستخدم في بعض الأحيان)، فإنه يجب اختيار الاستخدام الآخر ولا يتم اختيار "استخدام شخصي". مثال: (أحمد) لديه سيارة خاصة ويستخدمها غالباً للأغراض الشخصية ولكن في عطل نهاية الأسبوع يقوم (أحمد) باستخدام مركبته لتوصيل الأشخاص من مكان إلى آخر باستخدام (أوبر- على سبيل المثال). في حالة (أحمد)، يجب عليه اختيار "نقل ركاب (أوبر)" في خانة (الغرض من الاستخدام).
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>
حينما اشتريت وثيقة التأمين من منصة قارن كنت أستخدم المركبة للأغراض الشخصية، ولكن بعد مرور عدة أشهر قررت أن أقوم باستخدامها لنقل الركاب (على سبيل المثال: أوبر أو كريم). ماذا علي أن أعمل؟
            </span>
          </h3>
          <p class="faqs__answer">
            في حال كان هناك أي تغيير في بيانات أو معلومات المؤمن له أو المركبة أو أي معلومات تؤثر في وثيقة التأمين بعد شراء وثيقة التأمين، فإنه يجب على المؤمن له (مالك الوثيقة) إشعار شركة التأمين المعنية بذلك وتعديل وثيقة التأمين على ضوء ذلك لحفظ حقوق المؤمن له والتي تشمل على سبيل المثال لا الحصر (العنوان الوطني، معلومات المركبة، معلومات مالك الوثيقة، الغرض من استخدام المركبة، عمر السائق، مدينة قيادة المركبة، إلخ..).
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>
ماهي عواقب إدخال بيانات غير صحيحة؟
            </span>
          </h3>
          <p class="faqs__answer">
            في حال كان هناك أي تغيير في بيانات أو معلومات المؤمن له أو المركبة أو أي معلومات تؤثر في وثيقة التأمين بعد شراء وثيقة التأمين بحسب أنظمة ولوائح البنك المركزي السعودي، وبالرجوع إلى الوثيقة الموحدة للتأمين الإلزامي الصادرة من البنك المركزي السعودي، فإنها نصت على أنه إذا ثبت أن المؤمن له أدلى ببيانات غير صحيحة أو أخفى وقائع جوهرية فإن لشركة التأمين الاحتفاظ بحق الرجوع للمؤمن له. وبذلك لا تعد وثيقة التأمين صالحة بموجبه.
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>
هل يمكن شراء وثيقة تأمين بهوية وطنية أو إقامة منتهية الصلاحية؟
            </span>
          </h3>
          <p class="faqs__answer">
            لا يمكن ذلك، فيجب تجديد الهوية أولاً.
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>
              ما هو "تاريخ بدء الوثيقة"؟
            </span>
          </h3>
          <p class="faqs__answer">
            هو تاريخ بدء سريان وثيقة التأمين (بداية التغطية التأمينية). ولا يمكن أن تكون بداية التغطية التأمينية في نفس يوم طلب التأمين، بل يكون بعد يوم واحد على الأقل من تاريخ طلب التأمين وكحد اقصى (14) يوم.
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>
              لم أستطيع الحصول على تسعيرة للوثيقة وظهور رسالة "خطأ في جلب تسعيرة لطلبك"؟
            </span>
          </h3>
          <p class="faqs__answer">
            يرجى المحاولة مرة أخرى لاحقاً والتأكد من صحة المعلومات، وفي حال تكرار الرسالة يمكن التواصل مع خدمة العملاء أو إرسال طلب دعم فني عن طريق صفحة "تواصل معنا" وسوف يقوم فريق العمل لدينا بالتواصل معكم في أسرع وقت ممكن.
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>
              هل عروض الأسعار المقدمة لها فترة صلاحية؟ وهل هي متغيرة؟
            </span>
          </h3>
          <p class="faqs__answer">
            نعم، العروض المقدمة لها فترة صلاحية محددة تسمى بفترة الشراء وعند انتهاء الفترة يجب القيام من جديد بطلب تسعيرة جديدة.
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>
              ما المقصود بمميزات الوثيقة؟
            </span>
          </h3>
          <p class="faqs__answer">
            مميزات الوثيقة يقصد بها التغطيات التي تشملها وثيقة التأمين وتكون مقدمة من شركة التأمين. وتختلف تلك التغطيات عادةً من شركة تأمين لأخرى.
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>
              ماهي وسائل الدفع الممكنة لشراء وثيقة تأمين من منصة قارن ؟
            </span>
          </h3>
          <p class="faqs__answer">
            يمكن الدفع وشراء الوثائق إلكترونياً بطرق آمنه عن طريق
            <ul class="list-disc">
                <li>ابل باي</li>
                <li>اس تي سي باي  </li>
                <li>بطاقات مدى</li>
                <li>فيزا </li>
                <li>ماستر كارد</li>
                <li>خدمة سداد</li>
                <li>قطاف</li>
                <li>تمارا </li>
                <li>تابي </li>
            </ul>
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>
              أين أستطيع أن أجد رقم فاتورة سداد ورقم المرجع؟
            </span>
          </h3>
          <p class="faqs__answer">
            عند اختيارك الدفع عن طريق خدمة سداد، سيظهر لك رقم المفوتر والرقم المرجعي للفاتورة وسيتم إرسال هذه المعلومات أيضاً إلى بريدك الإلكتروني ورقم الجوال  المسجل في حسابك، ويمكن الوصول لهذه المعلومات عبر اتباع هذه الخطوات:
            <ul>
                <li>1.	الدخول لحسابك</li>
                <li>2.	الضغط على "حسابي"</li>
                <li>3.	الضغط على "لوحة التحكم" من القائمة </li>
                <li>4.	الضغط على فواتير سداد.</li>
            </ul>
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>
              كيف أعرف رقم فاتورة سداد؟
            </span>
          </h3>
          <p class="faqs__answer">
            عند إصدار فاتورة سداد سيتم عرض المعلومات الآتية:
            <ul>
              <li>1.	رمز فاتورة سداد</li>
              <li>2.	رقم المرجع </li>
              <li>3.	آخر موعد للسداد يرجى استخدام رمز فاتورة سداد ورقم المرجع واستخدام احدى قنواتك البنكية للسداد.</li>
            </ul>
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>
              قمت بالدفع ولم تصلني نسخة من الوثيقة عبر بريدي الإلكتروني؟
            </span>
          </h3>
          <p class="faqs__answer">
            إذا لم تصلك الوثيقة بعد عملية الدفع وظهور صفحة عملية دفع ناجحة يرجى إرسال طلب دعم فني عن طريق صفحة "تواصل معنا" حيث يقوم فريق العمل لدينا في خدمتك على مدار 24 ساعة .
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>
              قمت بالتسجيل ولم يصلني رمز التحقق عبر رسالة نصية للجوال؟
            </span>
          </h3>
          <p class="faqs__answer">
            سيتم إرسال رمز التحقق خلال أقصى حد (1) دقائق وفي حال لم تصل الرسالة يرجى التأكد من الرقم المستخدم أعلى خانة رمز التحقق، إذا كان الرقم صحيح يرجى الضغط على زر إعادة الإرسال، إذا لم يصلك الرمز خلال عدة محاولات، يرجى التواصل مع خدمة العملاء وإرسال طلب دعم فني عن طريق صفحة "تواصل معنا" حيث يقوم فريق العمل لدينا في خدمتك على مدار 24 ساعة .
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>
              هناك خطأ في معلوماتي التأمينية الواردة في وثيقة التأمين؟
            </span>
          </h3>
          <p class="faqs__answer">
            إذا كان هناك أي معلومات خاطئة أو غير صحيحة في وثيقة التأمين فيجب التواصل مباشرةً  مع خدمة العملاء وإرسال طلب دعم فني عن طريق صفحة "تواصل معنا" حيث يقوم فريق العمل لدينا في خدمتك على مدار 24 ساعة .
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>
              ماهي أوقات دوام عمل خدمة العملاء؟
            </span>
          </h3>
          <p class="faqs__answer">
            أوقات العمل طوال الأسبوع على مدار 24 ساعة .
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>
              هل تغطي وثيقة التأمين الإلزامي (ضد الغير) أي سائق يقل عمره ع عن 18 عامًا (هجري) ؟
            </span>
          </h3>
          <p class="faqs__answer">
            وفقاً للوثيقة الموحدة للتأمين الإلزامي على المركبات ، لا تغطي الوثيقة أي سائق يقل عمره عن 18 عامًا (هجري). ولكي يتم تغطية السائق الذي يقل عمره عن 18 عامًا (هجري) يجب أن يقوم المؤمن له بإضافة هذا السائق ضمن قائمة السائقين الإضافيين حتى يتم تغطيتهم.
          </p>
        </article>
      </div>
    </section>

    <section id="after-sales">
      <div class="container">
        <h2 class="faqs__subHeading">بعد عملية الشراء</h2>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>هل يجب أن أطبع وثيقة التأمين التي اشتريتها من منصة قارن ؟</span>
          </h3>
          <p class="faqs__answer">
            على الرغم من أن شركة نجم والتي تباشر الحوادث تقوم بالاستعلام عن وثيقة التأمين للمركبة إلكترونياً، إلا أننا ننصح ونحث عملائنا على طباعة وثيقة التأمين والاحتفاظ بها في المركبة دوماً في حال تم طلبها من رجل المرور أو غيره .
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>نسيت كلمة المرور الخاصة بي؟ ماذا أعمل؟</span>
          </h3>
          <p class="faqs__answer">
            لإعادة تهيئة كلمة المرور يرجى اتباع الخطوات التالية:
            <ul>
              <li>1.	الدخول لنافذة تسجيل الدخول والضغط على "نسيت كلمة المرور"</li>
              <li>2.	إدخال بريدك الإلكتروني المسجل في منصة قارن</li>
              <li>3.	الضغط على زر "إرسال"</li>
              <li>4.	سيتم إرسال رسالة إلى بريدك الإلكتروني المسجل، يرجى الضغط على رابط تغيير كلمة المرور المرسل لك.</li>
              <li>5.	ستفتح نافذة تحديث كلمة المرور.</li>
              <li>6.	قم بإدخال كلمة مرور الجديدة.</li>
            </ul>
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>هل أستطيع تغيير عنوان البريد الإلكتروني الخاص بي؟</span>
          </h3>
          <p class="faqs__answer">
            نعم، يمكن تغيير البريد الإلكتروني وذلك باتباع الخطوات التالية:
            <ul>
              <li>1.	الدخول لحسابك.</li>
              <li>2.	الضغط على حسابي.</li>
              <li>3.	الضغط على "الإعدادات" من القائمة.</li>
              <li>4.	الضغط على أيقونة تعديل البريد الإلكتروني في خانة "البريد الإلكتروني".</li>
            </ul>
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>هل يمكنني الرجوع لحسابي لاحقاً وطباعة الوثيقة؟</span>
          </h3>
          <p class="faqs__answer">
            نعم، يمكنك في أي وقت الرجوع للحساب وطباعة وثيقة التأمين التي تم شرائها.
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>هل أستطيع تغيير رقم الجوال؟</span>
          </h3>
          <p class="faqs__answer">
            نعم، يمكنك تغيير رقم جوالك وذلك باتباع الخطوات التالية:
            <ul>
              <li>1.	الدخول لحسابك </li>
              <li>2.	الضغط على حسابي</li>
              <li>3.	الضغط على الإعدادات من القائمة   </li>
              <li>4.	الضغط على أيقونة تعديل رقم الجوال في خانة " رقم الجوال". سيتم التحقق من صحة رقم جوالك الجديد إرسال رسالة نصية تحتوي على رمز للتفعيل.</li>
            </ul>
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>ما المقصود بـ “وثائق سارية المفعول” والموجودة عند الدخول لحسابي في منصة قارن ؟</span>
          </h3>
          <p class="faqs__answer">
            هي وثائق التأمين التي قمت بشرائها سابقاً من منصة قارن ولم تنتهي بعد صلاحية (فترة) تغطية التأمين لها.
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>
              ما المقصود بـ "وثائق منتهية الصلاحية" والموجودة عند الدخول لحسابي في منصة قارن ؟
            </span>
          </h3>
          <p class="faqs__answer">
            هي وثائق التأمين التي قمت بشرائها سابقاً من منصة قارن والتي انتهت صلاحية (فترة) تغطية التأمين لها.
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>
              ما المقصود بـ "وثائق قاربت على الانتهاء" والموجودة عند الدخول لحسابي في منصة قارن ؟
            </span>
          </h3>
          <p class="faqs__answer">
            هي وثائق التأمين التي قمت بشرائها سابقاً من منصة قارن والتي تبقى فترة زمنية قصيرة على انتهاء صلاحية (فترة) التغطية لها. في هذه الحالة يتوجب على مالك المركبة (المؤمن له) بشراء/تجديد وثيقة التأمين من منصة قارن للحصول على اسعار مميزة .
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>
              هل بعد عملية الشراء تكون الوثيقة سارية المفعول؟
            </span>
          </h3>
          <p class="faqs__answer">
            تعتبر وثيقة التأمين سارية المفعول فقط من تاريخ بدء (سريان) الوثيقة والموضح في وثيقة التأمين.
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>
              ماهي "قائمة الاختيار السريع" الموجودة في صفحة المركبات والهويات المسجلة؟
            </span>
          </h3>
          <p class="faqs__answer">
            هي قائمة تتضمن على العناصر (الهويات المسجلة، المركبات، العناوين) التي قمت بإضافتها مسبقاً في "قارن" لتسهيل عملية الشراء مستقبلاً دون الحاجة إلى إدخال البيانات مرةً أخرى.
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>
              هل أستطيع إلغاء الوثيقة بعد شراءها من منصة قارن ؟
            </span>
          </h3>
          <p class="faqs__answer">
            لا يمكن إلغاء وثيقة التأمين عن طريق منصة قارن ، ويلزم مراجعة شركة التأمين المعنية للقيام بذلك.
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>
              هل منصة قارن تقوم بتعويضي عن المطالبات؟
            </span>
          </h3>
          <p class="faqs__answer">
            لا ، منصة قارن تختص فقط في بيع وثائق التأمين.
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>
              قمت بتعديل/تغيير/إلغاء وثيقة التأمين مع شركة التأمين المعنية، لم أرى هذا التغيير في منصة قارن ؟
            </span>
          </h3>
          <p class="faqs__answer">
            في حال قام مالك الوثيقة بإجراء أي تعديل على وثيقته لاحقاً مع شركة التأمين المعنية، فإن هذا التغيير لن يحدث في منصة قارن ويعتبر ما تم تغييره من شركة التأمين هو الساري والمعمول به.
          </p>
        </article>
        <article class="faqs__faq">
          <h3 class="faqs__question">
            <figure class="faqs__icon">
              <img class="faqs__icon__light" src="@qariin-fe/src/images/svg/arrow-down-white.svg" alt="Arrow down" />
              <img class="faqs__icon__dark" src="@qariin-fe/src/images/svg/arrow-down.svg" alt="Arrow down" />
            </figure>
            <span>
              هل أستطيع حذف هوية أو مركبة مسجلة عن طريق حسابي في منصة قارن  ؟
            </span>
          </h3>
          <p class="faqs__answer">
            يمكن ذلك من خلال الدخول لحسابك والتوجه إلى الهويات المسجلة أو المركبات المسجلة، واختيار أيقونة الحذف بجانب المعلومة المراد حذفها.
          </p>
        </article>
      </div>
    </section>
  </app-layout>
</template>

<script>
import { Head } from '@inertiajs/inertia-vue3'
import AppLayout from "@/Layouts/AppLayout.vue";
export default {
    components: {
        AppLayout,
        Head,
    },
}
</script>
