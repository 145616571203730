<template>
    <app-layout>
        <Head>
            <title>About us | Qariin</title>
        </Head>
        <section class="about__poster">
            <div class="container relative z-10">
                <figure class="max-w-lg mx-auto py-10">
                    <img
                        src="@qariin-fe/src/images/about-us/poster.svg"
                        alt="Main poster"
                        class="max-w-full max-h-full"
                    />
                </figure>
            </div>
        </section>

        <section class="about-us">
            <div class="container text-center">
                <h1 class="text-xl lg:text-3xl text-black mb-5">من نحن</h1>

                <p class="text-secondary my-3 max-w-2xl mx-auto">
                    شـركة سـعودية نشأت فكرتها من مـجال التـحول الرقمي في مـجال
                    التأمين وتـماشياً مع رؤية المملكة العربية السعودية 2030
                    للتحول الـرقمي في جـميع الأنشطة ، وسعي مؤسسي الشركة
                    للإستثمار في مجال التأمين وذلك بعد الـجهود المباركة من البنك
                    المركزي السعودي لتطوير مجال التأمين والشفافية في النتائج و
                    التقارير في سوق التأمين ومن ناحية تقديم الخدمات للعملاء
                    والمنافسة السوقية العادلة ووجود كوادر وطنية تفوق خبراتهم 15
                    سنة لتقديم خدمات وخبرات وطنية تـساهم في نجاح الشركة وشركائها
                    بكل شفافية ومهنية.
                </p>
                <p class="text-secondary my-3 max-w-2xl mx-auto">
                    نملك المهنيـــة الــتي تؤهلنا لإختيـــار الخدمات الملائمـــة
                    لعملائنا ، وبالتالي نتجنـــب التعقيـــدات المحتملة عنـــد
                    المطالبات ونوفر للعملاء التغطيـــة التأمينية المثلـــى
                    والتـــي تتـــلائم مــع احتياجاتـــه الخاصـــة بأسعار منافسة
                    في الوقت والإسلوب المناسب، ومن هنا وجدت شركة اثنان واربعون.
                </p>
            </div>
        </section>

        <section class="my-12 features">
            <div class="container">
                <h1 class="text-xl lg:text-3xl text-black mb-5 text-center">
                    مميزات قارن
                </h1>

                <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
                    <div class="about__features">
                        <img
                            src="@qariin-fe/src/images/about-us/independent-consulting.svg"
                            alt="independent-consulting"
                        />
                        <span class="text-sm text-secondary"
                            >الإستشارات المستقلة.</span
                        >
                    </div>
                    <div class="about__features">
                        <img
                            src="@qariin-fe/src/images/about-us/insurance-experiences.svg"
                            alt="insurance-experiences"
                        />
                        <span class="text-sm text-secondary"
                            >الخبرات التأمينية.</span
                        >
                    </div>
                    <div class="about__features">
                        <img
                            src="@qariin-fe/src/images/about-us/market-statistics.svg"
                            alt="market-statistics"
                        />
                        <span class="text-sm text-secondary"
                            >دراسات السوق.</span
                        >
                    </div>
                    <div class="about__features">
                        <img
                            src="@qariin-fe/src/images/about-us/tailor-made-solutions.svg"
                            alt="tailor-made-solutions"
                        />
                        <span class="text-sm text-secondary"
                            >حلول تـصمم خصيصا لك.</span
                        >
                    </div>
                    <div class="about__features">
                        <img
                            src="@qariin-fe/src/images/about-us/insurance-quotes.svg"
                            alt="insurance-quotes"
                        />
                        <span class="text-sm text-secondary"
                            >إصدار عروض الأسعار التأمينية وإصدار الوثائق.</span
                        >
                    </div>
                    <div class="about__features">
                        <img
                            src="@qariin-fe/src/images/about-us/more-than-one-insurance.svg"
                            alt="more-than-one-insurance"
                        />
                        <span class="text-sm text-secondary"
                            >توفير أكثر من منتج تأميني في منصة قارن.</span
                        >
                    </div>
                    <div class="about__features">
                        <img
                            src="@qariin-fe/src/images/about-us/risk-management.svg"
                            alt="risk-management"
                        />
                        <span class="text-sm text-secondary"
                            >إدارة المخاطر.</span
                        >
                    </div>
                    <div class="about__features">
                        <img
                            src="@qariin-fe/src/images/about-us/primary-services.svg"
                            alt="primary-services"
                        />
                        <span class="text-sm text-secondary"
                            >خدمات التميز.</span
                        >
                    </div>
                    <div class="about__features">
                        <img
                            src="@qariin-fe/src/images/about-us/secrecy.svg"
                            alt="secrecy"
                        />
                        <span class="text-sm text-secondary"
                            >الموثوقية وسرية البيانات.</span
                        >
                    </div>
                    <div class="about__features">
                        <img
                            src="@qariin-fe/src/images/about-us/secrecy.svg"
                            alt="support"
                        />
                        <span class="text-sm text-secondary"
                            >خدمة العناية بالعملاء.</span
                        >
                    </div>
                </div>
            </div>
        </section>

        <section class="vision">
            <div class="container">
                <article class="about__vision">
                    <div class="about__vision__content">
                        <h2 class="text-black">رؤيتنا:</h2>
                        <p class="text-secondary">
                            ﺃﻥ ﻧﻜﻮﻥ ﺷﺮﻳﻜﺎً ﺍﺳﺘﺮﺍﺗﻴﺠﻴﺎً ﻓـــﻲ ﻣﺠﺎﻝ ﺍﻟﺘﺄﻣﻴﻦ
                            ﺍﻹﻟﻜﺘﺮﻭﻧﻲ ﻭﺍﻹﺧﺘﻴﺎﺭ ﺍﻷﻭﻝ ﻟﻠﻌﻤﻼﺀ، ﻭﺍﻥ ﻧﻜﻮﻥ ﺩﺍﻋﻤﻴﻦ
                            ﻟﻠﺘﺤﻮﻝ ﺍﻟﺮﻗﻤﻲ ، ﻭﺫﻟﻚ ﺗﻤﺎﺷﻴﺎً ﻣـــﻊ ﺭﺅﻳﺔ .ﺍﻟﻤﻤﻠﻜﺔ
                            ﺍﻟﻌﺮﺑﻴﺔ ﺍﻟﺴﻌﻮﺩﻳﺔ ٢٠٣٠
                        </p>
                        <h2 class="text-black mt-6">رسالتنا:</h2>
                        <p class="text-secondary">
                            ﺃﻥ ﻧﻜﻮﻥ ﺍﻷﻛﺜﺮ ﺗﻤﻴﺰ ﻭﺃﺻﺎﻟﺔ ﻭﺟﺪﺍﺭﺓ ﺑﺎﻟﺜﻘﺔ ﻓﻲ ﺗﻘﺪﻳﻢ
                            ﺍﻟﺤﻠﻮﻝ ﺍﻟﺘﺄﻣﻴﻨﻴﺔ ﺍﻟﻤﺘﺨﺼﺼﺔ ﺫﺍﺕ ﺣﻤﺎﻳﺔ ﺗﺄﻣﻴﻨﻴﺔ ﻋﺎﻟﻴﺔ
                            ﺍﻟﺠﻮﺩﺓ ﻟﻌﻤﻼﺋﻨﺎ ﻭﺷـــﺮﻛﺎﺋﻨﺎ ﻭﺍﻟﺴﻮﻕ ﺍﻟﻤﺤﻠﻲ.
                        </p>
                    </div>
                    <figure>
                        <img
                            src="@qariin-fe/src/images/about-us/vision-and-mision.svg"
                            alt="Vision and Mision"
                        />
                    </figure>
                </article>
            </div>
        </section>

        <section class="about__values">
            <div class="container">
                <h2 class="text-xl lg:text-3xl mb-8 text-black">قيمنا:</h2>
                <article
                    class="flex flex-col lg:flex-row items-start justify-between"
                >
                    <ul>
                        <li class="text-lg py-2">
                            <span class="text-black pe-2 font-bold"
                                >الأمانة:</span
                            >
                            <span class="text-secondary"
                                >تقديم خدماتنا بكل وضوح وشفافية مع عملائنا
                                وشركائنا ومنظومة العمل.</span
                            >
                        </li>
                        <li class="text-lg py-2">
                            <span class="text-black pe-2 font-bold"
                                >النزاهة:</span
                            >
                            <span class="text-secondary"
                                >الحرص على النزاهة مع جميع أصحاب العلاقة
                                والمصلحة.</span
                            >
                        </li>
                        <li class="text-lg py-2">
                            <span class="text-black pe-2 font-bold"
                                >الشفافية:</span
                            >
                            <span class="text-secondary"
                                >الوضوح والدقة في جميع تعاملاتنا.</span
                            >
                        </li>
                        <li class="text-lg py-2">
                            <span class="text-black pe-2 font-bold"
                                >الاحترام:</span
                            >
                            <span class="text-secondary"
                                >نحترم ونقدر أصحاب العلاقة والمصلحة.</span
                            >
                        </li>
                        <li class="text-lg py-2">
                            <span class="text-black pe-2 font-bold"
                                >العناية بالعملاء:</span
                            >
                            <span class="text-secondary"
                                >ينصب اهتمامنا على تقديم أفضل الخدمات
                                للعملاء.</span
                            >
                        </li>
                        <li class="text-lg py-2">
                            <span class="text-black pe-2 font-bold"
                                >العمل:</span
                            >
                            <span class="text-secondary"
                                >نعمل في بيئة ناجحة نتقاسم من خلالها المسؤولية
                                والنتائج.</span
                            >
                        </li>
                    </ul>
                    <figure
                        class="about__values__poster w-full max-w-xs h-full"
                    >
                        <img
                            src="@qariin-fe/src/images/about-us/values.svg"
                            alt="Values"
                        />
                    </figure>
                </article>
            </div>
        </section>
    </app-layout>
</template>

<script>
import { Head } from '@inertiajs/inertia-vue3'
import AppLayout from "@/Layouts/AppLayout.vue";
export default {
    components: {
        AppLayout,
        Head,
    },
};
</script>

<style></style>
