<template>
    <app-layout title="Email Verification">
        <Head>
            <title>Verify email | Qariin</title>
        </Head>
        <jet-authentication-card simple>
            <template #logo>
                <img
                    class="w-48 h-48 mx-auto object-contain"
                    src="@qariin-fe/src/images/verify-email.jpg"
                    alt="Verify Email"
                />
            </template>

            <h2 class="text-black text-center text-3xl mb-8">
                قم بتأكيد بريدك الألكتروني
            </h2>

            <div class="mb-4 text-tertiary max-w-lg mx-auto text-center">
                لقد أرسلنا رسالة إلكترونية للتحقق من عنوان بريدك الإلكتروني
                وتفعيل حسابك. ستنتهي صلاحية الرابط الموجود في البريد الإلكتروني
                خلال 24 ساعة.
            </div>

            <div
                class="mb-4 font-medium text-sm text-green-600 text-center"
                v-if="verificationLinkSent"
            >
                تم ارسال كود تفعيل جديد.
            </div>

            <form
                @submit.prevent="submit"
                class="max-w-sm mx-auto pb-12 text-center"
            >
                <div class="mt-4 flex flex-col items-center justify-between">
                    <div class="text-sm">
                        <jet-button
                            class="inline font-bold"
                            :class="{ 'opacity-25': timerEnabled }"
                            :disabled="timerEnabled"
                        >
                            انقر هنا
                        </jet-button>
                        <p class="inline text-secondary">
                            إذا لم تكن قد تلقيت بريدًا إلكترونيًا أو كنت ترغب في
                            تغيير عنوان البريد الإلكتروني الذي قمت بالتسجيل به.
                        </p>
                    </div>
                    <span v-show="timerEnabled" class="inline-block mt-8">
                        يمكنك ارسال بريد تفعيل اخر بعد {{ timerCount }}
                    </span>
                </div>
            </form>
        </jet-authentication-card>
    </app-layout>
</template>

<script>
import { Head } from '@inertiajs/inertia-vue3'
import { defineComponent } from "vue";
import JetAuthenticationCard from "@/Jetstream/AuthenticationCard.vue";
import JetButton from "@/Jetstream/Button.vue";
import AppLayout from "@/Layouts/AppLayout.vue";
import axios from "axios";

export default defineComponent({
    components: {
        AppLayout,
        Head,
        JetAuthenticationCard,
        JetButton,
    },

    data() {
        return {
            form: this.$inertia.form(),
            verificationLinkSent: false,
            timerEnabled: false,
            timerCount: 60,
        };
    },

    watch: {
        timerEnabled(value) {
            if (value) {
                setTimeout(() => {
                    this.timerCount--;
                }, 1000);
            }
        },

        timerCount: {
            handler(value) {
                if (value > 0 && this.timerEnabled && value <= 60) {
                    setTimeout(() => {
                        this.timerCount--;
                    }, 1000);
                } else {
                    this.timerEnabled = false;
                    this.timerCount = 60;
                }
            },
            immediate: true, // This ensures the watcher is triggered upon creation
        },
    },

    methods: {
        submit() {
            this.timerEnabled = true;
            axios.post(route("verification.send")).then(() => {
                this.verificationLinkSent = true;
            });
        },
    },
});
</script>
