<template>
    <app-layout>
        <main class="staticPage">
            <div class="container">
                <h1 class="text-xl lg:text-3xl text-black mt-16">
                    تأمين النقل البري للبضائع ( اخطار الطريق)
                </h1>
                <section class="staticPage__info">
                    <div class="mt-8">
                        <p class="text-secondary max-w-md">
                            تغطي هذة الوثيقة المؤمن له عن اخطار الطريق فقط وهي
                            اخطار الحريق او التصادم او الانقلاب .للوحدة الناقلة
                            حسب ما تقضيه الوثيقة
                        </p>
                        <a href="/" class="btn mt-8">قارن الان</a>
                    </div>
                    <img
                        class="staticPage__poster"
                        src="@qariin-fe/src/images/static-pages/land-transport-insurance-for-goods.png"
                        srcset="
                            @qariin-fe/src/images/static-pages/land-transport-insurance-for-goods.png    400w,
                            @qariin-fe/src/images/static-pages/land-transport-insurance-for-goods@2x.png 800w
                        "
                        sizes="(max-width: 678px) 400px, 800px"
                        alt="main poster"
                    />
                </section>
            </div>
        </main>
    </app-layout>
</template>

<script>
import AppLayout from "@/Layouts/AppLayout.vue";

export default {
    name: "BreachOfTrustInsurance",
    components: {
        AppLayout,
    },
};
</script>
