<template>
    <app-layout title="Register">
        <jet-authentication-card>
            <jet-validation-errors class="mb-4" />

            <div class="container flex justify-center text-center">
                <form @submit.prevent="submit">
                    <h1 class="text-2xl lg:text-3xl mb-6">اضف رقم الجوال</h1>

                    <fieldset class="input mobile">
                        <jet-label for="mobile" value="رقم جوال" />
                        <jet-input
                            type="text"
                            maxlength="10"
                            id="mobile"
                            required
                            name="mobile"
                            v-model="form.mobile"
                            placeholder="اكتب رقم الجوال هنا..."
                        />
                    </fieldset>

                    <jet-button
                        type="submit"
                        class="bg-primary text-white w-64 rounded-md mt-3"
                        :class="{ 'opacity-25': form.processing }"
                        :disabled="form.processing"
                        >إضافه الجوال</jet-button
                    >
                </form>
            </div>
        </jet-authentication-card>
    </app-layout>
</template>

<script>
import { defineComponent } from "vue";
import JetAuthenticationCard from "@/Jetstream/AuthenticationCard.vue";
import JetButton from "@/Jetstream/Button.vue";
import JetInput from "@/Jetstream/Input.vue";
import JetLabel from "@/Jetstream/Label.vue";
import JetValidationErrors from "@/Jetstream/ValidationErrors.vue";
import AppLayout from "@/Layouts/AppLayout.vue";

export default defineComponent({
    components: {
        AppLayout,
        JetAuthenticationCard,
        JetButton,
        JetInput,
        JetLabel,
        JetValidationErrors,
    },

    data() {
        return {
            form: this.$inertia.form({
                mobile: "",
            }),
        };
    },

    methods: {
        submit() {
            this.form.post(this.route("add.mobile"));
        },
    },
});
</script>
