<style lang="scss">
#cpatchaTextBox {
    padding: 12px 20px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}
#buttonCaptcha {
    background-color: #4caf50;
    border: none;
    color: white;
    padding: 12px 30px;
    text-decoration: none;
    margin: 4px 2px;
    cursor: pointer;
}
canvas {
    /*prevent interaction with the canvas*/
    pointer-events: none;
}
</style>
<template>
    <Head>
        <title>Qariin - Electronic Insurance Brokerage</title>
        <link rel="icon" type="image/x-icon" :href="faviconPath">
        <meta head-key="description" name="description" content="شـركة سـعودية نشأت فكرتها من مـجال التـحول الرقمي في مـجال التأمين وتـماشياً مع رؤية المملكة العربية السعودية 2030 للتحول الـرقمي في جـميع الأنشطة ، وسعي مؤسسي الشركة للإستثمار في مجال التأمين وذلك بعد الـجهود المباركة من البنك المركزي السعودي لتطوير مجال التأمين والشفافية في النتائج و التقارير في سوق التأمين ومن ناحية تقديم الخدمات للعملاء والمنافسة السوقية العادلة ووجود كوادر وطنية تفوق خبراتهم 15 سنة لتقديم خدمات وخبرات وطنية تـساهم في نجاح الشركة وشركائها بكل شفافية ومهنية. نملك المهنيـــة الــتي تؤهلنا لإختيـــار الخدمات الملائمـــة لعملائنا ، وبالتالي نتجنـــب التعقيـــدات المحتملة عنـــد المطالبات ونوفر للعملاء التغطيـــة التأمينية المثلـــى والتـــي تتـــلائم مــع احتياجاتـــه الخاصـــة بأسعار منافسة في الوقت والإسلوب المناسب، ومن هنا وجدت شركة اثنان واربعون." />
    </Head>

    <!-- Page Heading -->
    <header v-if="!disable" class="header">
        <div class="container">
            <nav class="header__nav">
                <a :href="route('home')">
                    <img
                        src="@qariin-fe/src/images/svg/logo-colored.svg"
                        alt="LOGO"
                        class="w-36 lg:w-44"
                    />
                </a>
                <ul class="header__routes">
                    <li>
                        <a href="/about-us" class="px-xs whitespace-nowrap">
                            {{ $t('header_about') }}
                        </a>
                    </li>

                    <li class="dropdown">
                        <a class="whitespace-nowrap">
                            {{ $t('header_services') }}
                        </a>
                        <img
                            class="w-3 h-3 ms-2"
                            src="~@qariin-fe/src/images/svg/arrow-down.svg"
                            alt="arrow-down"
                        />
                        <ul>
                            <li>
                                <a href="/vehicle-insurance">
                                    {{ $t('header_services_motor') }}
                                </a>
                            </li>
                            <li>
                                <a href="/medical-insurance">
                                    {{ $t('header_services_medical') }}
                                </a>
                            </li>
                            <li>
                                <a href="/medical-malpractice-insurance">
                                    {{ $t('header_services_medical_malpractice') }}
                                </a>
                            </li>
                            <li>
                                <a href="/travel-insurance">
                                    {{ $t('header_services_travel') }}
                                </a>
                            </li>
                            <li>
                                <a href="/domestic-labor-insurance">
                                    {{ $t('header_services_domestic') }}
                                </a>
                            </li>
                            <!-- <li>
                                <a href="/road-services">خدمة المساعدة على الطريق</a>
                            </li> -->
                        </ul>
                    </li>
                    <li>
                        <a
                            href="/user/profile"
                            class="px-xs whitespace-nowrap"
                        >
                            {{ $t('header_print') }}
                        </a>
                    </li>
                    <li>
                        <a href="/request-accident" class="px-xs whitespace-nowrap">
                            {{ $t('header_upload') }}
                        </a>
                    </li>
                    <li>
                        <a href="/cancel-policy" class="px-xs whitespace-nowrap">
                            {{ $t('header_cancel') }}
                        </a>
                    </li>
                    <li v-if="$page.props.user">
                        <a href="/user/profile#support" class="px-xs whitespace-nowrap">
                            {{ $t('header_contact') }}
                        </a>
                    </li>
                    <li v-else>
                        <a href="/contact-us" class="px-xs whitespace-nowrap">
                            {{ $t('header_contact') }}
                        </a>
                    </li>
                    <li>
                        <a
                            href="/faqs"
                            class="px-xs whitespace-nowrap"
                        >
                            {{ $t('header_faqs') }}
                        </a>
                    </li>
                </ul>
                <div class="header__buttons">
                    <button>
                        <a
                            v-if="$page.props.user"
                            :href="route('profile.show')"
                            class="me-2"
                        >
                            <img
                                src="@qariin-fe/src/images/svg/user.svg"
                                alt="Profile"
                                class="w-6"
                            />
                        </a>
                        <a
                            class="me-2"
                            :href="route('change.language',currentLang == 'en' ? 'ar' : 'en')"
                        >
                            <span>
                                {{  currentLang == "en" ? "Ar" : "En"  }}
                            </span>
                        </a>
                        <a
                            v-if="$page.props.user"
                            class="p-2"
                            @click.prevent="logout"
                        >
                            <img :src="require('@qariin-fe/src/images/profile/logout.svg').default" alt="Logout" class="w-6">
                        </a>
                        <a
                            v-if="!$page.props.user"
                            :href="route('login')"
                            class="p-2"
                        >
                            {{ $t('header_login') }}
                        </a>
                    </button>
                    <button class="header__buttons__toggler">
                        <a href="#">
                            <img
                                src="@qariin-fe/src/images/svg/menu.svg"
                                alt="Mobile Menu"
                                class="w-6"
                            />
                        </a>
                    </button>
                </div>
                <div class="header__mobile identity">
                    <button class="header__mobile__close">&times;</button>
                    <button class="header__mobile__back invisible">
                        <img
                            class="w-8 h-8 transform -rotate-90"
                            src="~@qariin-fe/src/images/svg/arrow-down.svg"
                            alt="arrow-down"
                        />
                    </button>
                    <ul class="header__mobile__top">
                        <li>
                            <a href="/about-us" class="px-xs whitespace-nowrap">
                                {{ $t('header_about') }}
                            </a>
                        </li>
                        <li data-child="products">
                            <div class="flex items-center justify-between">
                                <a
                                    href="#"
                                    class="header__mobile__hasChildren px-xs whitespace-nowrap">
                                    {{ $t('header_services') }}
                                </a>
                                <img
                                    class="w-8 h-8 transform rotate-90 me-2"
                                    src="~@qariin-fe/src/images/svg/arrow-down.svg"
                                    alt="arrow-down"
                                />
                            </div>
                            <ul
                                data-child-name="products"
                                class="header__mobile__child"
                            >
                                <li>
                                    <a href="/vehicle-insurance">
                                        {{ $t('header_services_motor') }}
                                    </a>
                                </li>
                                <li>
                                    <a href="/medical-insurance">
                                        {{ $t('header_services_medical') }}
                                    </a>
                                </li>
                                <li>
                                    <a href="/medical-malpractice-insurance">
                                        {{ $t('header_services_medical_malpractice') }}
                                    </a>
                                </li>
                                <li>
                                    <a href="/travel-insurance">
                                        {{ $t('header_services_travel') }}
                                    </a>
                                </li>
                                <li>
                                    <a href="/domestic-labor-insurance">
                                        {{ $t('header_services_domestic') }}
                                    </a>
                                </li>
                                <!-- <li>
                                    <a href="/road-services"
                                        >خدمة المساعدة على الطريق</a
                                    >
                                </li> -->
                            </ul>
                        </li>
                        <li data-child="general">
                            <div class="flex items-center justify-between">
                                <a
                                    href="/soon"
                                    class="header__mobile__hasChildren px-xs whitespace-nowrap">
                                    {{ $t('header_general_insurance') }}
                                </a>
                                <img
                                    class="w-8 h-8 transform rotate-90 me-2"
                                    src="~@qariin-fe/src/images/svg/arrow-down.svg"
                                    alt="arrow-down"
                                />
                            </div>
                            <!-- <ul
                                data-child-name="general"
                                class="header__mobile__child"
                            >
                                <li>
                                    <a href="/travel-care-insurance"
                                        >تأمين رعاية السفر</a
                                    >
                                </li>
                                <li>
                                    <a href="/marine-insurance"
                                        >التأمين البحري – بضائع – شحنة فردية</a
                                    >
                                </li>
                                <li>
                                    <a href="/breach-of-trust-insurance"
                                        >تأمين خيانة الأمانة</a
                                    >
                                </li>
                                <li>
                                    <a href="/marine-insurance-open"
                                        >التأمين البحري – بضائع – عقد مفتوح</a
                                    >
                                </li>
                                <li>
                                    <a href="/medical-malpractice-insurance"
                                        >تأمين اخطاء ممارسة المهن الطبية</a
                                    >
                                </li>
                                <li>
                                    <a href="/fire-insurance">تأمين الحريق</a>
                                </li>
                                <li>
                                    <a href="/workers-injury-insurance"
                                        >تأمين اصابة العمال</a
                                    >
                                </li>
                                <li>
                                    <a href="/soon">تأمين المسكن</a>
                                </li>
                                <li>
                                    <a href="/domestic-labor-insurance"
                                        >تأمين حماية عقود العمالة المنزلية</a
                                    >
                                </li>
                                <li>
                                    <a href="/all-risks-insurance-and-property"
                                        >تأمين جميع الاخطار – الممتلكات</a
                                    >
                                </li>
                                <li>
                                    <a
                                        href="/land-transport-insurance-for-goods"
                                        >تأمين النقل البري للبضائع ( اخطار
                                        الطريق )</a
                                    >
                                </li>
                                <li>
                                    <a href="/shop-insurance"
                                        >تأمين المحلات التجارية</a
                                    >
                                </li>
                                <li>
                                    <a href="/risks-of-land-transport"
                                        >تأمين جميع اخطار النقل البري</a
                                    >
                                </li>
                                <li>
                                    <a href="/soon">تأمين الطاقة</a>
                                </li>
                            </ul> -->
                        </li>
                        <li>
                            <a href="/user/profile" class="px-xs whitespace-nowrap">
                                {{ $t('header_print') }}
                            </a>
                        </li>
                        <li>
                            <a href="/cancel-policy" class="px-xs whitespace-nowrap">
                                {{ $t('header_cancel') }}
                            </a>
                        </li>
                        <li>
                            <a href="/request-accidentcancel-policy" class="px-xs whitespace-nowrap">
                                {{ $t('header_upload') }}
                            </a>
                        </li>
                        <li v-if="$page.props.user">
                            <a href="/user/profile#support" class="px-xs whitespace-nowrap">
                                {{ $t('header_contact') }}
                            </a>
                        </li>
                        <li v-else>
                            <a href="/contact-us" class="px-xs whitespace-nowrap">
                                {{ $t('header_contact') }}
                            </a>
                        </li>
                        <li>
                            <a
                                href="/faqs"
                                class="px-xs whitespace-nowrap"
                            >
                                {{ $t('header_faqs') }}
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </header>
    <!-- Page Content -->
    <main>
        <slot></slot>
    </main>

    <footer
        v-if="!disable"
        class="footer"
        :style="`--background: url(${
            require('@qariin-fe/src/images/footer-bg.png').default
        })`"
    >
        <div class="container relative">
            <div class="footer__bg"></div>
            <div class="flex flex-col lg:flex-row items-start justify-between">
                <div class="footer__links">
                    <div class="footer__links__lane hidden lg:block me-2">
                        <!-- <img
                            src="@qariin-fe/src/images/footer-flag.png"
                            class="w-56 h-56 object-contain"
                            alt="Footer flag"
                        /> -->
                    </div>
                    <div class="footer__links__lane">
                        <h3 class="font-semibold text-white">
                            {{ $t('footer_support') }}
                        </h3>
                        <a href="mailto:support@qariin.com">
                            support@qariin.com
                        </a>
                        <a href="tel:8001111051">8001111051</a>
                        <p class="flex items-start justify-start my-1">
                            <img
                                src="@qariin-fe/src/images/icons/location-pin.svg"
                                class="w-4 h-4"
                                alt="Location Icon"
                            />
                            <span class="text-xs">
                                {{ $t('footer_address') }}
                            </span>
                        </p>
                        <!-- <p class="flex items-start justify-start my-1">
                            <img
                                src="@qariin-fe/src/images/icons/location-pin.svg"
                                class="w-4 h-4"
                                alt="Location Icon"
                            />
                            <span class="text-xs">
                                الإدارة الإقليمية: الخبر - حي العقربية شارع
                                الأمير حمود
                            </span>
                        </p> -->
                    </div>
                    <div class="footer__links__lane">
                        <h3 class="font-semibold text-white">
                            {{ $t('footer_imp_links') }}
                        </h3>
                        <a target="__blank" href="https://splonline.com.sa/ar/national-address-1/">
                            {{ $t('footer_national_address') }}
                        </a>
                        <a target="__blank" href="https://najm.sa/sites/ar">
                            {{ $t('footer_najm') }}
                        </a>
                        <a target="__blank" href="https://chi.gov.sa/Pages/default.aspx">
                            {{ $t('footer_council') }}
                        </a>
                        <a target="__blank" href="https://www.sama.gov.sa/ar-sa/laws/pages/rules_and_regulations.aspx">
                            {{ $t('footer_sama') }}
                        </a>
                    </div>
                    <div class="footer__links__lane block lg:hidden">
                        <h3 class="font-semibold text-white">
                            {{ $t('footer_products') }}
                        </h3>
                        <a href="/vehicle-insurance">
                            {{ $t('header_services_motor') }}
                        </a>
                        <a href="/medical-insurance">
                            {{ $t('header_services_medical') }}
                        </a>
                        <a href="/medical-malpractice-insurance">
                            {{ $t('header_services_medical_malpractice') }}
                        </a>
                        <a href="/travel-insurance">
                            {{ $t('header_services_travel') }}
                        </a>
                        <a href="/domestic-labor-insurance">
                            {{ $t('header_services_domestic') }}
                        </a>
                    </div>
                    <div class="footer__links__lane">
                        <h3 class="font-semibold text-white">
                            {{ $t('footer_our_services') }}
                        </h3>
                        <!-- <a href="/road-services">خدمة المساعدة على الطريق</a> -->
                        <a v-if="$page.props.user" href="/user/profile">
                            {{ $t('header_print') }}
                        </a>
                        <a href="/cancel-policy">
                            {{ $t('header_cancel') }}
                        </a>
                        <a href="/request-accident">
                            {{ $t('header_upload') }}
                        </a>
                    </div>
                </div>

                <div class="footer__info">
                    <h3 class="text-sm mb-2">
                        <span>
                            {{ $t('footer_auth') }}
                        </span>
                    </h3>

                    <div class="flex items-center justify-start">
                        <a href="#" class="w-32 me-2">
                            <img
                                src="~@qariin-fe/src/images/google-play.svg"
                                alt="Central Bank"
                                class="max-h-full max-w-full"
                            />
                        </a>
                        <a href="#" class="w-32">
                            <img
                                src="~@qariin-fe/src/images/app-store.svg"
                                alt="App Store"
                                class="max-h-full max-w-full"
                            />
                        </a>
                    </div>

                    <h3 class="my-3 text-sm">
                        {{ $t('footer_download') }}
                    </h3>
                    <div class="flex items-center justify-start">
                        <a href="#" class="w-32 me-2">
                            <img
                                src="~@qariin-fe/src/images/central-bank.svg"
                                alt="Central Bank"
                                class="max-h-full max-w-full"
                            />
                        </a>
                        <a
                            href="#"
                            class="h-16 border-s border-primary-500 ms-3"
                        >
                            <img
                                src="~@qariin-fe/src/images/vision-2030.svg"
                                alt="Vision 2030"
                                class="max-h-full max-w-full px-xs"
                            />
                        </a>
                    </div>

                    <h3 class="text-sm mt-10 font-thin max-w-sm">
                        <!-- <span>مصرح من قبل</span> -->
                        <span>شركة اثنان واربعون الوسيط لوساطة التأمين الإلكتروني
                            مصرحة من قبل البنك المركزي السعودي لبيع
                            منتجاتها الإلكترونية في البيئة التجريبية
                        </span>
                    </h3>
                </div>
            </div>

            <div class="footer__socials">
                <a href="mailto:support@qariin.com" class="min-h-full w-4 h-4">
                    <img
                        src="~@qariin-fe/src/images/svg/email.svg"
                        alt="Email"
                        class="max-w-full max-h-full"
                    />
                </a>
                <a
                target="__blank"
                    href="https://www.instagram.com/qariinksa"
                    class="min-h-full w-4 h-4"
                >
                    <img
                        src="~@qariin-fe/src/images/svg/instagram-white.svg"
                        alt="Instagram"
                        class="max-w-full max-h-full"
                    />
                </a>
                <a
                target="__blank"
                    href="https://twitter.com/qariinksa"
                    class="min-h-full w-4 h-4"
                >
                    <img
                        src="~@qariin-fe/src/images/svg/twitter-white.svg"
                        alt="Twitter"
                        class="max-w-full max-h-full"
                    />
                </a>
                <a target="__blank" href="https://www.facebook.com/QariinKSA"  class="min-h-full w-4 h-4">
                    <img
                        src="~@qariin-fe/src/images/svg/facebook-white.svg"
                        alt="Facebook"
                        class="max-w-full max-h-full"
                    />
                </a>
                <a target="__blank" href="https://www.linkedin.com/company/%D9%82%D8%A7%D8%B1%D9%86-%D8%A7%D9%84%D9%88%D8%B3%D9%8A%D8%B7-%D9%84%D9%88%D8%B3%D8%A7%D8%B7%D8%A9-%D8%A7%D9%84%D8%AA%D8%A3%D9%85%D9%8A%D9%86-%D8%A7%D9%84%D8%A7%D9%84%D9%83%D8%AA%D8%B1%D9%88%D9%86%D9%8A/" class="min-h-full w-4 h-4">
                    <img
                        src="~@qariin-fe/src/images/svg/linkedin-white.svg"
                        alt="linkedin"
                        class="max-w-full max-h-full"
                    />
                </a>
            </div>

            <div class="footer__copyright">
                <div>
                    <a href="#" class="ps-2">
                        {{ $t('footer_career') }}
                    </a>
                    <a href="/terms-and-conditions" class="ps-2">
                        {{ $t('footer_conditions') }}
                    </a>
                    <a href="/privacy-and-policy" class="ps-2">
                        {{ $t('footer_privacy') }}
                    </a>
                    <a href="/faqs" class="ps-2">
                        {{ $t('footer_faqs') }}
                    </a>
                </div>
                <span>
                    {{ $t('footer_copy') }}
                </span>
            </div>
        </div>
    </footer>
    <a v-if="!disable" :href="authenticated ? '/user/profile#support' : '/contact-us'" class="w-[100px] h-[100px]  z-50 fixed bottom-[100px] right-[30px] rtl:right-auto rtl:left-[30px] rounded">
        <img src="~@qariin-fe/src/images/svg/support_icon.svg">
    </a>
</template>

<script>
import { getActiveLanguage } from 'laravel-vue-i18n';
import { defineComponent } from "vue";
import JetApplicationMark from "@/Jetstream/ApplicationMark.vue";
import JetBanner from "@/Jetstream/Banner.vue";
import JetDropdown from "@/Jetstream/Dropdown.vue";
import JetDropdownLink from "@/Jetstream/DropdownLink.vue";
import JetNavLink from "@/Jetstream/NavLink.vue";
import JetResponsiveNavLink from "@/Jetstream/ResponsiveNavLink.vue";
import { Head, Link } from "@inertiajs/inertia-vue3";

export default defineComponent({
    props: {
        title: String,
        disable: {
            type: Boolean,
            default: false,
        }
    },

    components: {
        Head,
        // eslint-disable-next-line vue/no-unused-components
        JetApplicationMark,
        // eslint-disable-next-line vue/no-unused-components
        JetBanner,
        // eslint-disable-next-line vue/no-unused-components
        JetDropdown,
        // eslint-disable-next-line vue/no-unused-components
        JetDropdownLink,
        // eslint-disable-next-line vue/no-unused-components
        JetNavLink,
        // eslint-disable-next-line vue/no-unused-components
        JetResponsiveNavLink,
        // eslint-disable-next-line vue/no-unused-components
        Link,
    },

    data() {
        return {
            showingNavigationDropdown: false,
            authenticated:this.$page.props.authenticated,
            currentLang : getActiveLanguage()
        };
    },

    computed: {
        faviconPath() {
            return require("@qariin-fe/src/images/favicon/favicon.png").default;
        }
    },

    mounted() {
        require("@qariin-fe/src/scripts/index.js");
        this.setActiveNavItem();
    },

    methods: {
        switchToTeam(team) {
            this.$inertia.put(
                route("current-team.update"),
                {
                    team_id: team.id,
                },
                {
                    preserveState: false,
                }
            );
        },

        logout() {
            this.$inertia.post(route("logout"));
        },
        setActiveNavItem(){
          var navElements = document.querySelectorAll("nav a");
          for(var i = 0 ; i < navElements.length;i++){
            navElements[i].addEventListener("click",function(){
                localStorage.setItem("last_active_item",this.href.substring(this.href.lastIndexOf('/')));
            });
          }
          this.loadActiveNavItem();
        },
        loadActiveNavItem(){
           var lastActiveItem =  localStorage.getItem("last_active_item");
            var activeElement = document.querySelector("a[href*='"+lastActiveItem+"']");
           if(activeElement){
                activeElement.classList.add("text-secondary-200");
            }
        }
    },
});
</script>
