<template>
    <Modal wide ref="modal" :title="$t('other_info_add_drivers_title')">
        <p
            class="max-w-xl text-secondary mx-auto mt-3 mb-6 text-xs text-center"
        >
            {{ $t('other_info_add_drivers_note') }}
        </p>

        <table v-if="!!drivers.length">
            <thead>
                <tr>
                    <th>
                        {{ $t('other_info_add_drivers_id') }}
                    </th>
                    <th>
                        {{ $t('other_info_add_drivers_bd') }}
                    </th>
                    <th>
                        {{ $t('other_info_add_drivers_ratio') }}
                    </th>
                    <th>
                        {{ $t('delete') }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(driver, index) in drivers" :key="driver.id">
                    <td>{{ driver.identity_number }}</td>
                    <td>
                        {{
                            driver.date_of_birth ??
                            driver.identity.date_of_birth
                        }}
                    </td>
                    <td dir="ltr">
                        {{
                            (driver.driving_percentage_code + "%")
                        }}
                    </td>
                    <td>
                        <form
                            v-if="!driver.main"
                            action="#"
                            @submit.prevent="deleteDriver(index, driver)"
                        >
                            <!-- Primary driver -->
                            <button class="text-danger">
                                <img
                                    :src="
                                        require('@qariin-fe/src/images/icons/delete-danger.svg')
                                            .default
                                    "
                                />
                            </button>
                        </form>
                    </td>
                </tr>
            </tbody>
        </table>
        <form v-if="!loading" @submit.prevent="submit()">
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
                <fieldset class="input-normal">
                    <label for="driver-id">
                        {{ $t('other_info_add_drivers_driver_id') }}
                    </label>
                    <input
                        ref="identityNumberInput"
                        v-model="input.identity_number"
                        type="number"
                        id="driver-id"
                        required
                        name="driver-id"
                        :placeholder="$t('other_info_add_drivers_driver_id_placeholder')"
                        maxlength="10"
                    />
                </fieldset>
                <fieldset v-if="!hijri" class="selectbox">
                    <label for="birth-date">
                        {{ $t('other_info_add_drivers_bd') }}
                    </label>
                    <div class="flex items-center">
                        <select
                            v-model="input.date_of_birth_dd"
                            class="date_select text-sm"
                        >
                            <option value="null" disabled>
                                {{ $t('day') }}
                            </option>
                            <option v-for="day in 31" :key="day" :value="day">
                                {{ day }}
                            </option>
                        </select>
                        <span> - </span>
                        <select
                            v-model="input.date_of_birth_mm"
                            class="date_select text-sm"
                        >
                            <option value="null" disabled>
                                {{ $t('month') }}
                            </option>
                            <option
                                v-for="month in 12"
                                :key="month"
                                :value="month"
                            >
                                {{ month }}
                            </option>
                        </select>
                        <span> - </span>
                        <select
                            v-model="input.date_of_birth_yy"
                            class="date_select text-sm"
                        >
                            <option value="null" disabled>
                                {{ $t('year') }}
                            </option>
                            <option
                                v-for="year in 100"
                                :key="year"
                                :value="new Date().getFullYear() - year"
                            >
                                {{ new Date().getFullYear() - year }}
                            </option>
                        </select>
                    </div>
                </fieldset>
                <fieldset v-else class="selectbox">
                    <label for="birth-date">
                        {{ $t('hijri_birth_date') }}
                    </label>
                    <div class="flex items-center">
                        <select
                            v-model="input.date_of_birth_dd"
                            class="date_select text-sm"
                        >
                            <option value="null" disabled>
                                {{ $t('day') }}
                            </option>
                            <option v-for="day in 30" :key="day" :value="day">
                                {{ day }}
                            </option>
                        </select>
                        <span> - </span>
                        <select
                            v-model="input.date_of_birth_mm"
                            class="date_select text-sm"
                        >
                            <option value="null" disabled>
                                {{ $t(month) }}
                            </option>
                            <option
                                v-for="month in 12"
                                :key="month"
                                :value="month"
                            >
                                {{ month }}
                            </option>
                        </select>
                        <span> - </span>
                        <select
                            v-model="input.date_of_birth_yy"
                            class="date_select text-sm"
                        >
                            <option value="null" disabled>
                                {{ $t('year') }}
                            </option>
                            <option
                                v-for="year in 100"
                                :key="year"
                                :value="currentHijriYear - year"
                            >
                                {{ currentHijriYear - year }}
                            </option>
                        </select>
                    </div>
                </fieldset>
                <fieldset
                    class="selectbox"
                    :style="`--icon: url('${
                        require('@qariin-fe/src/images/svg/arrow-down-secondary.svg')
                            .default
                    }');`"
                >
                    <label for="education">
                        {{ $t('other_info_add_drivers_education') }}
                    </label>
                    <select
                        v-model="input.educational_level_code"
                        name="education"
                        id="vehicle-modifications"
                    >
                        <option value="null" disabled>
                            {{ $t('choose') }}
                        </option>
                        <option
                            v-for="education in educations"
                            :key="education.code"
                            :value="education.code"
                        >
                            {{ education.arabic_description }}
                        </option>
                    </select>
                </fieldset>
                <fieldset
                    class="selectbox"
                    :style="`--icon: url('${
                        require('@qariin-fe/src/images/svg/arrow-down-secondary.svg')
                            .default
                    }');`"
                >
                    <label for="license-restrictions">
                        {{ $t('other_info_add_drivers_restrictions') }}
                    </label>
                    <select
                        v-model="input.medical_condition_code"
                        name="license-restrictions"
                        id="license-restrictions"
                    >
                        <option value="null" disabled>
                            {{ $t('choose') }}
                        </option>
                        <option
                            v-for="condition in medicalConditions"
                            :key="condition.code"
                            :value="condition.code"
                        >
                            {{ condition.arabic_description }}
                        </option>
                    </select>
                </fieldset>
                <fieldset
                    class="selectbox"
                    :style="`--icon: url('${
                        require('@qariin-fe/src/images/svg/arrow-down-secondary.svg')
                            .default
                    }');`"
                >
                    <label for="accidents-num">
                        {{ $t('other_info_add_drivers_title') }}
                    </label>
                    <select
                        v-model="input.accidents_number"
                        name="accidents-num"
                        id="accidents-num"
                    >
                        <option value="null" disabled>
                            {{ $t('choose') }}
                        </option>
                        <option
                            v-for="num in 10"
                            :key="num"
                            :value="num"
                        >
                            {{ num }}
                        </option>
                    </select>
                </fieldset>
                <fieldset
                    class="selectbox"
                    :style="`--icon: url('${
                        require('@qariin-fe/src/images/svg/arrow-down-secondary.svg')
                            .default
                    }');`"
                >
                    <label for="childs">
                        {{ $t('other_info_add_drivers_kids') }}
                    </label>
                    <select
                        v-model="input.children_under_sixteen"
                        name="childs"
                        id="childs"
                    >
                        <option value="null" disabled>
                            {{ $t('choose') }}
                        </option>
                        <option
                            v-for="num in 5"
                            :key="num"
                            :value="num"
                        >
                            {{ num < 5 ? num : $t('other_info_add_drivers_5_or_more') }}
                        </option>
                    </select>
                </fieldset>
                <fieldset
                    class="selectbox"
                    :style="`--icon: url('${
                        require('@qariin-fe/src/images/svg/arrow-down-secondary.svg')
                            .default
                    }');`"
                >
                    <label for="driving-skills">
                        {{ $t('other_info_add_drivers_ratio') }}
                    </label>
                    <select
                        v-model="input.driving_percentage_code"
                        name="driving-skills"
                        id="driving-skills"
                    >
                        <option value="null" disabled>
                            {{ $t('choose') }}
                        </option>
                        <option
                            v-for="percentage in drivingPercentages"
                            :key="percentage.code"
                            :value="percentage.code"
                        >
                            {{ percentage.arabic_description }}
                        </option>
                    </select>
                </fieldset>
                <fieldset
                    class="selectbox"
                    :style="`--icon: url('${
                        require('@qariin-fe/src/images/svg/arrow-down-secondary.svg')
                            .default
                    }');`"
                >
                    <label for="relative-relation">
                        {{ $t('other_info_add_drivers_relative') }}
                    </label>
                    <select
                        v-model="input.relation_with_user"
                        name="relative-relation"
                        id="relative-relation"
                    >
                        <option value="null" disabled>
                            {{ $t('choose') }}
                        </option>
                        <option
                            v-for="relation in Object.values($t('other_info_add_drivers_relative_options'))"
                            :key="relation"
                            :value="relation"
                        >
                            {{ relation }}
                        </option>
                    </select>
                </fieldset>
            </div>
            <div class="modals__addons">
                <button
                    type="button"
                    class="modals__addons__button"
                    @click="toggleAbroadLicenses"
                >
                    <span class="icon">+</span>
                    <span>
                        {{ $t('other_info_add_drivers_abroad_license') }}
                    </span>
                </button>
                <div v-if="showAbroadLicenses" class="px-xs">
                    <div
                        class="grid grid-cols-1 md:grid-cols-3 gap-3 place-content-start"
                        v-for="(item, index) in input.another_country_licenses"
                        :key="index"
                    >
                        <fieldset class="selectbox">
                            <label for="education">
                                {{ $t('country') }}
                            </label>
                            <select
                                v-model="
                                    input.another_country_licenses[index]
                                        .country_code
                                "
                                name="education"
                                id="vehicle-modifications"
                            >
                                <option value="null" disabled>
                                    {{ $t('choose') }}
                                </option>
                                <option
                                    v-for="country in countries"
                                    :key="country.code"
                                    :value="country.code"
                                >
                                    {{
                                        country.arabic_description
                                            ? country.arabic_description
                                            : country.english_description
                                    }}
                                </option>
                            </select>
                        </fieldset>
                        <fieldset class="selectbox">
                            <label for="accidents-num">
                                {{ $t('other_info_add_drivers_license_years') }}
                            </label>
                            <select
                                v-model="
                                    input.another_country_licenses[index].years
                                "
                                name="accidents-num"
                                id="accidents-num"
                            >
                                <option value="null" disabled>
                                    {{ $t('choose') }}
                                </option>
                                <option
                                    v-for="num in 20"
                                    :key="num"
                                    :value="num"
                                >
                                    {{ num }}
                                </option>
                            </select>
                        </fieldset>
                        <div>
                            <button
                                type="button"
                                class="modals__addons__button modals__addons__button--red whitespace-nowrap"
                                @click="removeLicense(index)"
                            >
                                <span class="icon">-</span>
                                <span> {{ $t('delete') }} </span>
                            </button>
                        </div>
                    </div>
                    <div class="mt-2 mb-6">
                        <button
                            type="button"
                            class="modals__addons__button modals__addons__button--green"
                            @click="addLicense()"
                        >
                            <span class="icon">+</span>
                            <span> {{ $t('other_info_add_drivers_add_license') }} </span>
                        </button>
                    </div>
                </div>
                <button
                    type="button"
                    class="modals__addons__button"
                    @click="toggleWorkAndHomeCity"
                >
                    <span class="icon">+</span>
                    <span>
                        {{ $t('other_info_add_drivers_is_address') }}
                    </span>
                </button>
                <div
                    v-if="showWorkAndHomeCity"
                    class="flex flex-col md:flex-row items-center justify-start px-xs mb-6 gap-5"
                >
                    <fieldset class="selectbox">
                        <label for="living_city_code">
                            {{ $t('other_info_add_drivers_home_address') }}
                        </label>
                        <select
                            v-model="input.living_city_code"
                            name="living_city_code"
                            id="vehicle-modifications"
                        >
                            <option value="null" disabled>
                                {{ $t('choose') }}
                            </option>
                            <option
                                v-for="city in cities"
                                :key="city.code"
                                :value="city.code"
                            >
                                {{ city.arabic_description }}
                            </option>
                        </select>
                    </fieldset>
                    <fieldset class="selectbox">
                        <label for="working_city_code">
                            {{ $t('other_info_add_drivers_work_address') }}
                        </label>
                        <select
                            v-model="input.working_city_code"
                            name="working_city_code"
                            id="vehicle-modifications"
                        >
                            <option value="null" disabled>
                                {{ $t('choose') }}
                            </option>
                            <option
                                v-for="city in cities"
                                :key="city.code"
                                :value="city.code"
                            >
                                {{ city.arabic_description }}
                            </option>
                        </select>
                    </fieldset>
                </div>
                <button
                    type="button"
                    class="modals__addons__button"
                    @click="toggleTrafficViolations"
                >
                    <span class="icon">+</span>
                    <span>
                        {{ $t('other_info_add_drivers_violations') }}
                    </span>
                </button>
                <div
                    v-if="showTrafficViolations"
                    class="grid grid-cols-1 md:grid-cols-3 gap-3 px-xs mt-3"
                >
                    <fieldset
                        v-for="(violation, index) in violations"
                        :key="violation.code"
                        class="max-w-xs mb-3"
                    >
                        <input
                            type="checkbox"
                            :id="`violation#${index}`"
                            :checked="input.violations.includes(violation.code)"
                            @change="toggleViolation(violation.code)"
                        />
                        <label :for="`violation#${index}`" class="text-xs">
                            {{ violation.arabic_description }}
                        </label>
                    </fieldset>
                </div>
            </div>
            <div class="flex items-center justify-center gap-4">
                <button
                    type="submit"
                    class="text-center bg-primary text-white rounded-md px-md"
                    :class="{ disabled: !formIsValid }"
                    :disabled="!formIsValid"
                >
                    {{ $t('other_info_add_drivers_add') }}
                </button>
                <button
                    type="button"
                    class="text-center bg-primary text-white rounded-md px-md"
                    @click="raiseData()"
                >
                    {{ $t('other_info_add_drivers_save') }}
                </button>
            </div>
        </form>
        <div v-else class="flex items-center justify-center my-16">
            <VueLottiePlayer
                style="width: 200px; height: 100px"
                name="Qariin loader"
                loop
                path="https://assets9.lottiefiles.com/private_files/lf30_1xgata3z.json"
            />
        </div>
    </Modal>
</template>

<script>
import { defineComponent } from "vue";
import Modal from "@/components/Modal";
import VueLottiePlayer from "vue-lottie-player";

export default defineComponent({
    name: "AddDriversModal",
    components: {
        Modal,
        VueLottiePlayer,
    },
    props: {
        addedDrivers: {
            type: Array,
            default: () => [],
        },
        currentHijriYear: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            open: false,
            hijri: false,
            loading: false,
            showAbroadLicenses: false,
            showTrafficViolations: false,
            showWorkAndHomeCity: false,
            formIsValid: false,
            formPercentagesValid: false,
            hasError: true,
            input: {
                identity_number: null,
                date_of_birth: null,
                date_of_birth_dd: null,
                date_of_birth_mm: null,
                date_of_birth_yy: null,
                educational_level_code: null,
                medical_condition_code: null,
                accidents_number: null,
                children_under_sixteen: null,
                driving_percentage_code: null,
                relation_with_user: null,
                working_city_code: null,
                living_city_code: null,
                have_another_country_licenses: 0,
                another_country_licenses: [],
                violations: [],
            },
            drivers: [],
            drivingPercentages: [],
            educations: [],
            countries: [],
            cities: [],
            violations: [],
            medicalConditions: [],
            deletedDrivers: [],
        };
    },
    watch: {
        "input.another_country_licenses": {
            handler() {
                const { another_country_licenses } = this.input;
                if (
                    another_country_licenses.length &&
                    another_country_licenses[0].country_code &&
                    another_country_licenses[0].years
                )
                    this.input.have_another_country_licenses = 1;
                else this.input.have_another_country_licenses = 0;
            },
            deep: true,
            immediate: true,
        },
        "input.identity_number"(value) {
            if (!value) return;
            const string = value.toString();
            this.hijri = string.startsWith("1");
            this.validateLength(
                this.$refs.identityNumberInput,
                "identity_number"
            );
        },
        input: {
            handler() {
               // this.validateFormPercentages();

                this.formIsValid =
                    this.input.identity_number &&
                   // this.formPercentagesValid &&
                    this.input.driving_percentage_code &&
                    !this.hasError &&
                    this.input.date_of_birth_dd &&
                    this.input.date_of_birth_mm &&
                    this.input.date_of_birth_yy;
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        validateFormPercentages() {
            var totalDriversPercentage = 0;
            var mainDriver = this.drivers?.filter((driver) => !!driver.main);
            var otherDrivers = this.drivers?.filter((driver) => !driver.main);
            otherDrivers?.forEach(
                (driver) =>
                    (totalDriversPercentage =
                        totalDriversPercentage +
                        parseInt(driver.driving_percentage_code))
            );
            this.formPercentagesValid =
                mainDriver.length &&
                totalDriversPercentage +
                    (parseInt(mainDriver[0].driving_percentage_code) -
                        totalDriversPercentage -
                        parseInt(this.input.driving_percentage_code)) <=
                    100 &&
                totalDriversPercentage +
                    (parseInt(mainDriver[0].driving_percentage_code) -
                        totalDriversPercentage -
                        parseInt(this.input.driving_percentage_code)) >=
                    0;
        },
        validateLength(input, key) {
            var value = this.input[key].toString();

            if (value.length < input.maxLength) {
                this.hasError = true;
                input.classList.add("border-danger");
            } else if (value.length > input.maxLength)
                this.input[key] = this.input[key]
                    .toString()
                    .slice(0, input.maxLength);
            else if (value.length == input.maxLength) {
                input.classList.remove("border-danger");
                this.hasError = false;
            }
        },
        toggleModal() {
            this.$refs.modal.toggleModal();
        },
        toggleAbroadLicenses() {
            this.showAbroadLicenses = !this.showAbroadLicenses;
        },
        toggleTrafficViolations() {
            this.showTrafficViolations = !this.showTrafficViolations;
        },
        toggleWorkAndHomeCity() {
            this.showWorkAndHomeCity = !this.showWorkAndHomeCity;
        },
        addLicense() {
            this.input.another_country_licenses.push({
                country_code: null,
                years: null,
            });
        },
        removeLicense(index) {
            if (!this.input.another_country_licenses.length) return;
            this.input.another_country_licenses.splice(index, 1);
        },
        toggleViolation(code) {
            if (this.input.violations.includes(code))
                this.input.violations.splice(
                    this.input.violations.indexOf(code),
                    1
                );
            else this.input.violations.push(code);
        },
        async getEducations() {
            await axios.get("/api/lookups/education").then(({ data }) => {
                this.educations = data.data;
            });
        },
        async getCountries() {
            await axios.get("/api/lookups/countries").then(({ data }) => {
                this.countries = data.data;
            });
        },
        async getCities() {
            await axios.get("/api/lookups/cities").then(({ data }) => {
                this.cities = data.data;
            });
        },
        async getMedicalConditions() {
            await axios
                .get("/api/lookups/medical_conditions")
                .then(({ data }) => {
                    this.medicalConditions = data.data;
                });
        },
        async getViolations() {
            await axios.get("/api/lookups/violations").then(({ data }) => {
                this.violations = data.data;
            });
        },
        async getDrivingPercentages() {
            await axios
                .get("/api/lookups/driving_percentages")
                .then(({ data }) => {
                    this.drivingPercentages = data.data;
                });
        },
        submit() {
            this.input.date_of_birth = `${this.input.date_of_birth_dd}-${this.input.date_of_birth_mm}-${this.input.date_of_birth_yy}`;

            const data = { ...this.input };
            // in case the user deleted the driver before saving data on BE
            data.justAdded = true;

            Object.keys(this.input).forEach((key) => {
                this.input[key] = null;
            });

            this.input.have_another_country_licenses = 0;
            this.input.another_country_licenses = [];
            this.input.violations = [];

            this.showAbroadLicenses = false;
            this.showTrafficViolations = false;
            this.showWorkAndHomeCity = false;

            Object.keys(data).forEach((key) => {
                if (!data[key]) delete data[key];
            });

            this.drivers.push(data);

            this.updateDriversPercentage();
        },
        updateDriversPercentage() {
            var otherDrivers = this.drivers?.filter((driver) => !driver.main);
            var totalDriversPercentage = 0;
            otherDrivers?.forEach((driver) => {
                totalDriversPercentage =
                    totalDriversPercentage +
                    parseInt(driver.driving_percentage_code);
            });
            this.setMainDriverPercentage(totalDriversPercentage);
        },
        setMainDriverPercentage(totalDriversPercentage) {
            var mainDriver = this.drivers?.filter((driver) => !!driver.main);
            if (mainDriver.length) {
                mainDriver[0].driving_percentage_code = "100";
                mainDriver[0].driving_percentage_code =
                    parseInt(mainDriver[0].driving_percentage_code) -
                    totalDriversPercentage;
            }
        },
        deleteDriver(index, driver) {
            this.drivers.splice(index, 1);
            this.updateDriversPercentage();
            if (driver.justAdded) return;
            this.deletedDrivers.push(driver.id);
        },
        raiseData() {
            let data = this.drivers.filter((driver) => !driver.main);

            if (!data.length) {
                data = {
                    driversCleared: true,
                    deletedDrivers: this.deletedDrivers,
                };
            } else {
                data.forEach((driver) => {
                    if (driver.justAdded) delete driver.justAdded;
                });
            }

            // eslint-disable-next-line vue/require-explicit-emits
            this.$emit("saved", data);
            this.toggleModal();
        },
    },
    async mounted() {
        this.loading = true;
        await this.getEducations();
        await this.getMedicalConditions();
        await this.getViolations();
        await this.getCountries();
        await this.getDrivingPercentages();
        await this.getCities();

        if (this.addedDrivers && this.addedDrivers.length) {
            this.addedDrivers.forEach((addDriver) => {
                const driver = {};
                Object.keys(addDriver).forEach((key) => {
                    if (key != "violations" && key != "driver_licenses") {
                        driver[key] = addDriver[key];
                    }
                });

                if (
                    addDriver.driver_licenses &&
                    addDriver.driver_licenses.length
                ) {
                    driver.another_country_licenses = [];
                    addDriver.driver_licenses.forEach((license) => {
                        driver.another_country_licenses.push({
                            country_code: license.country_code,
                            years: license.years,
                        });
                    });
                }

                if (addDriver.violations && addDriver.violations.length) {
                    driver.violations = [];
                    addDriver.violations.forEach((violation) => {
                        driver.violations.push(violation.violation_code);
                    });
                }

                this.drivers.push(driver);
            });
        }

        this.updateDriversPercentage();

        this.loading = false;
    },
});
</script>
